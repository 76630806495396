import { Button, Form, Input, message, Modal, notification, Popconfirm, Select, Spin } from "antd";
import { requestAddExam, requestGetExamList } from "../../../../services/admin.service";
import { useEffect, useState } from "react";

const LessonExamFormModal = ({ isVisible, lesson, onClose, onSuccess }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (lesson && lesson.subject) {
			fetchBlueprintedExams();
		} else {
			setBlueprintedExams([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lesson]);

	const [isLoading, setIsLoading] = useState(false);
	const [blueprintedExams, setBlueprintedExams] = useState([]);

	const fetchBlueprintedExams = () => {
		requestGetExamList({
			filter: `blueprinted:true,status:published,subject:${lesson?.subject?._id}`,
		})
			.then((response) => {
				setBlueprintedExams(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setIsLoading(true);

				values.lessonId = lesson._id;

				requestAddExam(values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={"Tambah Ujian"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menambah ujian baru?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[{ required: true, message: "Nama ujian harus diisi!" }]}
							tooltip="Masukkan nama ujian">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							label="Deskripsi"
							tooltip="Masukkan deskripsi ujian">
							<Input.TextArea style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="blueprintId"
							label="Template"
							tooltip="Pilih template">
							<Select
								style={{ width: "100%" }}
								placeholder="Pilih Template"
								options={[
									{ label: "Tidak menggunakan template", value: "" },
									...blueprintedExams?.map((exam) => ({ label: exam.name, value: exam._id })),
								]}
							/>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default LessonExamFormModal;
