import { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  App,
  theme,
  Radio,
  Space,
  Select,
} from "antd";
import axios from "axios";
import { useEffect } from "react";
import countryCodes from "country-codes-list";

const { Title } = Typography;
const { Footer } = Layout;

const ForgotPasswordPage = (props) => {
  const { message, notification } = App.useApp();

  useLayoutEffect(() => {
    document.title = "Lupa Password";
  }, []);

  const navigate = useNavigate();

  const [formRequest] = Form.useForm();
  const [formReset] = Form.useForm();

  const phone = Form.useWatch("phone", formRequest);

  const [isLoading, setLoading] = useState(false);
  const [resetType, setResetType] = useState("phone");
  const [credentials, setCredentials] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [seconds, setSeconds] = useState(0);
  const [maskedMessage, setMaskedMessage] = useState("");

  const [page, setPage] = useState(1);

  const {
    token: { borderRadius },
  } = theme.useToken();

  // useEffect(() => {
  // 	if (page === 2) {
  // 		if (seconds > 0) {
  // 			setTimeout(() => setSeconds(seconds - 1), 1000);
  // 		} else {
  // 			setSeconds(60);
  // 		}
  // 	}
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page]);

  useEffect(() => {
    formRequest.setFieldsValue({
      countryCode: "62",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (phone && phone.length > 0) {
      if (phone.startsWith("0")) {
        formRequest.setFieldValue("phone", phone.substring(1));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  // useEffect(() => {
  // 	if (seconds > 0) {
  // 		setTimeout(() => setSeconds(seconds - 1), 1000);
  // 	}
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [seconds]);

  const handleSubmitRequest = () => {
    formRequest
      .validateFields()
      .then((values) => {
        setLoading(true);

        const payload = {};

        if (resetType === "email") {
          payload.email = values.email.trim();
          setCredentials({ email: values.email.trim() });
        } else {
          payload.phone = `+${values.countryCode}${values.phone.replace(/\D/g, "")}`;
          setCredentials({
            phone: `+${values.countryCode}${values.phone.replace(/\D/g, "")}`,
          });
        }

        payload.password = values.password;

        axios
          .post(
            process.env.REACT_APP_API_URL + "/auth/request-reset-password",
            payload,
          )
          .then((response) => {
            if (response.data.code === 200) {
              message.success(
                "Kode reset password telah dikirimkan ke nomor telepon Anda",
              );

              if (resetType === "email") {
                setMaskedMessage(
                  `Kami telah mengirimkan kode melalui email Anda (${response.data.data.email})`,
                );
              } else if (resetType === "phone") {
                setMaskedMessage(
                  `Kami telah mengirimkan kode melalui WhatsApp Anda (+${response.data.data.phone})`,
                );
              }
              setPage(2);
              setSeconds(60);
            } else {
              notification.error({
                message: "Kesalahan!",
                description: response.data.message,
              });
            }
          })
          .catch((error) => {
            if (error.response !== undefined) {
              let message = error.response.data.message + ". ";
              if (error.response.data.errors !== undefined) {
                Object.keys(error.response.data.errors).forEach((key) => {
                  message += `${error.response.data.errors[key]}. `;
                });
              }
              notification.error({
                message: "Kesalahan!",
                description: message,
              });
            } else {
              notification.error({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {});
  };

  const handleSubmitReset = () => {
    formReset
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (values.password !== values["password-confirmation"]) {
          notification.error({
            message: "Kesalahan!",
            description: "Password dan konfirmasi password tidak sama",
          });
          setLoading(false);
          return;
        }

        const payload = {};

        if (resetType === "email") {
          if (!credentials.email) {
            notification.error({
              message: "Kesalahan!",
              description:
                "Data tidak valid, silahkan ulangi proses reset password",
            });
            setLoading(false);
            setPage(1);
            return;
          }
          payload.email = credentials.email;
        } else {
          if (!credentials.phone) {
            notification.error({
              message: "Kesalahan!",
              description:
                "Data tidak valid, silahkan ulangi proses reset password",
            });
            setLoading(false);
            setPage(1);
            return;
          }
          payload.phone = credentials.phone;
        }
        payload.token = values.token;
        payload.password = values.password;

        axios
          .post(process.env.REACT_APP_API_URL + "/auth/reset-password", payload)
          .then((response) => {
            if (response.data.code === 200) {
              message.success(
                "Password berhasil direset, silahkan login dengan password baru ^_^",
              );

              navigate("/login");
            } else {
              notification.error({
                message: "Kesalahan!",
                description: response.data.message,
              });
            }
          })
          .catch((error) => {
            if (error.response !== undefined) {
              let message = error.response.data.message + ". ";
              if (error.response.data.errors !== undefined) {
                Object.keys(error.response.data.errors).forEach((key) => {
                  message += `${error.response.data.errors[key]}. `;
                });
              }
              notification.error({
                message: "Kesalahan!",
                description: message,
              });
            } else {
              notification.error({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        notification.error({
          message: "Kesalahan!",
          description: "Silakan periksa kembali data Anda",
        });
      });
  };

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          {page === 1 && (
            <Card style={{ margin: "24px" }}>
              <Title level={4}>Lupa Password</Title>
              <p>
                Ketika Anda lupa password, Anda bisa meresetnya dengan melakukan
                verifikasi melalui WhatsApp atau email. Kami akan mengirimkan
                kode verifikasi ke nomor telepon atau email Anda, sesuai metode
                pilihan Anda.
              </p>
              <Form form={formRequest} initialValues={{ remember: true }}>
                <Radio.Group
                  onChange={(e) => setResetType(e.target.value)}
                  value={resetType}
                  style={{ marginBottom: 16, width: "100%" }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Radio value="phone">WhatsApp</Radio>
                    <Form.Item
                      style={{
                        display: resetType === "phone" ? "block" : "none",
                        width: "100%",
                      }}
                    >
                      <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          name="countryCode"
                          noStyle
                          rules={
                            resetType === "phone"
                              ? [
                                  {
                                    required: true,
                                    message: "Pilih kode negara",
                                  },
                                ]
                              : null
                          }
                        >
                          <Select
                            size="large"
                            style={{ width: 80 }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={Object.keys(
                              countryCodes.customList(
                                "countryCallingCode",
                                "countryCode",
                              ),
                            ).map((country) => {
                              return {
                                label: `+${country}`,
                                value: `${country}`,
                              };
                            })}
                          />
                        </Form.Item>
                        <Form.Item
                          name="phone"
                          noStyle
                          style={{
                            flex: 1,
                          }}
                          rules={
                            resetType === "phone"
                              ? [
                                  {
                                    required: true,
                                    message: "Masukkan nomor telepon",
                                  },
                                ]
                              : null
                          }
                        >
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="896xxxxxxxxx"
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                    <Radio value="email">Email</Radio>
                    <Form.Item
                      name="email"
                      style={{
                        display: resetType === "email" ? "block" : "none",
                        width: "100%",
                      }}
                      rules={
                        resetType === "email"
                          ? [{ required: true, message: "Masukkan email Anda" }]
                          : null
                      }
                    >
                      <Input size="large" placeholder="anda@email.com" />
                    </Form.Item>
                  </Space>
                </Radio.Group>
                <Form.Item>
                  <Button
                    loading={isLoading}
                    type="primary"
                    style={{ width: "100%" }}
                    size="large"
                    onClick={handleSubmitRequest}
                  >
                    Kirim Kode
                  </Button>
                </Form.Item>
                <Typography.Text
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginBottom: "16px",
                  }}
                >
                  Tidak jadi lupa?{" "}
                  <Link to="/login" className="font-bold text-dark">
                    Masuk
                  </Link>
                </Typography.Text>
              </Form>
              <Footer style={{ marginTop: 24, borderRadius: borderRadius }}>
                <Typography.Text>
                  Copyright © {new Date().getFullYear()} Smartiva from{" "}
                  <Typography.Link href="https://www.codeiva.com">
                    Codeiva
                  </Typography.Link>
                  .
                </Typography.Text>
              </Footer>
            </Card>
          )}
          {page === 2 && (
            <Card style={{ margin: "24px" }}>
              <Title level={4}>Reset Password</Title>
              <p>{maskedMessage}</p>
              <Form form={formReset} initialValues={{ remember: true }}>
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Masukkan kode yang Anda terima",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Kode" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Masukkan password baru" },
                  ]}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    placeholder="Password Baru"
                  />
                </Form.Item>
                <Form.Item
                  name="password-confirmation"
                  rules={[
                    { required: true, message: "Masukkan konfirmasi password" },
                  ]}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    placeholder="Konfirmasi Password"
                  />
                </Form.Item>

                {/* Timer to resend */}
                {/* {seconds > 0 && (
									<Typography.Text
										type="secondary"
										style={{
											display: "block",
											textAlign: "center",
											marginBottom: "16px",
										}}>
										Kirim ulang kode dalam {dayjs().minute(0).second(seconds).format("mm:ss")}
									</Typography.Text>
								)}
								{seconds === 0 && (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											marginBottom: "16px",
										}}>
										<Button
											type="link"
											onClick={() => {
												handleSubmitRequest();
											}}>
											Kirim ulang kode
										</Button>
									</div>
								)} */}

                <Form.Item>
                  <Button
                    loading={isLoading}
                    type="primary"
                    size="large"
                    style={{ width: "100%" }}
                    onClick={handleSubmitReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
                <Typography.Text
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginBottom: "16px",
                  }}
                >
                  Tidak jadi lupa?{" "}
                  <Link to="/login" className="font-bold text-dark">
                    Masuk
                  </Link>
                </Typography.Text>
              </Form>
              <Footer style={{ marginTop: 24, borderRadius: borderRadius }}>
                <Typography.Text>
                  Copyright © {new Date().getFullYear()} Smartiva from{" "}
                  <Typography.Link href="https://www.codeiva.com">
                    Codeiva
                  </Typography.Link>
                  .
                </Typography.Text>
              </Footer>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ForgotPasswordPage;
