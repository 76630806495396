/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	CreditCardFilled,
	MoreOutlined,
	PlusCircleFilled,
	QrcodeOutlined,
	QuestionCircleFilled,
	QuestionCircleOutlined,
	QuestionCircleTwoTone,
} from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Col,
	Dropdown,
	message,
	Row,
	Space,
	Switch,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
	requestBlockPocket,
	requestGetPocketList,
	requestGetTotalPocketBalance,
	requestUnblockPocket,
} from "../../../services/admin.service";
import { HiOutlineCreditCard, HiOutlineEye } from "react-icons/hi2";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import PocketWithdrawalFormModal from "./components/PocketWithdrawalFormModal";
import PocketDetailModal from "./components/PocketDetailModal";
import PocketScannerModal from "./components/PocketScannerModal";
import { useSearchParams } from "react-router-dom";
import { PiCreditCardDuotone } from "react-icons/pi";
import { format } from "../../../utils/Formatter";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
		render: (text, record) => (
			<Space direction="horizontal">
				<PiCreditCardDuotone
					style={{
						fontSize: "4.8em",
						color: `${record.type === "SMARTIVA" ? "gold" : "silver"}`,
						verticalAlign: "middle",
					}}
				/>
				<Space direction="vertical">
					<Typography.Text>{record.name}</Typography.Text>
					<Typography.Text
						style={{ margin: 0 }}
						copyable={{ text: record.number, tooltips: "Salin nomor" }}
						strong>
						{record.number.replace(/(.{4})/g, "$1-").slice(0, -1)}
					</Typography.Text>
				</Space>
			</Space>
		),
	},
	{
		title: "Tipe",
		dataIndex: "type",
		key: "type",
		render: (text, record) => (
			<Tag style={{ fontWeight: "bold" }} color={record.type === "SMARTIVA" ? "gold" : "default"}>
				{record.type === "SMARTIVA" ? "SMARTIVA" : "SEKOLAH"}
			</Tag>
		),
	},
	{
		title: "Siswa",
		dataIndex: "student",
		key: "student",
		render: (text, record) => (
			<Space>
				<Typography.Text>{record.student.name}</Typography.Text>
				<Tag color="blue">
					<Typography.Text
						strong
						copyable={{ text: record.student.number, tooltips: "Salin nomor" }}>
						{record.student.number}
					</Typography.Text>
				</Tag>
			</Space>
		),
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		filters: [
			{
				text: "ENABLED",
				value: "ENABLED",
			},
			{
				text: "DISABLED",
				value: "DISABLED",
			},
		],
		render: (text, record) => (
			<Tag color={record.status === "ENABLED" ? "green" : "red"}>{record.status}</Tag>
		),
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat Detail",
		icon: <HiOutlineEye />,
	},
	{
		key: "withdraw",
		label: "Tarik Saldo",
		icon: <HiOutlineCreditCard />,
	},
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <HiOutlineTrash />,
	// 	danger: true,
	// },
];

const PocketPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Tabungan";
	}, []);

	const [pockets, setPockets] = useState([]);
	const [mappedPockets, setMappedPockets] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isBlocking, setBlocking] = useState(false);

	const [isDetailVisible, setDetailVisible] = useState(false);
	const [isWithdrawVisible, setWithdrawVisible] = useState(false);
	const [pocket, setPocket] = useState(null);
	const [balances, setBalances] = useState([]);

	const [isScannerVisible, setScannerVisible] = useState(false);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchPocketList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}

		fetchBalance();
	}, []);

	const handleWithdrawButton = (pocket) => {
		setPocket(pocket);
		setWithdrawVisible(true);
	};

	const handleWithdrawSuccess = () => {
		setWithdrawVisible(false);
		setPocket(null);
		fetchPocketList();
	};

	const handleWithdrawClose = () => {
		setWithdrawVisible(false);
		setPocket(null);
	};

	const handleDetailButton = (pocket) => {
		setPocket(pocket);
		setDetailVisible(true);
	};

	const handleDetailClose = () => {
		setDetailVisible(false);
		setPocket(null);
	};

	const handleScannerButton = () => {
		setScannerVisible(true);
	};

	const handleScannerScan = (result) => {
		if (result === null) {
			return;
		}
		if (result.length === 0) {
			return;
		}
		if (result[0].rawValue === null || result[0].rawValue === "") {
			return;
		}

		const studentId = result[0].rawValue;
		setKeyword(studentId);

		setScannerVisible(false);
	};

	const handleScannerCancel = () => {
		setScannerVisible(false);
	};

	const fetchPocketList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetPocketList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setPockets(response.data.data);
				updateMappedPocket(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchBalance = () => {
		setLoading(true);

		requestGetTotalPocketBalance()
			.then((response) => {
				setBalances(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedPocket = (data) => {
		setMappedPockets(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				number: d.number,
				type: d.type,
				status: d.status,
				student: d.student,
				blocked: d.blocked,
				actions: (
					<Space>
						<Switch
							className="switch-blocked"
							checkedChildren="Buka Blokir"
							unCheckedChildren="Blokir"
							loading={isBlocking}
							checked={d.blocked === true}
							onChange={(checked) => {
								setBlocking(true);
								if (checked) {
									requestBlockPocket(d._id)
										.then((response) => {
											message.success(response.data.message);

											fetchPocketList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								} else {
									requestUnblockPocket(d._id)
										.then((response) => {
											message.success(response.data.message);

											fetchPocketList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								}
							}}
						/>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										handleDetailButton(d);
									} else if (e.key === "withdraw") {
										handleWithdrawButton(d);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<PocketWithdrawalFormModal
				isVisible={isWithdrawVisible}
				pocket={pocket}
				onSuccess={handleWithdrawSuccess}
				onClose={handleWithdrawClose}
			/>
			<PocketDetailModal
				isVisible={isDetailVisible}
				id={pocket ? pocket._id : ""}
				onClose={handleDetailClose}
			/>
			<PocketScannerModal
				isVisible={isScannerVisible}
				onScan={handleScannerScan}
				onCancel={handleScannerCancel}
			/>
			<Card
				bordered={false}
				title="Daftar Tabungan"
				extra={
					<Space>
						<SearchInput
							placeholder="Nomor atau nama siswa"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<Button icon={<QrcodeOutlined />} type="primary" onClick={handleScannerButton}></Button>
						<Button
							icon={<PlusCircleFilled />}
							type="primary"
							onClick={() => {
								notification["info"]({
									message: "Info",
									description: "Fitur ini belum tersedia.",
								});
							}}>
							<span className="ant-btn-text">Tambah</span>
						</Button>
					</Space>
				}>
				<Space
					direction="vertical"
					style={{
						width: "100%",
					}}>
					<Row gutter={[16, 16]}>
						<Col xs={24} xl={12}>
							<Card>
								<Space direction="horizontal" style={{ width: "100%" }}>
									<div
										className="text-success"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<PiCreditCardDuotone
											size={48}
											style={{
												color: "gold",
											}}
										/>
									</div>
									<Col gutter={0}>
										<Typography.Title
											level={5}
											style={{
												margin: 0,
												display: "flex",
												gap: 4,
											}}>
											Tabungan Smartiva
											<Tooltip
												title={
													"Tabungan Smartiva adalah tabungan yang mana dananya disimpan di rekening Smartiva (CV Codeiva Lentera Indonesia). Sekolah dapat mengajukan penarikan dana bila diperlukan."
												}
												placement="bottom">
												<QuestionCircleOutlined
													style={{
														color: "rgba(0, 0, 0, 0.45)",
													}}
												/>
											</Tooltip>
										</Typography.Title>
										<Typography.Title level={2} style={{ margin: 0 }} className="text-success">
											{format(balances.find((b) => b._id === "SMARTIVA")?.total ?? 0)}
										</Typography.Title>
									</Col>
								</Space>
							</Card>
						</Col>
						<Col xs={24} xl={12}>
							<Card>
								<Space direction="horizontal" style={{ width: "100%" }}>
									<div
										className="text-danger"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<PiCreditCardDuotone
											size={48}
											style={{
												color: "default",
											}}
										/>
									</div>
									<Col gutter={0}>
										<Typography.Title
											level={5}
											style={{
												margin: 0,
												display: "flex",
												gap: 4,
											}}>
											Tabungan Sekolah
											<Tooltip
												title={
													"Tabungan sekolah adalah tabungan yang dananya disimpan oleh sekolah. Sekolah berwenang sepenuhnya untuk mengatur dan menyimpan dana tersebut. Smartiva tidak bertanggung jawab atas kemungkinan kehilangan atau penipuanan dana yang disimpan di rekening sekolah."
												}
												placement="bottom">
												<QuestionCircleTwoTone
													style={{
														color: "rgba(0, 0, 0, 0.45)",
													}}
												/>
											</Tooltip>
										</Typography.Title>
										<Typography.Title level={2} style={{ margin: 0 }} className="text-danger">
											{format(balances.find((b) => b._id === "SCHOOL")?.total ?? 0)}
										</Typography.Title>
									</Col>
								</Space>
							</Card>
						</Col>
					</Row>
				</Space>
				<div>
					<Table
						style={{
							marginTop: 16,
						}}
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedPockets}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default PocketPage;
