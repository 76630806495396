/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import { useEffect } from "react";
import {
  requestAddExtracurricular,
  requestEditExtracurricular,
} from "../../../../services/admin.service";

const ExtracurricularFormModal = ({
  extracurricular,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
}) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();

  useEffect(() => {
    if (extracurricular !== null) {
      form.setFieldsValue({
        name: extracurricular.name,
        description: extracurricular.description,
      });
    } else {
      form.setFieldsValue({
        name: "",
        description: "",
      });
    }
  }, [extracurricular, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        if (extracurricular) {
          requestEditExtracurricular(extracurricular._id, values)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          requestAddExtracurricular(values)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          extracurricular === null || extracurricular === undefined
            ? "Tambah Ekskul"
            : "Ubah Ekskul"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data ekskul?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">
              {extracurricular ? "Simpan" : "Tambah"}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              tooltip="Masukkan nama ekskul"
            >
              <Input placeholder="Futsal" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="description"
              label="Deskripsi"
              required
              tooltip="Masukkan deskripsi ekskul"
            >
              <Input.TextArea
                placeholder="Deskripsi ekskul"
                rows={4}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ExtracurricularFormModal;
