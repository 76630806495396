/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ExclamationCircleFilled,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  App,
  Button,
  Card,
  Col,
  Dropdown,
  message,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  requestDeleteReportAssessment,
  requestGetCurriculumList,
  requestGetReportAssessmentList,
} from "../../../services/admin.service";
import ReportAssessmentFormModal from "./components/ReportAssessmentFormModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { CurriculumFilter } from "../../../components/CurriculumFilter";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "Kurikulum",
    dataIndex: "curriculum",
    key: "curriculum",
  },
  {
    title: "Urutan",
    dataIndex: "order",
    key: "order",
  },
  {
    title: "Nama",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Bobot (%)",
    dataIndex: "weight",
    key: "weight",
    render: (text) => <Tag color="blue">{text}%</Tag>,
  },
  {
    title: "Aksi",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];

const otherButtons = [
  {
    key: "edit",
    label: "Edit",
    icon: <HiOutlinePencil />,
  },
  {
    type: "divider",
  },
  {
    key: "delete",
    label: "Hapus",
    icon: <HiOutlineTrash />,
    danger: true,
  },
];

const ReportAssessmentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notification, modal } = App.useApp();

  useLayoutEffect(() => {
    document.title = "Ruang Admin | Rapor | Jenis Penilaian";
  }, []);

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({});
  const [sorter, setSorter] = useState({
    order: "asc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow startedAt and endedAt
      Object.keys(filter).forEach((key) => {
        if (key === "startedAt" || key === "endedAt") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        _id: "desc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  const [reportAssessments, setReportAssessments] = useState([]);
  const [mappedReportAssessments, setMappedReportAssessments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [curriculums, setCurriculums] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);

  const [reportAssessment, setReportAssessment] = useState(null);
  const [isReportAssessmentFormVisible, setReportAssessmentFormVisible] =
    useState(false);

  useEffect(() => {
    fetchSubjectList();
  }, []);

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    fetchReportAssessmentList();
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, []);

  useEffect(() => {
    setFilter({
      ...filter,
      curriculum: selectedCurriculum ? selectedCurriculum._id : "",
    });
  }, [selectedCurriculum]);

  const fetchReportAssessmentList = () => {
    setLoading(true);
    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetReportAssessmentList({
      page: page,
      limit: limit,
      order: tempSorter ?? "_id:desc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTotal(response.data.pagination.total);
        setReportAssessments(response.data.data);
        updateMappedReportAssessment(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    requestGetCurriculumList({
      page: 1,
      limit: 1000,
    })
      .then((response) => {
        setCurriculums(response.data.data);
      })
      .catch((error) => {});
  };

  const updateMappedReportAssessment = (data) => {
    setMappedReportAssessments(
      data.map((d) => ({
        key: d._id,
        curriculum: d.curriculum.name,
        order: d.order,
        name: d.name,
        description: d.description,
        weight: d.weight * 100,
        actions: (
          <Space>
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: (e) => {
                  if (e.key === "edit") {
                    handleEditButton(d);
                  } else if (e.key === "delete") {
                    modal.confirm({
                      title: "Sebentar",
                      icon: <ExclamationCircleFilled />,
                      content:
                        "Anda yakin akan menghapus jenis penilaian ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
                      okText: "Ya",
                      okType: "danger",
                      cancelText: "Tidak",
                      onOk() {
                        handleDeleteButton(d._id);
                      },
                      onCancel() {},
                    });
                  }
                },
                items: otherButtons,
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        ),
      })),
    );
  };

  const handleAddButton = () => {
    setReportAssessmentFormVisible(true);
  };

  const handleEditButton = (reportAssessment) => {
    setReportAssessment(reportAssessment);
    setReportAssessmentFormVisible(true);
  };

  const handleDeleteButton = (id) => {
    setLoading(true);
    requestDeleteReportAssessment(id)
      .then((response) => {
        if (response.data.code === 200) {
          message.success(response.data.message);

          fetchReportAssessmentList();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseReportAssessmentForm = () => {
    setReportAssessment(null);
    setReportAssessmentFormVisible(false);
  };

  const handleOnSuccessReportAssessmentForm = () => {
    setReportAssessment(null);
    setReportAssessmentFormVisible(false);
    fetchReportAssessmentList();
  };

  return (
    <>
      <ReportAssessmentFormModal
        isLoading={isLoading}
        isVisible={isReportAssessmentFormVisible}
        onLoading={(v) => setLoading(v)}
        reportAssessment={reportAssessment}
        onClose={handleOnCloseReportAssessmentForm}
        onSuccess={handleOnSuccessReportAssessmentForm}
        curriculums={curriculums}
      />
      <Card
        bordered={false}
        title="Daftar Jenis Penilaian"
        extra={
          <Space>
            <SearchInput
              placeholder="Nama jenis penilaian"
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
            <CurriculumFilter
              curriculums={curriculums}
              filteredBy={{
                key: selectedCurriculum ? selectedCurriculum._id : "",
                value: selectedCurriculum
                  ? selectedCurriculum.name
                  : "Semua Kurikulum",
              }}
              filterBy={(key) => {
                if (key === "") {
                  setSelectedCurriculum(null);
                } else {
                  setSelectedCurriculum(
                    curriculums.find((curriculum) => curriculum._id === key),
                  );
                }
              }}
              resettable={true}
            />
            <Button
              icon={<PlusCircleFilled />}
              type="primary"
              onClick={handleAddButton}
            >
              <span className="ant-btn-text">Tambah</span>
            </Button>
          </Space>
        }
      >
        <div>
          <Table
            bordered
            columns={columns}
            loading={isLoading}
            dataSource={mappedReportAssessments}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onChange={handlePaginate}
          />
        </div>
      </Card>
    </>
  );
};

export default ReportAssessmentPage;
