/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, message, Modal, Popconfirm, Select } from "antd";
import { useEffect, useState } from "react";
import {
	requestEnrollStudentsToExtracurricular,
	requestGetStudentList,
} from "../../../../services/admin.service";

const ExtracurricularStudentEnrollFormModal = ({
	extracurricular,
	isVisible,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();

	const [keyword, setKeyword] = useState("");
	const [students, setStudents] = useState([]);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (isVisible && !isLoading) {
			fetchStudentList();
		}
	}, [isVisible, keyword]);

	const fetchStudentList = () => {
		setLoading(true);
		requestGetStudentList({
			keyword: keyword,
			page: 1,
			limit: 10,
			filter: `status:ACTIVE`,
		})
			.then((response) => {
				// merge unique data
				const uniqueData = [...students, ...response.data.data];
				const uniqueStudents = [...new Map(uniqueData.map((item) => [item["_id"], item])).values()];
				const sortedStudents = uniqueStudents.sort((a, b) => {
					if (a.number < b.number) {
						return -1;
					}
					if (a.number > b.number) {
						return 1;
					}
					return 0;
				});
				setStudents(sortedStudents);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);

				requestEnrollStudentsToExtracurricular(extracurricular._id, values)
					.then((response) => {
						message.success(response.data.message);

						setStudents([]);
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			{extracurricular && (
				<Modal
					maskClosable={false}
					width={512}
					title={`Tambahkan Siswa ke Kelas ${extracurricular.name}`}
					open={isVisible}
					cancelText="Batal"
					onCancel={handleClose}
					footer={[
						<Button key={1} onClick={handleClose}>
							Batal
						</Button>,
						<Popconfirm
							key={2}
							onConfirm={handleSubmit}
							title="Yakin akan mendaftarkan siswa ini?"
							okText="Ya"
							cancelText="Tidak">
							<Button type="primary">{"Daftarkan"}</Button>
						</Popconfirm>,
					]}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="studentIds"
							label="Nama"
							required
							rules={[{ required: true, message: "Pilih siswa!" }]}
							tooltip="Pilih siswa">
							<Select
								mode="multiple"
								placeholder="Pilih Siswa"
								showSearch
								optionFilterProp="children"
								onSearch={(value) => {
									setKeyword(value);
								}}
								virtual={true}
								loading={isLoading}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{students.map((student) => (
									<Select.Option key={student._id} value={student._id}>
										{`[${student.number}] ${student.name}`}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</Modal>
			)}
		</>
	);
};

export default ExtracurricularStudentEnrollFormModal;
