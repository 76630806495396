import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Select,
  Spin,
  Upload,
} from "antd";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import {
  requestAddArticle,
  requestEditArticle,
} from "../../../../services/admin.service";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const ArticleFormModal = ({
  article,
  open,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (article !== null) {
      form.setFieldsValue({
        title: article.title,
        excerpt: article.excerpt,
        blocks: article.blocks[0].content,
        tags: article.tags,
        status: article.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("excerpt", values.excerpt);
        formData.append("blocks", values.blocks);
        formData.append("tags", values.tags);
        formData.append("status", values.status);
        if (values.cover && values.cover[0] && values.cover[0].originFileObj) {
          formData.append("cover", values.cover[0].originFileObj);
        }

        if (article) {
          requestEditArticle(article.slug, formData)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          requestAddArticle(formData)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification.error({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification.error({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Kesalahan!",
          description: error.message,
        });
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      maskClosable={false}
      width={"calc(100% - 64px)"}
      style={{
        top: 32,
      }}
      title={
        article === null || article === undefined
          ? "Tambah Artikel"
          : "Ubah Artikel"
      }
      open={open}
      cancelText="Batal"
      onCancel={handleClose}
      footer={[
        <Button key={1} onClick={handleClose}>
          Batal
        </Button>,
        <Popconfirm
          key={2}
          onConfirm={handleSubmit}
          title="Yakin akan menyimpan data artikel?"
          okText="Ya"
          cancelText="Tidak"
        >
          <Button type="primary">{article ? "Simpan" : "Tambah"}</Button>
        </Popconfirm>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" requiredMark="optional">
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            name="title"
            label="Judul"
            required
            rules={[{ required: true, message: "Masukkan judul artikel!" }]}
            tooltip="Masukkan judul artikel"
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            name="excerpt"
            label="Kutipan"
            required
            rules={[{ required: true, message: "Masukkan kutipan artikel!" }]}
            tooltip="Masukkan kutipan artikel"
          >
            <Input.TextArea rows={2} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            name="blocks"
            label="Isi"
            required
            rules={[{ required: true, message: "Masukkan isi artikel!" }]}
            tooltip="Masukkan isi artikel"
          >
            <ReactQuill
              onChange={(value) => {
                form.setFieldsValue({
                  blocks: value,
                });
              }}
              theme="snow"
              style={{
                width: "100%",
                borderRadius: 4,
              }}
            />
          </Form.Item>
          {/* tags, status, cover */}
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            name="tags"
            label="Tags"
            required
            rules={[{ required: true, message: "Masukkan tags artikel!" }]}
            tooltip="Masukkan tags artikel"
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter untuk memasukkan tag"
              allowClear
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            name="status"
            label="Status"
            required
            rules={[{ required: true, message: "Masukkan status artikel!" }]}
            tooltip="Masukkan status artikel"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Pilih status artikel"
            >
              <Select.Option value="DRAFT">Draft</Select.Option>
              <Select.Option value="PUBLISHED">Published</Select.Option>
              <Select.Option value="ARCHIVED">Archived</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{ marginTop: 12, marginBottom: 0 }}
            rules={
              article === null || article === undefined
                ? [{ required: true, message: "Masukkan sampul artikel!" }]
                : []
            }
            required={article === null || article === undefined ? true : false}
            name="cover"
            label="Sampul"
            valuePropName="cover"
            getValueFromEvent={normFile}
          >
            <Dragger
              name="cover"
              multiple={false}
              accept=".jpg,.jpeg,.png"
              beforeUpload={() => false}
              showUploadList={true}
              listType="picture"
              style={{ width: "100%" }}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              iconRender={(file) => {
                return <InboxOutlined style={{ fontSize: 24 }} />;
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                Klik atau seret file ke area ini untuk mengunggah
              </p>
              <p className="ant-upload-hint">
                Dukungan untuk satu file dengan ukuran maksimal 2MB
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ArticleFormModal;
