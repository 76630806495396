import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	browserName,
	deviceType,
	getUA,
	mobileModel,
	mobileVendor,
	osName,
} from "react-device-detect";
import {
	Layout,
	Button,
	Row,
	Col,
	Typography,
	Form,
	Input,
	Card,
	Checkbox,
	message,
	notification,
	Space,
	Select,
	Tabs,
	theme,
} from "antd";
import axios from "axios";
import packageJson from "../../../package.json";
import AppContext from "../../store/app.context.tsx";
import countryCodes from "country-codes-list";

const { Title } = Typography;
const { Footer } = Layout;

const LoginPage = (props) => {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	useLayoutEffect(() => {
		document.title = "Login";
	}, []);

	const [form] = Form.useForm();
	const phone = Form.useWatch("phone", form);
	const [loginType, setLoginType] = useState("phone");
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		form.setFieldsValue({
			countryCode: "62",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (phone && phone.length > 0) {
			if (phone.startsWith("0")) {
				form.setFieldValue("phone", phone.substring(1));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phone]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);

				const payload = {};

				if (loginType === "email") {
					payload.email = values.email;
				} else {
					payload.phone = `+${values.countryCode}${values.phone.replace(/\D/g, "")}`;
				}

				payload.password = values.password;

				const deviceOS = osName.toLowerCase();
				const deviceVendor = deviceType === "browser" ? browserName : mobileVendor;
				const deviceModel = deviceType === "browser" ? getUA : mobileModel;
				const deviceToken = localStorage.getItem("messaging_token");

				axios
					.post(process.env.REACT_APP_API_URL + "/auth/login", payload, {
						headers: {
							"x-device-os": deviceOS,
							"x-device-vendor": deviceVendor,
							"x-device-model": deviceModel,
							"x-device-token": deviceToken,
							"x-client-code": "smartiva-office",
							"x-client-version": packageJson.version,
						},
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Halo, selamat datang ^_^");

							context.login(response.data.data);
							navigate("/dashboard");
						} else {
							notification.error({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification.error({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description: "Silakan periksa kembali data Anda",
				});
			});
	};

	const {
		token: { borderRadius },
	} = theme.useToken();

	return (
		<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
			<Col xs={24} sm={24} md={12} lg={12} xl={8}>
				<Card style={{ margin: "24px" }}>
					<Title level={4}>Login {deviceType}</Title>
					<p>Untuk melanjutkan Anda harus login dulu ya</p>
					<Form form={form} initialValues={{ remember: true }}>
						<Tabs
							size="small"
							defaultActiveKey={loginType}
							onChange={(key) => {
								setLoginType(key);
							}}
							items={[
								{
									key: "phone",
									label: "No. Telepon",
									children: (
										<Form.Item>
											<Space.Compact
												style={{
													width: "100%",
												}}>
												<Form.Item
													name="countryCode"
													noStyle
													rules={
														loginType === "phone"
															? [
																	{
																		required: true,
																		message: "Pilih kode negara",
																	},
															  ]
															: null
													}>
													<Select
														size="large"
														style={{ width: 80 }}
														filterOption={(input, option) =>
															option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														options={Object.keys(
															countryCodes.customList("countryCallingCode", "countryCode")
														).map((country) => {
															return {
																label: `+${country}`,
																value: `${country}`,
															};
														})}
													/>
												</Form.Item>
												<Form.Item
													name="phone"
													noStyle
													style={{
														flex: 1,
													}}
													rules={
														loginType === "phone"
															? [
																	{
																		required: true,
																		message: "Masukkan nomor telepon",
																	},
															  ]
															: null
													}>
													<Input size="large" style={{ width: "100%" }} placeholder="No. Telepon" />
												</Form.Item>
											</Space.Compact>
										</Form.Item>
									),
								},
								{
									key: "email",
									label: "Email",
									children: (
										<Form.Item
											name="email"
											rules={
												loginType === "email"
													? [{ required: true, message: "Masukkan email Anda" }]
													: null
											}>
											<Input size="large" placeholder="Email" />
										</Form.Item>
									),
								},
							]}
						/>
						<Form.Item
							name="password"
							rules={[{ required: true, message: "Masukkan password Anda" }]}>
							<Input.Password size="large" type="password" placeholder="Password" />
						</Form.Item>
						<Form.Item>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}>
								<Form.Item name="remember" valuePropName="checked" noStyle>
									<Checkbox>Remember me</Checkbox>
								</Form.Item>

								<Link className="login-form-forgot" to={"/forgot-password"}>
									Forgot password
								</Link>
							</div>
						</Form.Item>
						<Form.Item>
							<Button
								loading={isLoading}
								type="primary"
								onClick={handleSubmit}
								style={{
									width: "100%",
								}}
								size="large">
								Log in
							</Button>
						</Form.Item>
						<Typography.Text
							style={{
								display: "block",
								textAlign: "center",
								marginBottom: "16px",
							}}>
							Belum punya akun?{" "}
							<Link to="/register" className="font-bold text-dark">
								Daftar
							</Link>
						</Typography.Text>
					</Form>
					<Footer style={{ marginTop: 24, borderRadius: borderRadius }}>
						<Typography.Text>
							Copyright © {new Date().getFullYear()} Smartiva from{" "}
							<Typography.Link href="https://www.codeiva.com">Codeiva</Typography.Link>.
						</Typography.Text>
					</Footer>
				</Card>
			</Col>
		</Row>
	);
};

export default LoginPage;
