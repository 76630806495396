/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import {
  requestAddReportAssessment,
  requestEditReportAssessment,
} from "../../../../services/admin.service";

const ReportAssessmentFormModal = ({
  reportAssessment,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
  curriculums,
}) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();

  useEffect(() => {
    if (reportAssessment !== null) {
      form.setFieldsValue({
        order: reportAssessment.order,
        name: reportAssessment.name,
        description: reportAssessment.description,
        curriculumId: reportAssessment.curriculumId,
        weight: reportAssessment.weight * 100,
        threshold: reportAssessment.threshold,
      });
    }
  }, [reportAssessment]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.weight = values.weight / 100;
        onLoading(true);
        if (reportAssessment) {
          requestEditReportAssessment(reportAssessment._id, values)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          requestAddReportAssessment(values)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          reportAssessment === null || reportAssessment === undefined
            ? "Tambah Jenis Penilaian"
            : "Ubah Jenis Penilaian"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data jenis penilaian?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">
              {reportAssessment ? "Simpan" : "Tambah"}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="curriculumId"
              label="Kurikulum"
              required
              rules={[{ required: true, message: "Pilih kurikulum" }]}
              tooltip="Pilih kurikulum"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Pilih kuriulum"
                options={curriculums.map((curriculum) => ({
                  label: curriculum.name,
                  value: curriculum._id,
                }))}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="order"
              label="Urutan"
              required
              rules={[{ required: true, message: "Masukkan urutan penilaian" }]}
              tooltip="Masukkan urutan berdasarkan tampilan di buku rapor"
            >
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              rules={[{ required: true, message: "Masukkan nama penilaian" }]}
              tooltip="Masukkan nama kurikulum"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="description"
              label="Deskripsi"
              tooltip="Masukkan deskripsi kurikulum"
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="weight"
              label="Bobot (%)"
              required
              rules={[{ required: true, message: "Masukkan bobot penilaian" }]}
              tooltip="Masukkan bobot kurikulum"
            >
              <InputNumber style={{ width: "100%" }} min={0} max={100} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="threshold"
              label="KKM"
              required
              rules={[{ required: true, message: "Masukkan KKM penilaian" }]}
              tooltip="Masukkan KKM penilaian"
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={
                  curriculums.find(
                    (curriculum) =>
                      curriculum._id === form.getFieldValue("curriculumId"),
                  )?.scale
                }
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="understand"
              valuePropName="checked"
              required
              rules={[
                {
                  required: true,
                  message: "Anda harus memahami peringatan ini",
                },
              ]}
            >
              <Checkbox style={{ marginTop: 12, marginBottom: 0 }}>
                Saya memahami bahwa perubahan ini akan mempengaruhi buku rapor
                yang telah dibuat
              </Checkbox>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ReportAssessmentFormModal;
