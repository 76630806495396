import {
  App,
  Button,
  Checkbox,
  Descriptions,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { HiEnvelope } from "react-icons/hi2";
import { requestReportEvent } from "../../../../services/admin.service";

const ExtracurricularReportFormModal = ({
  extracurricular,
  event,
  isVisible,
  onClose,
  onSuccess,
}) => {
  const { notification, modal } = App.useApp();
  const [form] = Form.useForm();
  const [students, setStudents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isVisible && event && extracurricular) {
      setStudents(extracurricular.students);
      form.setFieldsValue({
        note: event?.note,
        isSigned: event?.signedAt ? true : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, event, extracurricular]);

  useEffect(() => {
    if (students.length > 0) {
      updateAttendees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  const updateAttendees = () => {
    const tempAttendees = students.map((item) => {
      const eventAttendee = event?.attendees.find(
        (attendee) => attendee.referenceId === item._id,
      );
      return {
        id: item._id,
        referenceId: item._id,
        type: "STUDENT",
        number: item.number,
        name: item.name,
        status: eventAttendee ? eventAttendee.status : "PRESENT",
        score: eventAttendee ? eventAttendee.score : 0,
        note: eventAttendee ? eventAttendee.note : "",
        permission: eventAttendee ? eventAttendee.permission : null,
      };
    });
    setAttendees(tempAttendees);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        values.attendees = attendees;
        values.isSigned = values.isSigned ? values.isSigned : false;

        requestReportEvent(event._id, values)
          .then((response) => {
            message.success(response.data.message);
            setLoading(false);
            onSuccess();
          })
          .catch((error) => {
            setLoading(false);
            if (error.response && error.response.data) {
              notification["error"]({
                message: "Kesalahan!",
                description: error.response.data.message,
              });
            } else {
              notification["error"]({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      });
  };

  const handleClose = () => {
    form.resetFields();
    setAttendees([]);
    setStudents([]);
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={"calc(100% - 64px)"}
        style={{
          top: 32,
        }}
        title={"Laporan Pertemuan"}
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data laporan ini?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button loading={isLoading} type="primary">
              {"Simpan"}
            </Button>
          </Popconfirm>,
        ]}
      >
        {extracurricular && (
          <Form layout="vertical" form={form} requiredMark="optional">
            <Descriptions size="small" bordered column={2}>
              <Descriptions.Item
                labelStyle={{
                  width: 128,
                }}
                label="Ekskul"
              >
                {extracurricular.name}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{
                  width: 128,
                }}
                label="Waktu"
              >
                {dayjs(event?.startedAt).format("DD MMMM YYYY HH:mm")} -{" "}
                {dayjs(event?.endedAt).format("DD MMMM YYYY HH:mm")}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{
                  width: 128,
                }}
                label="Durasi"
              >
                {dayjs(event?.endedAt).diff(dayjs(event?.startedAt), "minute")}{" "}
                menit
              </Descriptions.Item>
              {event && (
                <Descriptions.Item
                  labelStyle={{
                    width: 128,
                  }}
                  label="TTD"
                >
                  {event?.signedAt ? (
                    <Tag color="green">Sudah ditandatangani</Tag>
                  ) : (
                    <Tag color="red">Belum ditandatangani</Tag>
                  )}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Typography.Paragraph
              style={{
                marginTop: 12,
                marginBottom: 0,
                paddingBottom: 8,
              }}
            >
              Daftar Siswa
              <Tooltip
                placement="top"
                title={"Siswa yang terdaftar pada kelas ini"}
              >
                <QuestionCircleOutlined
                  style={{
                    marginLeft: 4,
                    color: "rgba(0, 0, 0, 0.45)",
                  }}
                />
              </Tooltip>
            </Typography.Paragraph>
            <Table
              rowKey="id"
              dataSource={attendees}
              pagination={false}
              loading={isLoading}
              size="small"
              bordered
              footer={null}
            >
              <Table.Column
                onCell={(record, rowIndex) => {
                  return {
                    style: {
                      backgroundColor:
                        record.status === "ABSENT"
                          ? "#ff4d4f"
                          : record.status === "LATE"
                            ? "#ffc069"
                            : record.status === "EXCUSED"
                              ? "#bae637"
                              : "#52c41a",
                    },
                  };
                }}
                title="ID"
                dataIndex="number"
                width={50}
                align="center"
                render={(text, record, index) => {
                  return (
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      {text}
                    </span>
                  );
                }}
              />
              <Table.Column title="Nama" dataIndex="name" width={200} />
              <Table.Column
                title={
                  <Row justify="space-between">
                    <span>Kehadiran</span>
                    {/* mark all as */}
                    <Tooltip
                      placement="top"
                      title={"Tandai semua kehadiran siswa"}
                    >
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: 1,
                              label: "Tandai sbg alpa",
                              onClick: () => {
                                const tempAttendees = [...attendees];
                                tempAttendees.forEach((item) => {
                                  item.status = "ABSENT";
                                  item.permission = null;
                                });
                                setAttendees(tempAttendees);
                              },
                            },
                            {
                              key: 2,
                              label: "Tandai sbg hadir",
                              onClick: () => {
                                const tempAttendees = [...attendees];
                                tempAttendees.forEach((item) => {
                                  item.status = "PRESENT";
                                  item.permission = null;
                                });
                                setAttendees(tempAttendees);
                              },
                            },
                            {
                              key: 3,
                              label: "Tandai sbg terlambat",
                              onClick: () => {
                                const tempAttendees = [...attendees];
                                tempAttendees.forEach((item) => {
                                  item.status = "LATE";
                                  item.permission = null;
                                });
                                setAttendees(tempAttendees);
                              },
                            },
                            {
                              key: 4,
                              label: "Tandai sbg izin",
                              onClick: () => {
                                const tempAttendees = [...attendees];
                                tempAttendees.forEach((item) => {
                                  item.status = "EXCUSED";
                                });
                                setAttendees(tempAttendees);
                              },
                            },
                          ],
                        }}
                        placement="bottomCenter"
                        trigger={["click"]}
                        arrow
                      >
                        <Button
                          type="default"
                          shape="circle"
                          size="small"
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </Tooltip>
                  </Row>
                }
                dataIndex="status"
                width={120}
                render={(text, record, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        size="small"
                        defaultValue="PRESENT"
                        style={{
                          flex: "1 1 auto",
                        }}
                        value={attendees[index].status}
                        onChange={(value) => {
                          const tempAttendees = [...attendees];
                          tempAttendees[index].status = value;

                          if (value !== "EXCUSED") {
                            tempAttendees[index].permission = null;
                          } else {
                            tempAttendees[index].permission = {
                              type: "ILLNESS",
                              letter: "",
                            };
                          }

                          setAttendees(tempAttendees);
                        }}
                      >
                        <Select.Option value="ABSENT">Alpa</Select.Option>
                        <Select.Option value="PRESENT">Hadir</Select.Option>
                        <Select.Option value="LATE">Terlambat</Select.Option>
                        <Select.Option value="EXCUSED">Izin</Select.Option>
                      </Select>
                      {/* set permission for excused */}
                      {attendees[index].status === "EXCUSED" && (
                        <Select
                          size="small"
                          defaultValue="ILLNESS"
                          style={{
                            flex: "1 1 auto",
                            marginLeft: 6,
                          }}
                          value={
                            attendees[index].permission
                              ? attendees[index].permission.type
                              : "ILLNESS"
                          }
                          onChange={(value) => {
                            const tempAttendees = [...attendees];
                            tempAttendees[index].permission = {
                              type: value,
                              letter: "",
                            };
                            setAttendees(tempAttendees);
                          }}
                        >
                          <Select.Option value="ILLNESS">Sakit</Select.Option>
                          <Select.Option value="LEAVE">Pergi</Select.Option>
                        </Select>
                      )}
                      {attendees[index].permission &&
                        attendees[index].permission.letter && (
                          <Tooltip
                            placement="top"
                            title={"Buka surat perizinan siswa"}
                          >
                            <HiEnvelope
                              style={{
                                marginLeft: 6,
                                // color primary
                                color: "#1890ff",
                                cursor: "pointer",
                              }}
                              className="message-animation"
                              onClick={() => {
                                modal.info({
                                  title: "Surat Perizinan",
                                  content: (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>
                                        {attendees[index].permission?.letter}
                                      </span>
                                      <span
                                        style={{
                                          marginTop: 8,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Wali murid {record.name}{" "}
                                      </span>
                                    </div>
                                  ),
                                });
                              }}
                            />
                          </Tooltip>
                        )}
                    </div>
                  );
                }}
              />
              <Table.Column
                title="Nilai"
                dataIndex="score"
                width={60}
                render={(text, record, index) => {
                  return (
                    <Input
                      size="small"
                      value={attendees[index].score}
                      onChange={(e) => {
                        const tempAttendees = [...attendees];
                        tempAttendees[index].score = e.target.value
                          ? parseFloat(e.target.value)
                          : 0;
                        setAttendees(tempAttendees);
                      }}
                    />
                  );
                }}
              />
              <Table.Column
                title="Keterangan"
                dataIndex="note"
                width={200}
                render={(text, record, index) => {
                  return (
                    <Input
                      size="small"
                      value={attendees[index].note}
                      onChange={(e) => {
                        const tempAttendees = [...attendees];
                        tempAttendees[index].note = e.target.value;
                        setAttendees(tempAttendees);
                      }}
                    />
                  );
                }}
              />
            </Table>
            <Form.Item
              label="Keterangan"
              style={{ marginTop: 12, marginBottom: 0 }}
              name="note"
              tooltip="Masukkan keterangan kegiatan"
            >
              <Form.Item name="note" noStyle>
                <Input.TextArea
                  rows={4}
                  placeholder="Masukkan keterangan pertemuan"
                />
              </Form.Item>
            </Form.Item>
            {/* checkbox is report is signed */}
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="isSigned"
              valuePropName="checked"
            >
              <Checkbox>
                Tandai laporan pertemuan ini sudah ditandatangani oleh pengajar
              </Checkbox>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default ExtracurricularReportFormModal;
