/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import {
  requestAddEvent,
  requestDeleteEvent,
  requestEditEvent,
} from "../../../../services/admin.service";
import { useState } from "react";
import dayjs from "dayjs";

const EventFormModal = ({
  event,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
  onReport,
}) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  const [startedAt, setstartedAt] = useState(null);
  const [endedAt, setendedAt] = useState(null);

  useEffect(() => {
    if (event !== null) {
      form.setFieldsValue({
        type: event.type,
        name: event.name,
        description: event.description,
        time: [dayjs(event.startedAt), dayjs(event.endedAt)],
      });
    }
  }, [event]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        values.startedAt = startedAt;
        values.endedAt = endedAt;
        if (event) {
          requestEditEvent(event._id, values)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          requestAddEvent(values)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleDelete = () => {
    onLoading(true);
    requestDeleteEvent(event._id)
      .then((response) => {
        message.success(response.data.message);
        onSuccess();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        onLoading(false);
      });
  };

  const handleReportButton = () => {
    onReport();
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onTimeChange = (value, dateString) => {};

  const onTimeOk = (value) => {
    setstartedAt(dayjs(value[0]).format("YYYY-MM-DD HH:mm"));
    setendedAt(dayjs(value[1]).format("YYYY-MM-DD HH:mm"));
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          event === null || event === undefined
            ? "Tambah Kegiatan"
            : "Lihat Kegiatan"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          event &&
            event.type === "LESSON" &&
            dayjs(event.startedAt).toDate() < dayjs().toDate() && (
              <Button key={4} onClick={handleReportButton} type="dashed">
                Laporan
              </Button>
            ),
          <Button key={1} onClick={handleClose}>
            Tutup
          </Button>,
          event && event.type !== "LESSON" && (
            <Popconfirm
              key={2}
              onConfirm={handleDelete}
              title="Menghapus kegiatan akan menghapus seluruh data terkait (termasuk laporan). Tindakan ini tidak dapat dibatalkan. Anda yakin akan menghapus?"
              okText="Ya"
              cancelText="Tidak"
            >
              <Button type="primary" danger>
                {"Hapus"}
              </Button>
            </Popconfirm>
          ),
          (!event || (event && event.type !== "LESSON")) && (
            <Popconfirm
              key={3}
              onConfirm={handleSubmit}
              title="Yakin akan menyimpan data kegiatan?"
              okText="Ya"
              disabled={event && event.type === "LESSON"}
              cancelText="Tidak"
            >
              <Button type="primary">
                {event ? "Simpan Perubahan" : "Tambah"}
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="type"
              label="Tipe"
              required
              rules={[{ required: true, message: "Pilih tipe kegiatan!" }]}
              tooltip="Pilih tipe kegiatan"
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="LESSON">Pelajaran</Select.Option>
                <Select.Option value="EXTRA">
                  Kegiatan Ekstra Sekolah
                </Select.Option>
                <Select.Option value="OTHER">Kegiatan Lainnya</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              rules={[
                { required: true, message: "Nama kegiatan harus diisi!" },
              ]}
              tooltip="Masukkan nama kegiatan"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="description"
              label="Deskripsi"
              tooltip="Masukkan deskripsi kegiatan"
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="time"
              label="Waktu"
              required
              rules={[
                { required: true, message: "Waktu kegiatan harus diisi!" },
              ]}
              tooltip="Masukkan waktu kegiatan"
            >
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onTimeChange}
                onOk={onTimeOk}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default EventFormModal;
