/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Upload,
  message,
  notification,
} from "antd";
import React, { useEffect } from "react";
import {
  addTeacher,
  requestEditTeacher,
} from "../../../../services/admin.service";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const TeacherFormModal = ({
  teacher,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [signatureList, setSignatureList] = React.useState([]);

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
      setSignatureList([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (teacher !== null) {
      form.setFieldsValue({
        number: teacher.number,
        name: teacher.name,
        birthPlace: teacher.birthPlace,
        birthDate: dayjs(teacher.birthDate, "YYYY-MM-DD"),
        address: teacher.address,
        gender: teacher.gender,
      });
    }
  }, [teacher]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        const formData = new FormData();
        formData.append("number", values.number);
        formData.append("name", values.name);
        formData.append("birthPlace", values.birthPlace);
        formData.append("birthDate", values.birthDate.format("YYYY-MM-DD"));
        formData.append("address", values.address);
        formData.append("gender", values.gender);

        if (signatureList.length > 0) {
          formData.append("signature", signatureList[0]);
        }

        if (teacher) {
          requestEditTeacher(teacher._id, formData)
            .then((response) => {
              message.success("Data pengajar berhasil diubah");
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification.error({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification.error({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          addTeacher(formData)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          teacher === null || teacher === undefined
            ? "Tambah Pengajar"
            : "Ubah Pengajar"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data pengajar?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">{teacher ? "Simpan" : "Tambah"}</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="number"
              label="NIP"
              required
              rules={[
                {
                  required: true,
                  message: "Masukkan NIP",
                },
              ]}
              tooltip="Masukkan NIP"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              rules={[
                {
                  required: true,
                  message: "Masukkan nama pengajar",
                },
              ]}
              tooltip="Masukkan nama pengajar"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="birthPlace"
              label="Tempat Lahir"
              tooltip="Masukkan tempat lahir pengajar"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="birthDate"
              label="Tanggal Lahir"
              tooltip="Masukkan tanggal lahir pengajar"
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="address"
              label="Alamat"
              tooltip="Masukkan alamat pengajar"
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="gender"
              label="Jenis Kelamin"
              required
              rules={[
                {
                  required: true,
                  message: "Pilih jenis kelamin pengajar",
                },
              ]}
              tooltip="Pilih jenis kelamin pengajar"
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="MALE">Laki-laki</Select.Option>
                <Select.Option value="FEMALE">Perempuan</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="signature"
              label="Tanda Tangan"
              tooltip="Masukkan tanda tangan pengajar"
            >
              {teacher && teacher.signature && (
                <img
                  src={teacher.signature.url}
                  alt="Tanda Tangan"
                  style={{ width: 100, marginRight: 12 }}
                />
              )}
              <Upload
                onRemove={(file) => {
                  const index = signatureList.indexOf(file);
                  const newFileList = signatureList.slice();
                  newFileList.splice(index, 1);
                  setSignatureList(newFileList);
                }}
                beforeUpload={(file) => {
                  setSignatureList([...signatureList, file]);
                  return false;
                }}
                maxCount={1}
              >
                <Button type="dashed" icon={<UploadOutlined />}>
                  Klik untuk pilih ttd baru
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default TeacherFormModal;
