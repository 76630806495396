/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, message, Modal, notification, Popconfirm, Select } from "antd";
import { useEffect, useState } from "react";
import { requestAddCard, requestGetStudentList } from "../../../../services/admin.service";

const CardFormModal = ({ card, open, onClose, onSuccess }) => {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [searching, setSearching] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [students, setStudents] = useState([]);

	useEffect(() => {
		fetchStudents();
	}, []);

	useEffect(() => {
		fetchStudents();
	}, [keyword]);

	useEffect(() => {
		if (card) {
			form.setFieldsValue({
				number: card.number,
			});
		}
	}, [card]);

	const fetchStudents = () => {
		setSearching(true);
		requestGetStudentList({
			keyword: keyword ?? "",
			page: 1,
			limit: 10,
			filter: `status:ACTIVE`,
		})
			.then((response) => {
				// merge unique data
				const uniqueData = [...students, ...response.data.data];
				const uniqueStudents = [...new Map(uniqueData.map((item) => [item["_id"], item])).values()];
				const sortedStudents = uniqueStudents.sort((a, b) => {
					if (a.number < b.number) {
						return -1;
					}
					if (a.number > b.number) {
						return 1;
					}
					return 0;
				});
				setStudents(sortedStudents);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setSearching(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setSubmitting(true);
				requestAddCard(values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setSubmitting(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={!card ? "Tambah Kartu" : "Ubah Kartu"}
				open={open}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data kartu?"
						okText="Ya"
						cancelText="Tidak">
						<Button loading={submitting} type="primary">
							{card ? "Simpan" : "Tambah"}
						</Button>
					</Popconfirm>,
				]}>
				<Form form={form} layout="vertical" requiredMark="optional">
					<Form.Item
						style={{ marginTop: 0, marginBottom: 0 }}
						name="number"
						label="Nomor Kartu"
						required
						rules={[{ required: true, message: "Masukkan nomor kartu!" }]}
						tooltip="Masukkan nomor kartu">
						<Input style={{ width: "100%" }} />
					</Form.Item>
					<Form.Item
						style={{ marginTop: 12, marginBottom: 0 }}
						name="studentId"
						label="Siswa"
						required
						rules={[{ required: true, message: "Pilih siswa!" }]}
						tooltip="Pilih siswa">
						<Select
							placeholder="Pilih Siswa"
							showSearch
							optionFilterProp="children"
							onSearch={(value) => {
								setKeyword(value);
							}}
							virtual={true}
							loading={searching}
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}>
							{students.map((student) => (
								<Select.Option key={student._id} value={student._id}>
									{`[${student.number}] ${student.name}`}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default CardFormModal;
