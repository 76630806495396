import {
	PiChatTeardropDotsDuotone,
	PiHandsClappingDuotone,
	PiHandshakeDuotone,
	PiHeartDuotone,
	PiLightbulbDuotone,
	PiSmileyMeltingDuotone,
	PiThumbsUpDuotone,
} from "react-icons/pi";

const REACTIONS = {
	LIKE: <PiThumbsUpDuotone size={24} />,
	CELEBRATE: <PiHandsClappingDuotone size={24} />,
	LOVE: <PiHeartDuotone size={24} />,
	INSIGHTFUL: <PiLightbulbDuotone size={24} />,
	CURIOUS: <PiChatTeardropDotsDuotone size={24} />,
	FUNNY: <PiSmileyMeltingDuotone size={24} />,
	SUPPORT: <PiHandshakeDuotone size={24} />,
};

export default REACTIONS;
