import axios from "axios";

export async function requestRefreshToken(token) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
		token,
	});
}

export async function requestGetProfile() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
}

export async function requestGetNotificationList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/notifications?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestReadAllNotification() {
	return await axios.post(`${process.env.REACT_APP_API_URL}/notifications/read`);
}

export async function requestReadNotification(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/notifications/${id}/read`);
}

export async function requestGetEventList(filter = "") {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/events?order=startedAt:asc&filter=${filter}`
	);
}

export async function requestGetPaymentList(amount) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/payments?amount=${amount}`);
}

// SOCIAL-STREAM
export async function requestGetSocialStreamList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/@social-module/streams?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddSocialStream(payload) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/@social-module/streams/add`, payload);
}

export async function requestEditSocialStream(id, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/@social-module/streams/${id}/edit`,
		payload
	);
}

export async function requestDeleteSocialStream(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/@social-module/streams/${id}/delete`);
}

export async function requestReactSocialStream(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/@social-module/streams/${id}/react`,
		payload
	);
}
// SOCIAL-STREAM

// SOCIAL-COMMENT
export async function requestGetSocialCommentList(
	id,
	{ page = 1, limit = 10, order = "_id:desc", keyword = "", filter = "" }
) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/@social-module/streams/${id}/comments?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddSocialComment(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/@social-module/streams/${id}/comments/add`,
		payload
	);
}

export async function requestDeleteSocialComment(id, commentId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/@social-module/streams/${id}/comments/${commentId}/delete`
	);
}
// SOCIAL-COMMENT
