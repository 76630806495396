import FileResizer from "react-image-file-resizer";

export const resizeImage = (
  file,
  maxWidth = 1920,
  maxHeight = 1080,
  quality = 80,
) => {
  const compressFormat = file.type === "image/png" ? "PNG" : "JPEG";

  return new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
    );
  });
};
