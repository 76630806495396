import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Typography,
} from "antd";
import React from "react";
import {
  requestGetClassroomList,
  requestPromoteStudents,
} from "../../../../services/admin.service";

export default function ClassroomPromotionFormModal({
  classroom,
  isVisible,
  onClose,
  isLoading,
  activeYear,
  studentIds,
}) {
  const [form] = Form.useForm();
  const [action, setAction] = React.useState(null);
  const [classrooms, setClassrooms] = React.useState([]);
  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [isClassroomLoading, setIsClassroomLoading] = React.useState(false);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsFormLoading(true);

        requestPromoteStudents({
          classroomId: values.classroomId,
          studentIds: studentIds,
          action: action,
        })
          .then((response) => {
            message.success(response.data.message);

            form.resetFields();
            onClose();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              notification["error"]({
                message: "Kesalahan!",
                description: error.response.data.message,
              });
            } else {
              notification["error"]({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            setIsFormLoading(false);
          });
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setIsFormLoading(false);
    setIsClassroomLoading(false);
    form.resetFields();
    onClose();
  };

  React.useEffect(() => {
    if (isVisible) {
      form.setFieldValue("classroomId", null);

      if (action === "PROMOTE") {
        fetchClassrooms(parseInt(classroom.grade) + 1);
      } else if (action === "DEMOTE") {
        fetchClassrooms(parseInt(classroom.grade) - 1);
      } else if (action === "RETAIN") {
        fetchClassrooms(classroom.grade);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const fetchClassrooms = (grade) => {
    setIsClassroomLoading(true);
    requestGetClassroomList({
      filter: `grade:${grade},year:${activeYear._id}`,
    })
      .then((response) => {
        setClassrooms(response.data.data);
      })
      .catch((error) => {
        notification.error({
          message: "Gagal mengambil data",
          description: error.message,
        });
      })
      .finally(() => {
        setIsClassroomLoading(false);
      });
  };

  return (
    <Modal
      maskClosable={false}
      width={512}
      title={"Promosikan Siswa untuk Tahun Ajaran Baru"}
      open={isVisible}
      cancelText="Batal"
      onCancel={handleClose}
      footer={[
        <Button key={1} onClick={handleClose}>
          Tutup
        </Button>,
        <Popconfirm
          key={2}
          onConfirm={handleSubmit}
          title="Yakin akan melakukan proses promosi?"
          okText="Ya"
          cancelText="Tidak"
        >
          <Button type="primary">{"Aplikasikan"}</Button>
        </Popconfirm>,
      ]}
    >
      <Spin spinning={isLoading || isFormLoading}>
        <Form form={form} layout="vertical" requiredMark="optional">
          <Form.Item
            style={{ marginTop: 0, marginBottom: 0 }}
            name="action"
            label="Aksi"
            tooltip="Pilih aksi"
            rules={[
              {
                required: true,
                message: "Pilih aksi",
              },
            ]}
            required
          >
            <Radio.Group
              onChange={(e) => {
                setAction(e.target.value);
              }}
              options={[
                { label: "Naik", value: "PROMOTE" },
                { label: "Turun", value: "DEMOTE" },
                { label: "Tetap", value: "RETAIN" },
                { label: "Luluskan", value: "GRADUATE" },
              ]}
              style={{ width: "100%" }}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {action && action !== "GRADUATE" && (
            <>
              <Form.Item
                label="Kelas Tujuan"
                style={{ marginTop: 12, marginBottom: 0 }}
                name="classroomId"
                rules={[{ required: true, message: "Pilih kelas tujuan" }]}
              >
                <Select
                  showSearch
                  placeholder="Pilih kelas tujuan"
                  optionFilterProp="children"
                  loading={isClassroomLoading}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {classrooms.map((classroom) => (
                    <Select.Option key={classroom._id} value={classroom._id}>
                      {classroom.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* Note */}
              <Typography.Text
                type="secondary"
                style={{
                  display: "block",
                  marginBottom: "16px",
                }}
              >
                Opsi kelas merupakan kelas yang diambil dari tahun aktif saat
                ini.
              </Typography.Text>
            </>
          )}
          <Form.Item
            label="Keterangan"
            style={{ marginTop: 12, marginBottom: 0 }}
            name="note"
          >
            <Input.TextArea
              style={{ width: "100%" }}
              placeholder="Keterangan"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
