/* eslint-disable react-hooks/exhaustive-deps */
import {
	App,
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	message,
	Modal,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import {
	CheckCircleFilled,
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	DownloadOutlined,
	ExclamationCircleFilled,
	EyeOutlined,
	Loading3QuartersOutlined,
	MoreOutlined,
	SwapOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import writeXlsxFile from "write-excel-file";
import {
	requestDeleteReportCard,
	requestGetAttendanceReportList,
	requestGetReportMarkList,
	requestShowClassroom,
} from "../../../../services/teacher.service";
import { HiClipboardDocumentCheck, HiClipboardDocumentList } from "react-icons/hi2";
import dayjs from "dayjs";
import ClassroomDailyReportFormModal from "./ClassroomDailyReportFormModal";
import ClassroomReportCardFormModal from "./ClassroomReportCardFormModal";
import ClassroomReportCardDetailModal from "./ClassroomReportCardDetailModal";
import ClassroomStudentMovingFormModal from "./ClassroomStudentMovingFormModal";

dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

const studentColumns = [
	{
		title: "No",
		dataIndex: "order",
		fixed: "left",
		align: "center",
		key: "order",
		width: 36,
	},
	{
		title: "NISN",
		dataIndex: "number",
		key: "number",
		width: 100,
		fixed: "left",
		render: (text, record) => {
			return (
				<Typography.Text
					style={{
						fontFamily: "monospace",
						fontWeight: "bold",
						whiteSpace: "nowrap",
					}}
					copyable>
					{record.number}
				</Typography.Text>
			);
		},
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		fixed: "left",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
		width: 24,
	},
];

const lessonColumns = [
	{
		title: "Kurikulum",
		dataIndex: "curriculum",
		key: "curriculum",
	},
	{
		title: "Matpel",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Pengajar",
		dataIndex: "teachers",
		key: "teachers",
	},
];

export const ClassroomDetailModal = ({ id, year, isVisible, isLoading, onLoading, onClose }) => {
	const { notification } = App.useApp();
	const [classroom, setClassroom] = React.useState(null);
	const [isReportCardFormVisible, setReportCardFormVisible] = React.useState(false);
	const [isDailyReportVisible, setDailyReportVisible] = React.useState(false);
	const [reportCardDetailVisible, setReportCardDetailVisible] = React.useState(false);
	// eslint-disable-next-line no-unused-vars
	const [reportCards, setReportCards] = React.useState([]);

	const [columns, setColumns] = React.useState(studentColumns);

	const [students, setStudents] = React.useState([]);
	const [lessons, setLessons] = React.useState([]);
	const [mappedLessons, setMappedLessons] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [mode, setMode] = React.useState("student");
	const [downloadMode, setDownloadMode] = React.useState("month");
	const [downloadMonth, setDownloadMonth] = React.useState(dayjs().format("YYYY-MM"));
	const [attendanceReports, setAttendanceReports] = React.useState([]);

	const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);

	const [reportCard, setReportCard] = React.useState(null);

	const [isMoveStudentFormVisible, setMoveStudentFormVisible] = React.useState(false);
	const [student, setStudent] = React.useState(null);

	useEffect(() => {
		if (!isVisible) {
			setMappedLessons([]);
			setMappedStudents([]);
			setMode("student");
			setSelectedStudentIds([]);
			setReportCard(null);
			setReportCardDetailVisible(false);
		}
	}, [isVisible]);

	useEffect(() => {
		if (id) {
			fetchClassroom();
		} else {
			setClassroom(null);
		}
	}, [id]);

	useEffect(() => {
		if (classroom) {
			updateMappedItems();
		}
	}, [classroom]);

	useEffect(() => {
		setColumns(studentColumns);
		if (mode === "attendance") {
			fetchClassroomAttendanceReports();
		} else if (mode === "report") {
			fetchMarks();
		} else {
			setColumns(studentColumns);
		}
	}, [mode]);

	const updateMappedItems = (invoices = null, attendances = null) => {
		const lessons = classroom.lessons;
		const students = classroom.students;

		setLessons(lessons);
		setStudents(students);

		const mappedLessons = lessons.map((lesson) => {
			const teachers = lesson.teachers;

			return {
				key: lesson._id,
				curriculum: lesson.curriculum.name,
				name: lesson.subject.name,
				teachers: (
					<>
						<Space wrap>
							{teachers.map((teacher, index) => {
								return (
									<Space size={1} direction="horizontal" key={index}>
										<Typography.Text>
											{teacher.name}
											<Tag
												color="blue"
												style={{
													marginLeft: 4,
													marginRight: 0,
												}}>
												{teacher.type === "MAIN"
													? "Utama"
													: teacher.type === "ASSISTANT"
													? "Asisten"
													: "Pengganti"}
											</Tag>
										</Typography.Text>
									</Space>
								);
							})}
						</Space>
					</>
				),
			};
		});

		setMappedLessons(mappedLessons);

		const mappedStudents = students
			.sort((a, b) => ("" + a.name).localeCompare(b.name))
			.map((student, index) => {
				return {
					key: student._id,
					order: index + 1,
					number: student.number,
					name: student.name,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "move") {
											handleMoveStudent(student);
										}
									},
									items: [
										{
											key: "move",
											label: "Pindah Kelas",
											icon: <SwapOutlined />,
										},
									],
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			});

		setMappedStudents(mappedStudents);
	};

	const fetchClassroom = () => {
		onLoading(true);
		requestShowClassroom(id)
			.then((response) => {
				const classroom = response.data.data;

				setClassroom(classroom);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchClassroomAttendanceReports = () => {
		onLoading(true);

		requestGetAttendanceReportList({
			order: "date:asc",
			limit: 1000,
			filter: `type:CLASSROOM,reference:${classroom._id},start:${year.startedAt},end:${year.endedAt}`,
		})
			.then((response) => {
				const reports = response.data.data;
				const cols = [...studentColumns];
				const semesters = year.semesters;

				setAttendanceReports(reports);

				for (const semester of semesters) {
					const semesterCols = [];
					const monthInSemester = dayjs(semester.endedAt).diff(semester.startedAt, "month") + 1;
					const semesterMonths = [];

					for (let i = 0; i < monthInSemester; i++) {
						semesterMonths.push(dayjs(semester.startedAt).add(i, "month").format("MMMM YYYY"));
					}

					for (let month of semesterMonths) {
						const monthCols = [];
						const monthReports = reports.filter(
							(attendance) => dayjs(attendance.date).format("MMMM YYYY") === month
						);

						// PUSH TO MONTHCOLS
						for (let report of monthReports) {
							monthCols.push({
								title: dayjs(report.date).format("D"),
								dataIndex: report._id,
								key: report._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const attendee = report.attendees.find(
										(attendee) => attendee.referenceId === record.key
									);

									if (attendee) {
										if (attendee.status === "PRESENT") {
											return (
												<Tooltip title="Hadir" placement="top">
													<Tag
														color="green"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														H
													</Tag>
												</Tooltip>
											);
										} else if (attendee.status === "ABSENT") {
											return (
												<Tooltip title="Tidak Hadir" placement="top">
													<Tag
														color="red"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														A
													</Tag>
												</Tooltip>
											);
										} else if (
											attendee.status === "EXCUSED" &&
											attendee?.permission?.type === "ILLNESS"
										) {
											return (
												<Tooltip title="Izin Sakit" placement="top">
													<Tag
														color="blue"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														S
													</Tag>
												</Tooltip>
											);
										} else if (
											attendee.status === "EXCUSED" &&
											attendee?.permission?.type === "LEAVE"
										) {
											return (
												<Tooltip title="Izin Pergi" placement="top">
													<Tag
														color="purple"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														I
													</Tag>
												</Tooltip>
											);
										} else if (attendee.status === "LATE") {
											return (
												<Tooltip title="Terlambat" placement="top">
													<Tag
														color="orange"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														T
													</Tag>
												</Tooltip>
											);
										} else {
											return (
												<Tooltip title="Belum diisi" placement="top">
													<Tag
														color="default"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														-
													</Tag>
												</Tooltip>
											);
										}
									} else {
										return (
											<Tooltip title="Belum diisi" placement="top">
												<Tag
													color="default"
													style={{
														fontWeight: "normal",
														marginInlineEnd: 0,
														width: 25,
														textAlign: "center",
													}}>
													?
												</Tag>
											</Tooltip>
										);
									}
								},
							});
						}
						// PUSH TO MONTHCOLS

						if (monthCols.length > 0) {
							// PUSH TO SEMESTERCOLS
							semesterCols.push({
								title: `${month} (${monthCols.length})`,
								dataIndex: month,
								key: month,
								width: 40,
								align: "center",
								children: monthCols,
							});
							// PUSH TO SEMESTERCOLS
						}
					}

					// ADD SUMMARY PER SEMESTER
					const semesterReports = reports.filter(
						(report) =>
							dayjs(report.date).isSameOrAfter(semester.startedAt) &&
							dayjs(report.date).isSameOrBefore(semester.endedAt)
					);

					semesterCols.push({
						title: "Keterangan",
						dataIndex: "summary_" + semester._id,
						key: "summary_" + semester._id,
						align: "center",
						width: 40,
						children: [
							{
								title: <Tooltip title="Hadir">H</Tooltip>,
								dataIndex: "present_" + semester._id,
								key: "present_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const present = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "PRESENT"
										)
									);

									return <Tooltip title="Hadir">{present.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Izin Sakit">S</Tooltip>,
								dataIndex: "illness_" + semester._id,
								key: "illness_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const sick = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "ILLNESS"
										)
									);

									return <Tooltip title="Sakit">{sick.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Izin Pergi">I</Tooltip>,
								dataIndex: "excused_" + semester._id,
								key: "excused_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const excused = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "LEAVE"
										)
									);

									return <Tooltip title="Izin">{excused.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Tidak hadir">A</Tooltip>,
								dataIndex: "absent_" + semester._id,
								key: "absent_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const absent = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "ABSENT"
										)
									);

									return <Tooltip title="Tidak hadir">{absent.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Terlambat">T</Tooltip>,
								dataIndex: "late_" + semester._id,
								key: "late_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const late = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "LATE"
										)
									);

									return <Tooltip title="Terlambat">{late.length}</Tooltip>;
								},
							},
							// total
							{
								title: <Tooltip title="Total">#</Tooltip>,
								dataIndex: "total_" + semester._id,
								key: "total_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const present = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "PRESENT"
										)
									);

									const sick = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "ILLNESS"
										)
									);

									const excused = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "LEAVE"
										)
									);

									const absent = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "ABSENT"
										)
									);

									const late = semesterReports.filter((report) =>
										report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "LATE"
										)
									);

									return (
										<Tooltip title="Total">
											{present.length + sick.length + excused.length + absent.length + late.length}
										</Tooltip>
									);
								},
							},
						],
					});
					// ADD SUMMARY PER SEMESTER

					if (semesterCols.length > 0) {
						cols.push({
							title: semester.name,
							dataIndex: semester._id,
							key: semester._id,
							align: "center",
							children: semesterCols,
							width: 40,
						});
					}
				}

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchMarks = () => {
		onLoading(true);

		const lessonIds = lessons.map((lesson) => lesson._id);

		requestGetReportMarkList({
			page: 1,
			limit: 1000,
			filter: `lesson:${lessonIds.join("|")}`,
		})
			.then((response) => {
				const marks = response.data.data;

				const reportCards = [];
				for (const mark of marks) {
					if (
						!reportCards
							.map((reportCard) => reportCard?._id.toString())
							.includes(mark.card?._id.toString())
					) {
						reportCards.push(mark.card);
					}
				}

				const semesters = year.semesters;

				setReportCards(reportCards);

				const curriculums = [];
				for (const lesson of lessons) {
					if (
						!curriculums
							.map((curriculum) => curriculum._id.toString())
							.includes(lesson.curriculum._id.toString())
					) {
						curriculums.push(lesson.curriculum);
					}
				}

				const cols = [...studentColumns];

				for (const semester of semesters) {
					const semesterCols = [];

					for (const curriculum of curriculums) {
						const curriculumCols = [];
						const curriculumLessons = lessons.filter(
							(lesson) => lesson.curriculum._id.toString() === curriculum._id.toString()
						);

						for (const lesson of curriculumLessons) {
							curriculumCols.push({
								title: (
									<Tooltip title={lesson.subject.name}>
										{lesson.subject.name.length > 5
											? `${lesson.subject.name.substring(0, 5)}..`
											: lesson.subject.name}
									</Tooltip>
								),
								dataIndex: lesson._id,
								key: lesson._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const mark = marks.find(
										(mark) =>
											mark.card?.studentId === record.key &&
											mark.lessonId === lesson._id &&
											mark.card.semesterId === semester._id
									);

									if (mark) {
										if (mark.signature && mark.score !== null && mark.score.final !== null) {
											const final = mark.score.final;
											return (
												<Tooltip title="Sudah ditandatangani" placement="top">
													<Space>
														{final}
														<CheckOutlined style={{ color: "green" }} />
													</Space>
												</Tooltip>
											);
										} else {
											const final = mark.assessments.reduce((acc, assessment) => {
												return (
													acc +
													(mark.assessments.find((a) => a._id === assessment._id)?.score *
														mark.assessments.find((a) => a._id === assessment._id)?.weight || 0)
												);
											}, 0);

											return (
												<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
													<Space>
														{final}
														<Loading3QuartersOutlined
															style={{
																color: "orange",
															}}
															spin
														/>
													</Space>
												</Tooltip>
											);
										}
									}

									return (
										<Tooltip
											title={`Rapor belum dibuat, silakan buat melalui tombol "Buat Buku Rapor" di atas`}
											placement="top">
											<Space>
												<CloseOutlined style={{ color: "red" }} />
											</Space>
										</Tooltip>
									);
								},
							});
						}

						// add last col for button
						curriculumCols.push(
							...[
								{
									title: "TTD",
									dataIndex: "signature",
									key: "signature",
									width: 40,
									align: "center",
									render: (text, record) => {
										const card = reportCards.find(
											(card) => card?.studentId === record.key && card.semesterId === semester._id
										);

										if (!card) {
											return (
												<Space direction="horizontal" size="small">
													<Tooltip title="Buku rapor belum dibuat oleh wali kelas" placement="top">
														<Tag
															style={{
																fontWeight: "normal",
																marginInlineEnd: 0,
																textAlign: "center",
															}}
															color="default">
															Wali Kelas
														</Tag>
													</Tooltip>
													<Tooltip title="Buku rapor belum dibuat oleh wali kelas" placement="top">
														<Tag
															style={{
																fontWeight: "normal",
																marginInlineEnd: 0,
																textAlign: "center",
															}}
															color="default">
															Kepala Sekolah
														</Tag>
													</Tooltip>
												</Space>
											);
										}

										return (
											<Space direction="horizontal" size="small">
												<Tooltip
													title={
														card?.signature?.advisor?._id
															? "Wali Kelas sudah menandatangani"
															: "Wali Kelas belum menandatangani"
													}>
													<Tag
														color={card?.signature?.advisor?._id ? "success" : "error"}
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															textAlign: "center",
														}}>
														Wali Kelas
													</Tag>
												</Tooltip>
												<Tooltip
													title={
														card?.signature?.headmaster?._id
															? "Kepala Sekolah sudah menandatangani"
															: "Kepala Sekolah belum menandatangani"
													}>
													<Tag
														color={card?.signature?.headmaster?._id ? "success" : "error"}
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															textAlign: "center",
														}}>
														Kepala Sekolah
													</Tag>
												</Tooltip>
											</Space>
										);
									},
								},
								{
									title: "Status",
									dataIndex: "status",
									key: "status",
									width: 40,
									align: "center",
									render: (text, record) => {
										const card = reportCards.find(
											(card) => card?.studentId === record.key && card.semesterId === semester._id
										);

										if (!card) {
											return (
												<Tooltip title="Buku rapor belum dibuat oleh wali kelas" placement="top">
													<Tag
														style={{
															fontWeight: "bold",
															marginInlineEnd: 0,
															textAlign: "center",
														}}
														color="default">
														N/A
													</Tag>
												</Tooltip>
											);
										}

										return (
											<Tooltip
												title={
													card?.status === "PUBLISHED"
														? "Buku rapor sudah diterbitkan sehingga dapat dilihat oleh siswa dan wali murid"
														: card?.status === "DRAFT"
														? "Buku rapor belum diterbitkan"
														: "Buku rapor belum dibuat"
												}>
												<Tag
													style={{
														fontWeight: "bold",
														marginInlineEnd: 0,
														textAlign: "center",
													}}
													color={
														card?.status === "PUBLISHED"
															? "success"
															: card?.status === "DRAFT"
															? "warning"
															: "error"
													}>
													{card?.status === "PUBLISHED"
														? "TERBIT"
														: card?.status === "DRAFT"
														? "DRAF"
														: "N/A"}
												</Tag>
											</Tooltip>
										);
									},
								},
								{
									title: "Aksi",
									dataIndex: curriculum._id,
									key: curriculum._id,
									width: 40,
									align: "center",
									render: (text, record) => {
										const card = reportCards.find(
											(card) => card?.studentId === record.key && card.semesterId === semester._id
										);

										return (
											<Space direction="horizontal" size="small">
												<Button
													disabled={!card}
													type="default"
													size="small"
													icon={<EyeOutlined />}
													onClick={() => {
														if (card) {
															setReportCard(card);
															setReportCardDetailVisible(true);
														}
													}}>
													Lihat
												</Button>
												<Button
													disabled={
														!card ||
														card.status === "PUBLISHED" ||
														card.signature?.advisor?._id ||
														card.signature?.headmaster?._id
													}
													type="primary"
													danger
													size="small"
													icon={<DeleteOutlined />}
													onClick={() => {
														Modal.confirm({
															title: "Apakah Anda yakin akan menghapus buku rapor ini?",
															icon: <ExclamationCircleFilled />,
															content:
																"Anda tidak dapat mengubah buku rapor ini setelah Anda menghapusnya. Setelah Anda menghapus buku rapor, siswa dan wali siswa akan menerima buku rapor melalui aplikasi dan dapat melihatnya di halaman buku rapor.",
															okText: "Ya",
															okType: "danger",
															cancelText: "Tidak",
															onOk() {
																requestDeleteReportCard(card?._id)
																	.then((response) => {
																		message.success(response.data.message);

																		fetchMarks();
																	})
																	.catch((error) => {
																		if (error.response && error.response.data) {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.response.data.message,
																			});
																		} else {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.message,
																			});
																		}
																	})
																	.finally(() => {});
															},
															onCancel() {},
														});
													}}></Button>
											</Space>
										);
									},
								},
							]
						);

						semesterCols.push({
							title: curriculum.name,
							dataIndex: curriculum._id,
							key: curriculum._id,
							width: 40,
							align: "center",
							children: curriculumCols,
						});
					}

					cols.push({
						title: semester.name,
						dataIndex: semester._id,
						key: semester._id,
						align: "center",
						children: semesterCols,
						width: 40,
					});
				}

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		setClassroom(null);
		setMappedLessons([]);
		setMappedStudents([]);
		onClose();
	};

	const handleDailyReportClose = () => {
		setDailyReportVisible(false);
	};

	const handleDailyReportSuccess = () => {
		setDailyReportVisible(false);
		fetchClassroom();
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const actionMenus = [
		// {
		// 	key: "PROMOTE",
		// 	icon: <UpCircleFilled />,
		// 	label: "Promosikan Siswa untuk Tahun Ajaran Baru",
		// },
	];

	const actionMenuProps = {
		items: actionMenus,
		onClick: (e) => {
			if (e.key === "PROMOTE") {
				// setPromotionFormVisible(true);
			}
		},
	};

	const handleMoveStudent = (student) => {
		setStudent(student);
		setMoveStudentFormVisible(true);
	};

	return (
		<>
			<ClassroomDailyReportFormModal
				semester={
					year.semesters.find(
						(semester) =>
							dayjs().isSameOrAfter(semester.startedAt) && dayjs().isSameOrBefore(semester.endedAt)
					) || null
				}
				isVisible={isDailyReportVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleDailyReportClose}
				onSuccess={handleDailyReportSuccess}
				classroom={classroom}
				students={students}
			/>
			<ClassroomReportCardFormModal
				isVisible={isReportCardFormVisible}
				classroom={classroom}
				onClose={() => {
					setReportCardFormVisible(false);
				}}
				onSuccess={() => {
					setReportCardFormVisible(false);
					fetchMarks();
				}}
			/>
			<ClassroomReportCardDetailModal
				isVisible={reportCardDetailVisible}
				reportCardId={reportCard ? reportCard?._id : null}
				onCancel={() => {
					setReportCard(null);
					setReportCardDetailVisible(false);
				}}
			/>
			<ClassroomStudentMovingFormModal
				classroom={classroom ? classroom : null}
				student={student}
				isVisible={isMoveStudentFormVisible}
				onClose={() => {
					setMoveStudentFormVisible(false);
					setStudent(null);
				}}
				onSuccess={() => {
					setMoveStudentFormVisible(false);
					setStudent(null);
					fetchClassroom();
				}}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={"Detail Kelas"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{classroom && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col span={24}>
								<Space direction="horizontal">
									<Typography.Title level={3} style={{ margin: 0 }}>
										{classroom.name}
									</Typography.Title>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Kelas" size="small">
										<Descriptions size="small" layout="vertical" column={2}>
											<Descriptions.Item label="Tahun">{classroom.year.name}</Descriptions.Item>
											<Descriptions.Item label="Nama">{classroom.name}</Descriptions.Item>
											<Descriptions.Item label="Tingkat">{classroom.grade}</Descriptions.Item>
											<Descriptions.Item label="Wali Kelas">
												{/* wrap */}
												<Space wrap>
													{classroom.teachers.length > 0 &&
														classroom.teachers.map((teacher, index) => {
															return (
																<span key={index}>
																	{teacher.name}
																	<Tag
																		color="blue"
																		style={{
																			marginLeft: 4,
																			marginRight: 0,
																			fontWeight: "normal",
																		}}>
																		{teacher.type === "ADVISOR" ? "Wali Kelas" : "Asisten"}
																	</Tag>
																</span>
															);
														})}
												</Space>
											</Descriptions.Item>
										</Descriptions>
									</Card>
									<Card title={"Aksi"} size="small">
										<Row gutter={[8, 8]}>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														setDailyReportVisible(true);
													}}>
													<HiClipboardDocumentCheck
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Presensi Harian
												</Button>
											</Col>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														setReportCardFormVisible(true);
													}}>
													<HiClipboardDocumentList
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Buat Buku Rapor
												</Button>
											</Col>
										</Row>
									</Card>
									<Card title="Pelajaran" size="small">
										<Table
											size="small"
											columns={lessonColumns}
											dataSource={mappedLessons}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												defaultPageSize: 100,
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
										/>
									</Card>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card
										title={
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													gap: 8,
												}}>
												<span>Siswa</span>
												<Space wrap>
													<Radio.Group
														size="small"
														value={mode}
														onChange={(e) => handleModeChange(e.target.value)}
														style={{ float: "right" }}>
														<Radio.Button value="student">Siswa</Radio.Button>
														<Radio.Button value="attendance">Presensi</Radio.Button>
														<Radio.Button value="report">Rapor</Radio.Button>
													</Radio.Group>
													{selectedStudentIds.length > 0 && (
														<Dropdown.Button
															type="default"
															size="small"
															menu={actionMenuProps}
															trigger={["click"]}>
															<CheckCircleFilled /> {selectedStudentIds.length} Dipilih
														</Dropdown.Button>
													)}
												</Space>
											</div>
										}
										size="small">
										<Table
											size="small"
											columns={columns}
											dataSource={mappedStudents}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												defaultPageSize: 100,
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
											scroll={{ x: "max-content" }}
											rowSelection={{
												type: "checkbox",
												...{
													onChange: (selectedRowKeys, selectedRows) => {
														setSelectedStudentIds(selectedRowKeys.map((key) => key.toString()));
													},
												},
											}}
										/>
										{mode === "attendance" && (
											// download attendance, can be per month, per semester, or all, if per month, then show month picker, if per semester, then show semester picker
											<Space.Compact
												direction="horizontal"
												size={0}
												style={{
													width: "100%",
													display: "flex",
													justifyContent: "flex-end",
												}}>
												<Select
													style={{
														width: 120,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
													value={downloadMode}
													onChange={(value) => setDownloadMode(value)}>
													{/* <Select.Option value="all">Semua</Select.Option> */}
													<Select.Option value="month">Bulan</Select.Option>
													{/* <Select.Option value="semester">Semester</Select.Option> */}
												</Select>
												{downloadMode === "month" && (
													<Select
														style={{
															minWidth: 120,
															borderRadius: 0,
														}}
														defaultValue={dayjs().format("YYYY-MM")}
														value={downloadMonth}
														onChange={(value) => setDownloadMonth(value)}>
														{year.semesters
															.flatMap((semester) => {
																const monthInSemester =
																	dayjs(semester.endedAt).diff(semester.startedAt, "month") + 1;
																const semesterMonths = [];

																for (let i = 0; i < monthInSemester; i++) {
																	semesterMonths.push(
																		dayjs(semester.startedAt).add(i, "month").format("YYYY-MM")
																	);
																}

																return semesterMonths;
															})
															.map((month) => {
																return (
																	<Select.Option key={month} value={month}>
																		{month}
																	</Select.Option>
																);
															})}
													</Select>
												)}
												{downloadMode === "semester" && (
													<Select
														style={{
															minWidth: 120,
															borderRadius: 0,
														}}
														defaultValue={year.semesters[0]._id}
														// value={downloadSemester}
														// onChange={(value) => setDownloadSemester(value)}
													>
														{year.semesters.map((semester) => {
															return (
																<Select.Option key={semester._id} value={semester._id}>
																	{semester.name}
																</Select.Option>
															);
														})}
													</Select>
												)}
												<Button
													type="default"
													icon={<DownloadOutlined />}
													style={{
														borderTopLeftRadius: 0,
														borderBottomLeftRadius: 0,
													}}
													size="middle"
													onClick={async () => {
														const selectedMonth = downloadMonth;
														const reportsInMonth = attendanceReports.filter(
															(report) => dayjs(report.date).format("YYYY-MM") === selectedMonth
														);

														// HEADER_ROW from first day of month to last day of month
														const HEADER_ROW = [
															{
																value: "No",
																fontWeight: "bold",
															},
															{
																value: "Nama",
																fontWeight: "bold",
															},
														];
														const daysInMonth = dayjs(selectedMonth).daysInMonth();
														for (let i = 1; i <= daysInMonth; i++) {
															HEADER_ROW.push({
																value: i,
																fontWeight: "bold",
																align: "center",
															});
														}
														// ADD SUMMARY of each H, A, S, I, T
														HEADER_ROW.push({
															value: "H",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "S",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "I",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "A",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "T",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "Total",
															fontWeight: "bold",
															align: "center",
														});

														const DATA_ROW = mappedStudents.map((student, index) => {
															const row = [
																{
																	value: index + 1,
																},
																{
																	value: student.name,
																},
															];

															for (let i = 1; i <= daysInMonth; i++) {
																const report = reportsInMonth.find(
																	(report) =>
																		dayjs(report.date).format("D") === i.toString() &&
																		report.attendees.find(
																			(attendee) => attendee.referenceId === student.key
																		)
																);

																if (report) {
																	const attendee = report.attendees.find(
																		(attendee) => attendee.referenceId === student.key
																	);

																	if (attendee) {
																		if (attendee.status === "PRESENT") {
																			row.push({
																				value: "H",
																				backgroundColor: "#52c41a",
																				align: "center",
																			});
																		} else if (attendee.status === "ABSENT") {
																			row.push({
																				value: "A",
																				backgroundColor: "#f5222d",
																				align: "center",
																			});
																		} else if (
																			attendee.status === "EXCUSED" &&
																			attendee?.permission?.type === "ILLNESS"
																		) {
																			row.push({
																				value: "S",
																				backgroundColor: "#1890ff",
																				align: "center",
																			});
																		} else if (
																			attendee.status === "EXCUSED" &&
																			attendee?.permission?.type === "LEAVE"
																		) {
																			row.push({
																				value: "I",
																				backgroundColor: "#722ed1",
																				align: "center",
																			});
																		} else if (attendee.status === "LATE") {
																			row.push({
																				value: "T",
																				backgroundColor: "#fa8c16",
																				align: "center",
																			});
																		} else {
																			row.push({
																				value: "-",
																				align: "center",
																			});
																		}
																	} else {
																		row.push({
																			value: "?",
																			align: "center",
																		});
																	}
																} else {
																	row.push({
																		value: "-",
																		align: "center",
																	});
																}
															}

															// ADD SUMMARY of each H, A, S, I, T
															const present = reportsInMonth.filter((report) =>
																report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "PRESENT"
																)
															);

															const illness = reportsInMonth.filter((report) =>
																report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "EXCUSED" &&
																		attendee?.permission?.type === "ILLNESS"
																)
															);

															const leave = reportsInMonth.filter((report) =>
																report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "EXCUSED" &&
																		attendee?.permission?.type === "LEAVE"
																)
															);

															const absent = reportsInMonth.filter((report) =>
																report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "ABSENT"
																)
															);

															const late = reportsInMonth.filter((report) =>
																report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "LATE"
																)
															);

															row.push({
																value: present.length,
																align: "center",
															});
															row.push({
																value: illness.length,
																align: "center",
															});
															row.push({
																value: leave.length,
																align: "center",
															});
															row.push({
																value: absent.length,
																align: "center",
															});
															row.push({
																value: late.length,
																align: "center",
															});
															row.push({
																value:
																	present.length +
																	illness.length +
																	leave.length +
																	absent.length +
																	late.length,
																align: "center",
															});
															return row;
														});

														const data = [HEADER_ROW, ...DATA_ROW];

														const buffer = await writeXlsxFile(data, {
															columns: [
																{ width: 5 },
																{ width: 20 },
																...Array.from({ length: daysInMonth }, () => ({
																	width: 5,
																})),
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 8 },
															],
															buffer: true,
														});

														const blob = new Blob([buffer], {
															type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
														});

														const url = URL.createObjectURL(blob);
														const a = document.createElement("a");

														// download
														a.href = url;
														a.download = `Presensi_${classroom.name}_${selectedMonth}.xlsx`;
														a.click();
													}}>
													Unduh Presensi
												</Button>
											</Space.Compact>
										)}
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
};

export default ClassroomDetailModal;
