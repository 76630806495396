import {
	Alert,
	App,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Dropdown,
	Form,
	Input,
	message,
	Modal,
	Popconfirm,
	Radio,
	Row,
	Select,
	Space,
	Steps,
	Table,
	Tabs,
	TimePicker,
	Tooltip,
	Typography,
} from "antd";
import { CloseOutlined, DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import {
	requestUnenrollStudentsFromExtracurricular,
	requestShowExtracurricular,
	requestScheduleExtracurricular,
	requestGetReportExtracurricularMarkList,
	requestEditReportExtracurricularMark,
	requestSignReportExtracurricularMarks,
} from "../../../../services/teacher.service";
import dayjs from "dayjs";
import ExtracurricularReportFormModal from "./ExtracurricularReportFormModal";

dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

const studentColumns = [
	{
		title: "No",
		dataIndex: "order",
		fixed: "left",
		key: "order",
		width: 36,
	},
	{
		title: "NISN",
		dataIndex: "number",
		key: "number",
		width: 100,
		fixed: "left",
		render: (text, record) => {
			return (
				<Typography.Text
					style={{
						fontFamily: "monospace",
						fontWeight: "bold",
						whiteSpace: "nowrap",
					}}
					copyable>
					{record.number}
				</Typography.Text>
			);
		},
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		fixed: "left",
	},
	{
		title: "NH",
		dataIndex: "score",
		key: "score",
		width: 81,
		align: "center",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
		width: 24,
	},
];

export const ExtracurricularDetailModal = ({ id, isVisible, onClose }) => {
	const { notification } = App.useApp();
	const [isLoading, setLoading] = React.useState(false);

	const [extracurricular, setExtracurricular] = React.useState(null);

	const [semesterTimes, setSemesterTimes] = React.useState([]);
	const [selectedMonth, setSelectedMonth] = React.useState(null);

	const [columns, setColumns] = React.useState(studentColumns);

	// eslint-disable-next-line no-unused-vars
	const [students, setStudents] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [mode, setMode] = React.useState("student");
	const [isSignedMarks, setIsSignedMarks] = React.useState(false);

	// eslint-disable-next-line no-unused-vars
	const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);

	const [isReportExtracurricularVisible, setReportExtracurricularVisible] = React.useState(false);
	const [event, setEvent] = React.useState(null);

	useEffect(() => {
		if (id) {
			fetchExtracurricular();
		} else {
			setExtracurricular(null);
			setIsSignedMarks(false);
		}
		setMode("student");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (extracurricular) {
			updateMappedItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extracurricular]);

	useEffect(() => {
		if (mode === "mark") {
			fetchExtracurricularMarks();
		} else {
			setColumns(studentColumns);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	const updateMappedItems = () => {
		const students = extracurricular.students;
		const events = extracurricular.events ?? [];

		setStudents(students);

		const mappedStudents = students
			.sort((a, b) => ("" + a.name).localeCompare(b.name))
			.map((student, index) => {
				let score = 0;

				events.forEach((event) => {
					const studentScore =
						event?.attendees?.find(
							(attendee) => attendee.type === "STUDENT" && attendee.referenceId === student._id
						)?.score ?? 0;

					score += studentScore;
				});

				return {
					key: student._id,
					extracurricularId: extracurricular._id,
					order: index + 1,
					number: student.number,
					name: student.name,
					score: score,
					grade: student.grade ?? "-",
					note: student.note ?? "-",
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "kick") {
											handleUnenrollStudent(student._id);
										}
									},
									items: [
										{
											key: "kick",
											label: "Keluarkan",
											icon: <CloseOutlined />,
											danger: true,
										},
									],
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			});

		setMappedStudents(mappedStudents);
	};

	const fetchExtracurricular = () => {
		setLoading(true);
		requestShowExtracurricular(id)
			.then((response) => {
				const extracurricular = response.data.data;

				setExtracurricular(extracurricular);

				const events = extracurricular.events ?? [];
				const semesters = extracurricular.year.semesters;

				const semesterTimes = [];

				// group events by time and semesters
				semesters.forEach((semester) => {
					events.forEach((event) => {
						if (
							dayjs(event.startedAt).toDate() >= dayjs(semester.startedAt).toDate() &&
							dayjs(event.startedAt).toDate() <= dayjs(semester.endedAt).toDate()
						) {
							// get day from event
							const day = dayjs(event.startedAt).day();
							// get time from event
							const startedAt = dayjs(event.startedAt).format("HH:mm");
							const endedAt = dayjs(event.endedAt).format("HH:mm");

							// concat
							const time = `${day}-${startedAt}-${endedAt}`;

							// group by day and time
							if (!semesterTimes[semester._id]) {
								semesterTimes[semester._id] = [];
							}
							if (
								!semesterTimes[semester._id]
									.map((s) => `${s.day}-${s.startedAt}-${s.endedAt}`)
									.includes(time)
							) {
								semesterTimes[semester._id].push({
									day: day,
									startedAt: startedAt,
									endedAt: endedAt,
								});
							}
						}
					});
				});

				setSemesterTimes(semesterTimes);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchExtracurricularMarks = () => {
		setLoading(true);
		requestGetReportExtracurricularMarkList({
			page: 1,
			limit: 1000,
			filter: `extracurricular:${extracurricular._id}`,
		})
			.then((response) => {
				const marks = response.data.data;

				const isSigned = marks.every((mark) => mark.signature && mark.signature.teacher._id);

				setIsSignedMarks(isSigned);

				// setExtracurricular({ ...extracurricular });
				setColumns([
					{
						title: "No",
						dataIndex: "order",
						fixed: "left",
						key: "order",
						width: 36,
					},
					{
						title: "NISN",
						dataIndex: "number",
						key: "number",
						width: 100,
						fixed: "left",
						render: (text, record) => {
							return (
								<Typography.Text
									style={{
										fontFamily: "monospace",
										fontWeight: "bold",
										whiteSpace: "nowrap",
									}}
									copyable>
									{record.number}
								</Typography.Text>
							);
						},
					},
					{
						title: "Nama",
						dataIndex: "name",
						key: "name",
						fixed: "left",
					},
					{
						title: "NH",
						dataIndex: "score",
						key: "score",
						width: 81,
						align: "center",
					},
					{
						title: "Predikat",
						dataIndex: "grade",
						key: "grade",
						width: 81,
						align: "center",
						render: (text, record) => {
							const mark = marks.find((mark) => mark.card?.studentId === record.key);

							if (!mark) {
								return <Input size="small" defaultValue={"-"} disabled />;
							}

							return (
								<Tooltip
									title={
										mark?.signature?.teacher?._id
											? "Nilai telah ditandatangani oleh penanggung jawab ekskul"
											: "Tekan enter untuk menyimpan data"
									}>
									<Input
										size="small"
										defaultValue={mark?.grade ?? "-"}
										disabled={mark?.signature?.teacher?._id}
										onKeyUp={(e) => {
											if (e.key === "Enter") {
												handleGradeChange(mark._id, e.target.value);
											}
										}}
									/>
								</Tooltip>
							);
						},
					},
					{
						title: "Catatan",
						dataIndex: "note",
						key: "note",
						render: (text, record) => {
							const mark = marks.find((mark) => mark.card?.studentId === record.key);

							if (!mark) {
								return (
									<Input size="small" defaultValue={"Rapor belum dibuat wali kelas"} disabled />
								);
							}

							return (
								<Tooltip
									title={
										mark?.signature?.teacher?._id
											? "Nilai telah ditandatangani oleh penanggung jawab ekskul"
											: "Tekan enter untuk menyimpan data"
									}>
									<Input
										size="small"
										defaultValue={mark?.note ?? "-"}
										disabled={mark?.signature?.teacher?._id}
										onKeyUp={(e) => {
											if (e.key === "Enter") {
												handleNoteChange(mark._id, e.target.value);
											}
										}}
									/>
								</Tooltip>
							);
						},
					},
					{
						title: "Aksi",
						dataIndex: "actions",
						key: "actions",
						align: "right",
						width: 24,
					},
				]);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleUnenrollStudent = (studentId) => {
		setLoading(true);
		requestUnenrollStudentsFromExtracurricular(extracurricular._id, {
			studentIds: [studentId],
		})
			.then((response) => {
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleGradeChange = (markId, grade) => {
		setLoading(true);

		requestEditReportExtracurricularMark(markId, { grade: grade })
			.then((response) => {
				message.success(response.data.message);
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleNoteChange = (markId, note) => {
		setLoading(true);

		requestEditReportExtracurricularMark(markId, { note: note })
			.then((response) => {
				message.success(response.data.message);
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		setExtracurricular(null);
		setMappedStudents([]);
		onClose();
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const handleSubmit = (semesterId, values) => {
		setLoading(true);
		if (values.remaining) {
			values.mode = "REMAINING";
		}
		values.type = "EXTRA";
		values.referenceId = extracurricular._id;
		values.semesterId = semesterId;
		values.times = values.times.map((time) => {
			return {
				day: time.day,
				startedAt: dayjs(time.time[0]).format("HH:mm"),
				endedAt: dayjs(time.time[1]).format("HH:mm"),
			};
		});

		requestScheduleExtracurricular(extracurricular._id, values)
			.then((response) => {
				message.success(response.data.message);

				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleReportButton = (event) => {
		setEvent(event);
		setReportExtracurricularVisible(true);
	};

	const handleOnReportSuccess = () => {
		fetchExtracurricular();
		setEvent(null);
		setReportExtracurricularVisible(false);
	};

	const handleSignButton = () => {
		setLoading(true);

		const payload = {
			extracurricularId: extracurricular._id,
		};

		requestSignReportExtracurricularMarks(payload)
			.then(() => {
				message.success("Nilai siswa berhasil ditanda tangani");
				fetchExtracurricular();
				fetchExtracurricularMarks();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<ExtracurricularReportFormModal
				isVisible={isReportExtracurricularVisible}
				onClose={() => {
					setReportExtracurricularVisible(false);
					setEvent(null);
				}}
				onSuccess={handleOnReportSuccess}
				extracurricular={extracurricular}
				event={event}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={extracurricular ? `${extracurricular.name}` : "Detail Ekskul"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				{extracurricular && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col lg={16} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail" size="small">
									<Descriptions size="small" layout="vertical" column={2}>
										<Descriptions.Item label="Nama">{extracurricular.name}</Descriptions.Item>
										<Descriptions.Item label="Tahun">{extracurricular.year.name}</Descriptions.Item>
										<Descriptions.Item label="Deskripsi">
											{extracurricular.description}
										</Descriptions.Item>
										<Descriptions.Item label="Penanggungjawab">
											{/* wrap */}
											<Space wrap>
												{extracurricular.teachers.length > 0 &&
													extracurricular.teachers.map((teacher, index) => {
														return <span key={index}>{teacher.name}</span>;
													})}
											</Space>
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card
									title={
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												gap: 8,
											}}>
											<span>Siswa</span>
											<Space wrap>
												<Radio.Group
													size="small"
													value={mode}
													onChange={(e) => handleModeChange(e.target.value)}
													style={{ float: "right" }}>
													<Radio.Button value="student">Siswa</Radio.Button>
													<Radio.Button value="mark">Penilaian</Radio.Button>
												</Radio.Group>
												{mode === "mark" && (
													<Popconfirm
														title="Apakah Anda yakin akan menanda tangani nilai siswa? Tindakan ini tidak dapat dibatalkan. Anda tidak dapat mengubah nilai siswa setelah ditanda tangani."
														okText="Ya"
														cancelText="Tidak"
														onConfirm={() => {
															handleSignButton();
														}}>
														<Button size="small" type="primary" disabled={isSignedMarks}>
															Tanda tangani
														</Button>
													</Popconfirm>
												)}
												{/* {selectedStudentIds.length > 0 ? (
													<Dropdown.Button
														type="default"
														size="small"
														menu={actionMenuProps}
														trigger={["click"]}>
														<CheckCircleFilled /> {selectedStudentIds.length} Dipilih
													</Dropdown.Button>
												) : (
													<Button type="default" size="small" onClick={handleEnrollStudentButton}>
														<PlusCircleFilled /> Tambah
													</Button>
												)} */}
											</Space>
										</div>
									}
									size="small">
									{mode === "mark" && (
										<Alert
											message="Tekan enter untuk menyimpan data penilaian"
											type="info"
											showIcon
											style={{ marginBottom: 16 }}
										/>
									)}
									<Table
										size="small"
										columns={columns}
										dataSource={mappedStudents}
										loading={isLoading}
										bordered
										pagination={{
											position: "bottomRight",
											pageSizeOptions: ["10", "20", "50", "100"],
											showSizeChanger: true,
											defaultPageSize: 100,
											locale: { items_per_page: "" },
										}}
										scroll={{ x: "max-content" }}
										rowSelection={{
											type: "checkbox",
											...{
												onChange: (selectedRowKeys, selectedRows) => {
													setSelectedStudentIds(selectedRowKeys.map((key) => key.toString()));
												},
											},
										}}
									/>
								</Card>
							</Space>
						</Col>
						<Col lg={8} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Sinkronisasi Jadwal" size="small">
									<Alert
										key="sync-schedule-alert"
										message="Perhatian!"
										description={
											<Typography.Text>
												Sinkronisasi jadwal akan menghapus jadwal sebelumnya dan menggantinya dengan
												jadwal baru. Namun dengan mengaktifkan opsi{" "}
												<strong>Ganti sisa jadwal saja</strong>, maka jadwal sebelum hari ini tidak
												akan terhapus.
											</Typography.Text>
										}
										type="warning"
										showIcon
										closable
										style={{
											marginBottom: 16,
										}}
									/>
									<Tabs
										type="line"
										size="small"
										items={extracurricular.year.semesters.map((semester) => {
											return {
												label: `${semester.name}`,
												key: semester._id,
												children: (
													<SemesterForm
														onSubmit={(values) => {
															handleSubmit(semester._id, values);
														}}
														times={semesterTimes[semester._id]}
													/>
												),
											};
										})}
									/>
								</Card>
								<Card
									title={
										<Row justify="space-between">
											Pertemuan{" "}
											<DatePicker
												size="small"
												picker="month"
												placeholder="Pilih bulan"
												onChange={(v) => {
													setSelectedMonth(v);
												}}
											/>
										</Row>
									}
									size="small">
									<Space style={{ width: "100%" }} direction="vertical">
										<Steps
											progressDot
											size="small"
											direction="vertical"
											current={
												extracurricular.events?.filter(
													(event) =>
														dayjs(event.startedAt).format("MM-YYYY") ===
															dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
												).length - 1
											}>
											{extracurricular.events &&
												extracurricular.events
													.filter(
														(event) =>
															dayjs(event.startedAt).format("MM-YYYY") ===
																dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
													)
													.map((event) => (
														<Steps.Step
															key={event._id}
															title={dayjs(event.startedAt).format("dddd, D MMMM YYYY")}
															description={
																<span>
																	{dayjs(event.startedAt).format("HH:mm") +
																		" - " +
																		dayjs(event.endedAt).format("HH:mm")}
																	{dayjs(event.startedAt).toDate() < dayjs().toDate() && (
																		<Button
																			type="dashed"
																			style={{ marginLeft: 4 }}
																			size="small"
																			onClick={() => {
																				handleReportButton(event);
																			}}>
																			Laporan
																		</Button>
																	)}
																</span>
															}
														/>
													))}
										</Steps>
									</Space>
								</Card>
							</Space>
						</Col>
					</Row>
				)}
			</Modal>
		</>
	);
};

export default ExtracurricularDetailModal;

export function SemesterForm({ times, onSubmit }) {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			onSubmit(values);
		});
	};

	useEffect(() => {
		if (times) {
			form.setFieldsValue({
				times: times.map((time) => {
					return {
						day: time.day.toString(),
						time: [dayjs(time.startedAt, "HH:mm"), dayjs(time.endedAt, "HH:mm")],
					};
				}),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [times]);

	return (
		<Form form={form} layout="vertical" requiredMark="optional">
			<Form.List name="times" label="Bahan" style={{ marginTop: 0, marginBottom: 12 }}>
				{(times, { add, remove }) => (
					<>
						{times.map(({ key, name, ...restField }, itemIndex) => (
							<Row gutter={8} key={key} align="baseline">
								<Col span={8}>
									<Form.Item
										required
										style={{ marginTop: 0, marginBottom: 12 }}
										{...restField}
										name={[name, "day"]}
										rules={[
											{
												required: true,
												message: "Nama harus diisi!",
											},
										]}>
										<Select placeholder="Hari">
											<Select.Option value="0">Ahad</Select.Option>
											<Select.Option value="1">Senin</Select.Option>
											<Select.Option value="2">Selasa</Select.Option>
											<Select.Option value="3">Rabu</Select.Option>
											<Select.Option value="4">Kamis</Select.Option>
											<Select.Option value="5">Jumat</Select.Option>
											<Select.Option value="6">Sabtu</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item requiredMark style={{ marginTop: 0, marginBottom: 0 }} {...restField}>
										<Input.Group compact>
											<Form.Item
												requiredMark
												name={[name, "time"]}
												style={{
													width: "calc(100% - 32px)",
													marginTop: 0,
													marginBottom: 0,
												}}
												rules={[
													{
														required: true,
														message: "Waktu harus diisi ya!",
													},
												]}>
												<TimePicker.RangePicker
													format={"HH:mm"}
													style={{
														width: "100%",
														marginTop: 0,
														marginBottom: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												/>
											</Form.Item>
											<Button
												icon={<DeleteOutlined />}
												onClick={() => remove(name)}
												danger></Button>
										</Input.Group>
									</Form.Item>
								</Col>
							</Row>
						))}
						<Form.Item style={{ marginTop: 0, marginBottom: 12 }}>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Tambah Jam
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item name="remaining" valuePropName="checked" initialValue={true}>
				<Checkbox value={"remaining"}>Ganti sisa jadwal saja (mulai hari ini)</Checkbox>
			</Form.Item>
			<Popconfirm
				placement="topRight"
				title={`Semua data yang berkaitan (termasuk laporan) dengan jadwal yang terhapus akan ikut terhapus. Apakah anda yakin?`}
				onConfirm={handleSubmit}
				okText="Yes"
				cancelText="No">
				<Button type="primary" style={{ width: "100%" }}>
					Terapkan
				</Button>
			</Popconfirm>
		</Form>
	);
}
