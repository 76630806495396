/* eslint-disable react-hooks/exhaustive-deps */
import { MoreOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, notification, Space, Table } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  requestGetExtracurricularList,
  requestGetYearList,
} from "../../../services/teacher.service";
import ExtracurricularDetailModal from "./components/ExtracurricularDetailModal";
import { useContext } from "react";
import AppContext from "../../../store/app.context.tsx";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { YearFilter } from "../../../components/YearFilter";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "Nama",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Penanggungjawab",
    dataIndex: "teachers",
    key: "teachers",
    render: (text, record) => {
      return record.teachers.map((t) => `${t.name}`).join(", ");
    },
  },
  {
    title: "Siswa",
    dataIndex: "studentCount",
    key: "studentCount",
    render: (text, record) => {
      return `${
        record.students.length > 0
          ? `${record.students.length} siswa`
          : "Belum ada siswa"
      }`;
    },
  },
  {
    title: "Aksi",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];

const TeacherExtracurricularPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(AppContext);

  useLayoutEffect(() => {
    document.title = "Ruang Admin | Ekskul";
  }, []);

  const [years, setYears] = useState([]);
  const [filteredBy, setFilteredBy] = useState("");
  const [selectedYearId, setSelectedYearId] = useState("");

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({});
  const [sorter, setSorter] = useState({
    grade: "asc",
    name: "asc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
    setPage(1);
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow startedAt and endedAt
      Object.keys(filter).forEach((key) => {
        if (key === "grade" || key === "name") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        grade: "asc",
        name: "asc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  // eslint-disable-next-line no-unused-vars
  const [extracurriculars, setExtracurriculars] = useState([]);
  const [mappedExtracurriculars, setMappedExtracurriculars] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [extracurricular, setExtracurricular] = useState(null);
  const [isExtracurricularDetailVisible, setExtracurricularDetailVisible] =
    useState(false);

  useEffect(() => {
    setSelectedYearId(context.year._id);
    fetchYearList();
  }, []);

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    if (filter.year) {
      fetchExtracurricularList();
    }
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, []);

  useEffect(() => {
    if (selectedYearId !== "") {
      const year = years.find((year) => year._id === selectedYearId);
      if (year) {
        setFilteredBy(year.name);
      } else {
        setFilteredBy(context.year.name);
      }
    } else {
      setFilteredBy("");
    }
    setFilter({
      ...filter,
      year: selectedYearId,
    });
  }, [selectedYearId]);

  const fetchYearList = () => {
    requestGetYearList({
      page: 1,
      limit: 100,
    })
      .then((response) => {
        setYears(response.data.data);
      })
      .catch((error) => {});
  };

  const fetchExtracurricularList = () => {
    setLoading(true);
    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetExtracurricularList({
      page: page,
      limit: limit,
      order: tempSorter ?? "grade:asc,name:asc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTotal(response.data.pagination.total);
        setExtracurriculars(response.data.data);
        updateMappedExtracurricular(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMappedExtracurricular = (data) => {
    setMappedExtracurriculars(
      data.map((d) => ({
        key: d._id,
        name: d.name,
        description: d.description,
        teachers: d.teachers,
        students: d.students,
        actions: (
          <Space>
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: (e) => {
                  if (e.key === "show") {
                    handleShowButton(d);
                  }
                },
                items: otherButtons,
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        ),
      })),
    );
  };

  const handleShowButton = (extracurricular) => {
    setExtracurricular(extracurricular);
    setExtracurricularDetailVisible(true);
  };

  const handleOnCloseExtracurricularDetail = () => {
    setExtracurricular(null);
    setExtracurricularDetailVisible(false);
  };

  const otherButtons = [
    {
      key: "show",
      label: "Lihat",
      icon: <HiOutlineEye />,
    },
    {
      key: "edit",
      label: "Edit",
      icon: <HiOutlinePencil />,
    },
    {
      type: "divider",
    },
    {
      key: "delete",
      label: "Hapus",
      icon: <HiOutlineTrash />,
      danger: true,
    },
  ];

  return (
    <>
      <ExtracurricularDetailModal
        isLoading={isLoading}
        isVisible={isExtracurricularDetailVisible}
        onLoading={(v) => setLoading(v)}
        id={extracurricular ? extracurricular._id : null}
        onClose={handleOnCloseExtracurricularDetail}
      />
      <Card
        bordered={false}
        title="Daftar Ekskul"
        extra={
          <Space>
            <SearchInput
              placeholder="Nama ekskul"
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
            <YearFilter
              activeYear={context.year}
              years={years}
              filteredBy={{
                key: selectedYearId,
                value: filteredBy,
              }}
              filterBy={(key) => {
                setSelectedYearId(key);
              }}
            />
          </Space>
        }
      >
        <div>
          <Table
            bordered
            columns={columns}
            loading={isLoading}
            dataSource={mappedExtracurriculars}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onChange={handlePaginate}
            className="ant-border-extracurricular"
          />
        </div>
      </Card>
    </>
  );
};

export default TeacherExtracurricularPage;
