/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
	requestAddQuestion,
	requestAddQuestionOption,
	requestArchiveExam,
	requestDeleteExam,
	requestDeleteExamSubmission,
	requestDeleteExamToken,
	requestDeleteQuestion,
	requestDeleteQuestionOption,
	requestEditExam,
	requestEditQuestion,
	requestEditQuestionOption,
	requestGenerateExamToken,
	requestGetExamSubmissionList,
	requestGetReportAssessmentList,
	requestLinkExam,
	requestMarkExamSubmission,
	requestPublishExam,
	requestScheduleExam,
	requestShowExam,
	requestShowExamSubmission,
	requestSyncExamSubmission,
	requestUnlinkExam,
} from "../../../../services/teacher.service";
import {
	Alert,
	Button,
	Card,
	Col,
	Collapse,
	DatePicker,
	Descriptions,
	Dropdown,
	Empty,
	Form,
	Image,
	Input,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Row,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	Tabs,
	Tag,
	TimePicker,
	Typography,
	Upload,
	Watermark,
} from "antd";
import {
	CheckCircleOutlined,
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	ExclamationCircleOutlined,
	FileTextOutlined,
	Loading3QuartersOutlined,
	MinusCircleOutlined,
	MoreOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
	ReloadOutlined,
	SyncOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { HiOutlineEye, HiOutlineTrash } from "react-icons/hi2";

// duration in minute
const durationToString = (duration, sec = false) => {
	if (sec) {
		const hours = Math.floor(duration / 3600);
		const minutes = Math.floor((duration % 3600) / 60);
		const seconds = Math.floor(duration % 60);

		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
	} else {
		const hours = Math.floor(duration / 60);
		const minutes = Math.floor(duration % 60);

		return `${pad(hours)}:${pad(minutes)}`;
	}
};

function pad(n) {
	return n < 10 ? `0${n}` : n;
}

function ExamDetailModal({ id, isVisible, onClose }) {
	const [isLoading, setLoading] = React.useState(true);
	const [exam, setExam] = React.useState(null);
	const [questions, setQuestions] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);
	const [assessments, setAssessments] = React.useState([]);

	const [isReportExamVisible, setReportExamVisible] = React.useState(false);
	const [event, setEvent] = React.useState(null);

	const [isAddQuestionVisible, setAddQuestionVisible] = React.useState(false);
	const [isScheduleFormVisible, setScheduleFormVisible] = React.useState(false);

	const [newQuestionOptions, setNewQuestionOptions] = React.useState([]);

	const [tab, setTab] = React.useState("1");

	const [submissions, setSubmissions] = React.useState([]);

	const [isSubmissionDetailVisible, setSubmissionDetailVisible] = React.useState(false);
	const [submission, setSubmission] = React.useState(null);

	const [isGeneratingToken, setGeneratingToken] = React.useState(false);

	React.useEffect(() => {
		if (isVisible && id) {
			setTab("1");
			fetchExamDetail();
			fetchSubmissions();
		} else {
			setExam(null);
			setSubmissions([]);
			setMappedStudents([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, id]);

	React.useEffect(() => {
		if (exam && exam.blueprinted === false && exam.curriculum) {
			fetchAssessments();
		} else {
			setAssessments([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exam]);

	const fetchAssessments = () => {
		setLoading(true);
		requestGetReportAssessmentList({
			limit: 100,
			filter: `curriculum:${exam.curriculum._id}`,
		})
			.then((response) => {
				const assessments = response.data.data;
				setAssessments(assessments);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchExamDetail = () => {
		setLoading(true);
		requestShowExam(id)
			.then((response) => {
				const exam = response.data.data;
				setExam(exam);
				setQuestions(exam.questions);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchSubmissions = () => {
		setLoading(true);
		requestGetExamSubmissionList(id, {
			page: 1,
			limit: 1000,
		})
			.then((response) => {
				const submissions = response.data.data;
				setSubmissions(submissions);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleReportButton = (event) => {
		setEvent(event);
		setReportExamVisible(true);
	};

	const handleSubmit = (values) => {
		setLoading(true);
		values.type = "EXAM";
		values.referenceId = exam._id;

		// requestScheduleExam(exam._id, values)
		// 	.then((response) => {
		// 		message.success(response.data.message);

		// 		fetchExamDetail();
		// 		setSelectedStudent(null);
		// 		setRequireRefresh(true);
		// 	})
		// 	.catch((error) => {
		// 		if (error.response && error.response.data) {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.response.data.message,
		// 			});
		// 		} else {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.message,
		// 			});
		// 		}
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	const handleClose = () => {
		setNewQuestionOptions([]);
		onClose(true);
	};

	const handleOnReportSuccess = (exam, event) => {
		fetchExamDetail();
		setEvent(null);
		setReportExamVisible(false);
	};

	const handleDeleteExam = () => {
		setLoading(true);
		requestDeleteExam(exam._id)
			.then((response) => {
				message.success(response.data.message);

				onClose(true);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDeleteQuestion = (questionId) => {
		setLoading(true);
		requestDeleteQuestion(exam._id, questionId)
			.then((response) => {
				message.success(response.data.message);

				setQuestions(exam.questions.filter((q) => q._id !== questionId));
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const isValidQuestion = (question) => {
		if (question.type === "MULTIPLE_CHOICE") {
			return (
				question.body.length > 0 &&
				question.options.length > 1 &&
				question.answers.length === 1 &&
				question.point > 0
			);
		} else if (question.type === "MULTIPLE_ANSWER") {
			return (
				question.body.length > 0 &&
				question.options.length > 0 &&
				question.answers.length > 1 &&
				question.point > 0
			);
		} else if (question.type === "SHORT_ANSWER") {
			return question.body.length > 0 && question.point > 0;
		} else if (question.type === "TRUE_FALSE") {
			return (
				question.body.length > 0 &&
				question.options.length === 2 &&
				question.answers.length === 1 &&
				question.point > 0
			);
		} else if (question.type === "FILL_BLANK") {
			return question.body.length > 0 && question.point > 0;
		} else if (question.type === "ESSAY") {
			return question.body.length > 0 && question.point > 0;
		} else if (question.type === "FILE_UPLOAD") {
			return question.body.length > 0 && question.point > 0;
		} else if (question.type === "ARRANGEMENT") {
			return question.body.length > 0 && question.point > 0;
		}
	};

	return (
		<>
			{/* <LessonExamReportFormModal
				isVisible={isReportExamVisible}
				onClose={() => {
					setEvent(null);
					setReportExamVisible(false);
				}}
				onSuccess={handleOnReportSuccess}
				exam={exam}
				event={event}
			/> */}
			<ScheduleFormModal
				isVisible={isScheduleFormVisible}
				exam={exam}
				onClose={() => {
					setScheduleFormVisible(false);
				}}
				onSuccess={() => {
					setScheduleFormVisible(false);
					fetchExamDetail();
				}}
			/>
			<ExamQuestionFormModal
				exam={exam}
				isVisible={isAddQuestionVisible}
				onClose={() => setAddQuestionVisible(false)}
				onAdded={(question) => {
					setQuestions([...questions, question]);
					setNewQuestionOptions([]);
					setAddQuestionVisible(false);
				}}
			/>
			<Watermark
				content={exam && exam.blueprinted ? "TEMPLATE" : null}
				font={{
					fontSize: 36,
					color: "#E7E7E766",
				}}>
				<Modal
					maskClosable={false}
					width={"calc(100% - 64px)"}
					style={{
						top: 32,
					}}
					title={
						exam ? (
							<>
								{exam.name}{" "}
								{exam.blueprinted && (
									<Tag
										color="default"
										style={{
											marginLeft: 8,
											fontStyle: "normal",
											fontWeight: "bold",
											fontSize: 10,
										}}>
										Template
									</Tag>
								)}
								{exam.blueprint && (
									<Typography.Text
										type="secondary"
										style={{
											fontStyle: "italic",
											fontSize: 10,
											marginLeft: 8,
										}}>
										dari template{" "}
										<Tag
											style={{
												marginLeft: 8,
												fontStyle: "normal",
												fontWeight: "bold",
												fontSize: 10,
											}}>
											{exam.blueprint.name}
										</Tag>
									</Typography.Text>
								)}
							</>
						) : (
							"Detail Ujian"
						)
					}
					open={isVisible}
					cancelText="Batal"
					onCancel={handleClose}
					footer={[
						<Button key={1} onClick={handleClose}>
							Tutup
						</Button>,
					]}>
					<Spin spinning={isLoading}>
						{exam && (
							<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
								<ExamSubmissionDetailModal
									isVisible={isSubmissionDetailVisible}
									examId={exam._id}
									submissionId={submission?._id}
									onClose={() => {
										setSubmissionDetailVisible(false);
										setSubmission(null);
									}}
								/>
								<Col lg={8} md={24}>
									<Space
										direction="vertical"
										size="middle"
										className="layout-popup"
										style={{ width: "100%" }}>
										<Card title="Detail" size="small">
											<Descriptions size="small" layout="vertical" column={2}>
												<Descriptions.Item label="Nama">
													{/* <Input
														disabled={exam?.status === "PUBLISHED"}
														size="small"
														style={{
															minHeight: 0,
														}}
														value={exam.name}
														onChange={(e) => {
															setExam({
																...exam,
																name: e.target.value,
															});
														}}
														onBlur={() => {
															requestEditExam(exam._id, {
																name: exam.name,
															})
																.then((response) => {
																	message.success(response.data.message);
																})
																.catch((error) => {
																	if (error.response && error.response.data) {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.response.data.message,
																		});
																	} else {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.message,
																		});
																	}
																});
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																requestEditExam(exam._id, {
																	name: exam.name,
																})
																	.then((response) => {
																		message.success(response.data.message);
																	})
																	.catch((error) => {
																		if (error.response && error.response.data) {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.response.data.message,
																			});
																		} else {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.message,
																			});
																		}
																	});
															}
														}}
													/> */}
													<Typography.Text
														editable={
															exam?.status !== "PUBLISHED"
																? {
																		onChange: (text) => {
																			requestEditExam(exam._id, {
																				name: text,
																			})
																				.then((response) => {
																					message.success(response.data.message);
																					setExam({
																						...exam,
																						name: text,
																					});
																				})
																				.catch((error) => {
																					if (error.response && error.response.data) {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.response.data.message,
																						});
																					} else {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.message,
																						});
																					}
																				});
																		},
																  }
																: false
														}
														strong>
														{exam.name}
													</Typography.Text>
												</Descriptions.Item>
												<Descriptions.Item label="Deskripsi">
													{/* <Input
														disabled={exam?.status === "PUBLISHED"}
														size="small"
														style={{
															minHeight: 0,
														}}
														value={exam.description}
														onChange={(e) => {
															setExam({
																...exam,
																description: e.target.value,
															});
														}}
														onBlur={() => {
															requestEditExam(exam._id, {
																description: exam.description,
															})
																.then((response) => {
																	message.success(response.data.message);
																})
																.catch((error) => {
																	if (error.response && error.response.data) {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.response.data.message,
																		});
																	} else {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.message,
																		});
																	}
																});
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																requestEditExam(exam._id, {
																	description: exam.description,
																})
																	.then((response) => {
																		message.success(response.data.message);
																	})
																	.catch((error) => {
																		if (error.response && error.response.data) {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.response.data.message,
																			});
																		} else {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.message,
																			});
																		}
																	});
															}
														}}
													/> */}
													<Typography.Text
														editable={
															exam?.status !== "PUBLISHED"
																? {
																		onChange: (text) => {
																			requestEditExam(exam._id, {
																				description: text,
																			})
																				.then((response) => {
																					message.success(response.data.message);
																					setExam({
																						...exam,
																						description: text,
																					});
																				})
																				.catch((error) => {
																					if (error.response && error.response.data) {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.response.data.message,
																						});
																					} else {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.message,
																						});
																					}
																				});
																		},
																  }
																: false
														}>
														{exam.description}
													</Typography.Text>
												</Descriptions.Item>
												<Descriptions.Item label="Matpel">{exam.subject?.name}</Descriptions.Item>
												<Descriptions.Item label="Tingkat">
													{exam.grade}{" "}
													{exam.grade < 7 ? "SD/MI" : exam.grade < 9 ? "SMP/MTs" : "SMA/SMK/MA"}
												</Descriptions.Item>
												{exam.classroom && (
													<Descriptions.Item label="Kelas">{exam.classroom.name}</Descriptions.Item>
												)}
												{exam.year && (
													<Descriptions.Item label="Tahun Ajaran">
														{exam.year?.name}
													</Descriptions.Item>
												)}
												<Descriptions.Item label="Σ Bobot Nilai">
													{questions
														.map((question) => question.point ?? 0)
														.reduce((a, b) => a + b, 0)}
												</Descriptions.Item>
												{exam.curriculum && (
													<Descriptions.Item label="Skala Nilai">
														{exam.curriculum?.scale}
													</Descriptions.Item>
												)}
												<Descriptions.Item label="Status">
													{exam?.status === "DRAFT"
														? "Draf"
														: exam?.status === "PUBLISHED"
														? "Terbit"
														: "Arsip"}
													{exam?.status !== "PUBLISHED" && (
														<Button
															style={{ marginLeft: 8 }}
															size="small"
															type="primary"
															onClick={() => {
																if (exam?.blueprinted) {
																	requestPublishExam(exam._id)
																		.then((response) => {
																			message.success(response.data.message);

																			setExam({
																				...exam,
																				status: "PUBLISHED",
																			});
																		})
																		.catch((error) => {
																			if (error.response && error.response.data) {
																				notification["error"]({
																					message: "Kesalahan!",
																					description: error.response.data.message,
																				});
																			} else {
																				notification["error"]({
																					message: "Kesalahan!",
																					description: error.message,
																				});
																			}
																		});
																} else {
																	setScheduleFormVisible(true);
																}
															}}>
															Terbitkan
														</Button>
													)}
													{exam?.status === "PUBLISHED" && (
														<Button
															style={{ marginLeft: 8 }}
															size="small"
															type="default"
															danger
															onClick={() => {
																requestArchiveExam(exam._id)
																	.then((response) => {
																		message.success(response.data.message);

																		setExam({
																			...exam,
																			status: "ARCHIVED",
																		});
																	})
																	.catch((error) => {
																		if (error.response && error.response.data) {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.response.data.message,
																			});
																		} else {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.message,
																			});
																		}
																	});
															}}>
															Arsip
														</Button>
													)}
												</Descriptions.Item>
												<Descriptions.Item label="Durasi (dalam menit)">
													<InputNumber
														disabled={exam?.status === "PUBLISHED"}
														size="small"
														style={{
															minHeight: 0,
														}}
														value={exam.duration}
														onChange={(e) => {
															setExam({
																...exam,
																duration: e,
															});
														}}
														onBlur={() => {
															requestEditExam(exam._id, {
																duration: exam.duration,
															})
																.then((response) => {
																	message.success(response.data.message);
																})
																.catch((error) => {
																	if (error.response && error.response.data) {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.response.data.message,
																		});
																	} else {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.message,
																		});
																	}
																});
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																requestEditExam(exam._id, {
																	description: exam.description,
																})
																	.then((response) => {
																		message.success(response.data.message);
																	})
																	.catch((error) => {
																		if (error.response && error.response.data) {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.response.data.message,
																			});
																		} else {
																			notification.error({
																				message: "Kesalahan!",
																				description: error.message,
																			});
																		}
																	});
															}
														}}
													/>
													<Typography.Text
														type="secondary"
														style={{
															marginLeft: 8,
														}}>
														{exam.duration && durationToString(exam.duration)}
													</Typography.Text>
												</Descriptions.Item>
												<Descriptions.Item label="Soal acak">
													<Switch
														disabled={exam?.status === "PUBLISHED"}
														checked={exam.shuffled}
														onChange={(e) => {
															setExam({
																...exam,
																shuffled: e,
															});

															requestEditExam(exam._id, {
																shuffled: e,
															})
																.then((response) => {
																	message.success(response.data.message);
																})
																.catch((error) => {
																	if (error.response && error.response.data) {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.response.data.message,
																		});
																	} else {
																		notification.error({
																			message: "Kesalahan!",
																			description: error.message,
																		});
																	}
																});
														}}
														checkedChildren={<CheckOutlined />}
														unCheckedChildren={<CloseOutlined />}
														loading={isLoading}
													/>
												</Descriptions.Item>
												{exam.blueprinted === false && exam.curriculum && (
													<Descriptions.Item label="Penilaian Rapor">
														<Select
															disabled={exam?.status === "PUBLISHED"}
															size="small"
															placeholder="Pilih jenis penilaian"
															allowClear
															showSearch
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.children.toLowerCase().includes(input.toLowerCase())
															}
															value={exam.assessmentId}
															onChange={(value) => {
																setExam({ ...exam, assessmentId: value });

																if (value) {
																	requestLinkExam(exam._id, {
																		assessmentId: value,
																	})
																		.then((response) => {
																			message.success(response.data.message);
																		})
																		.catch((error) => {
																			if (error.response && error.response.data) {
																				notification.error({
																					message: "Kesalahan!",
																					description: error.response.data.message,
																				});
																			} else {
																				notification.error({
																					message: "Kesalahan!",
																					description: error.message,
																				});
																			}
																		});
																} else {
																	requestUnlinkExam(exam._id)
																		.then((response) => {
																			message.success(response.data.message);
																		})
																		.catch((error) => {
																			if (error.response && error.response.data) {
																				notification.error({
																					message: "Kesalahan!",
																					description: error.response.data.message,
																				});
																			} else {
																				notification.error({
																					message: "Kesalahan!",
																					description: error.message,
																				});
																			}
																		});
																}
															}}
															options={[
																{
																	label: "Tidak ada",
																	value: "",
																},
																...assessments.map((item) => ({
																	label: item.name,
																	value: item._id,
																})),
															]}
														/>
													</Descriptions.Item>
												)}
												{exam.blueprinted === false && (
													<Descriptions.Item label="Token">
														{exam.token ? (
															<div style={{ display: "flex", gap: 4, alignItems: "center" }}>
																<Typography.Text
																	style={{
																		fontFamily: "monospace",
																	}}
																	copyable
																	strong>
																	{exam.token}
																</Typography.Text>
																<Button
																	loading={isGeneratingToken}
																	type="primary"
																	size="small"
																	shape="circle"
																	onClick={() => {
																		setGeneratingToken(true);
																		requestGenerateExamToken(exam._id)
																			.then((response) => {
																				message.success(response.data.message);
																				setExam({
																					...exam,
																					token: response.data.data.token,
																				});
																			})
																			.catch((error) => {
																				if (error.response && error.response.data) {
																					notification["error"]({
																						message: "Kesalahan!",
																						description: error.response.data.message,
																					});
																				} else {
																					notification["error"]({
																						message: "Kesalahan!",
																						description: error.message,
																					});
																				}
																			})
																			.finally(() => {
																				setGeneratingToken(false);
																			});
																	}}>
																	<SyncOutlined />
																</Button>
																<Button
																	loading={isGeneratingToken}
																	type="primary"
																	size="small"
																	shape="circle"
																	danger
																	onClick={() => {
																		setGeneratingToken(true);
																		requestDeleteExamToken(exam._id)
																			.then((response) => {
																				message.success(response.data.message);

																				setExam({
																					...exam,
																					token: null,
																				});
																			})
																			.catch((error) => {
																				if (error.response && error.response.data) {
																					notification["error"]({
																						message: "Kesalahan!",
																						description: error.response.data.message,
																					});
																				} else {
																					notification["error"]({
																						message: "Kesalahan!",
																						description: error.message,
																					});
																				}
																			})
																			.finally(() => {
																				setGeneratingToken(false);
																			});
																	}}>
																	<DeleteOutlined />
																</Button>
															</div>
														) : (
															<Button
																loading={isGeneratingToken}
																type="primary"
																size="small"
																onClick={() => {
																	setGeneratingToken(true);
																	requestGenerateExamToken(exam._id)
																		.then((response) => {
																			message.success(response.data.message);
																			setExam({
																				...exam,
																				token: response.data.data.token,
																			});
																		})
																		.catch((error) => {
																			if (error.response && error.response.data) {
																				notification["error"]({
																					message: "Kesalahan!",
																					description: error.response.data.message,
																				});
																			} else {
																				notification["error"]({
																					message: "Kesalahan!",
																					description: error.message,
																				});
																			}
																		})
																		.finally(() => {
																			setGeneratingToken(false);
																		});
																}}
																icon={<SyncOutlined />}>
																Generate
															</Button>
														)}
													</Descriptions.Item>
												)}
											</Descriptions>
										</Card>
										{exam.startedAt && exam.endedAt && (
											<Card
												title="Jadwal"
												size="small"
												extra={
													!exam.blueprinted && (
														<Button
															type="primary"
															size="small"
															style={{ width: "100%" }}
															onClick={() => {
																setScheduleFormVisible(true);
															}}>
															{exam.startedAt ? "Jadwalkan Ulang" : "Jadwalkan Penerbitan"}
														</Button>
													)
												}>
												<Descriptions
													style={{
														width: "100%",
													}}
													size="small"
													layout="vertical"
													column={2}>
													<Descriptions.Item label="Tanggal">
														{exam.startedAt && <>{dayjs(exam.startedAt).format("DD MMMM YYYY")}</>}
													</Descriptions.Item>
													<Descriptions.Item label="Waktu">
														{exam.startedAt && <>{dayjs(exam.startedAt).format("HH:mm")}</>}
														{exam.endedAt && <> - {dayjs(exam.endedAt).format("HH:mm")}</>}
													</Descriptions.Item>
												</Descriptions>
											</Card>
										)}
										{!exam.blueprinted && !(exam.startedAt && exam.endedAt) && (
											<Button
												type="primary"
												style={{ width: "100%" }}
												onClick={() => {
													setScheduleFormVisible(true);
												}}>
												{exam.startedAt ? "Jadwalkan Ulang" : "Jadwalkan Penerbitan"}
											</Button>
										)}
										{exam?.status !== "PUBLISHED" && (
											<Button
												type="default"
												danger
												style={{ width: "100%" }}
												onClick={() => {
													Modal.confirm({
														title: "Sebentar",
														icon: <ExclamationCircleFilled />,
														content:
															"Anda yakin akan menghapus ujian ini? Tidak dapat dibatalkan. Semua data terkait akan terputus.",
														okText: "Ya",
														okType: "danger",
														cancelText: "Tidak",
														onOk() {
															handleDeleteExam();
														},
														onCancel() {},
													});
												}}>
												Hapus Ujian
											</Button>
										)}
									</Space>
								</Col>
								<Col lg={16} md={24}>
									<Space
										direction="vertical"
										size="middle"
										className="layout-popup"
										style={{ width: "100%", paddingTop: 8 }}>
										<Tabs
											size="small"
											type="card"
											activeKey={tab}
											tabBarExtraContent={
												tab === "1" ? (
													<Button
														disabled={
															exam?.status !== "PUBLISHED" ||
															questions
																.map((question) => question.point ?? 0)
																.reduce((a, b) => a + b, 0) >= exam.curriculum?.scale
														}
														type="primary"
														icon={<PlusOutlined />}
														size="small"
														onClick={() => {
															setAddQuestionVisible(true);
														}}>
														Tambah
													</Button>
												) : (
													<Button
														type="primary"
														size="small"
														icon={<ReloadOutlined />}
														onClick={() => {
															fetchSubmissions();
														}}>
														Segarkan
													</Button>
												)
											}
											items={[
												{
													key: "1",
													icon: <QuestionCircleOutlined />,
													label: "Pertanyaan",
													children: (
														<Space
															direction="vertical"
															size="middle"
															className="layout-popup"
															style={{ width: "100%" }}>
															{questions.length > 0 ? (
																<Collapse
																	size="small"
																	items={questions.map((question, index) => {
																		return {
																			key: question._id,
																			label: (
																				<>
																					<Typography.Text>{index + 1}. </Typography.Text>
																					<Typography.Text>{question.body}</Typography.Text>
																					<Tag
																						style={{
																							marginLeft: 8,
																						}}
																						color={isValidQuestion(question) ? "success" : "error"}>
																						{isValidQuestion(question) ? "Valid" : "Belum Valid"}
																					</Tag>
																				</>
																			),
																			children: (
																				<ExamQuestion
																					exam={exam}
																					question={question}
																					questions={questions}
																					setQuestions={setQuestions}
																					newQuestionOptions={newQuestionOptions}
																					setNewQuestionOptions={setNewQuestionOptions}
																					isLoading={isLoading}
																				/>
																			),
																			extra: exam?.status !== "PUBLISHED" && (
																				<DeleteOutlined
																					onClick={(event) => {
																						event.stopPropagation();

																						Modal.confirm({
																							title: "Sebentar",
																							icon: <ExclamationCircleFilled />,
																							content:
																								"Anda yakin akan menghapus pertanyaan ini? data akan terhapus secara permanen.",
																							okText: "Ya",
																							okType: "danger",
																							cancelText: "Tidak",
																							onOk() {
																								handleDeleteQuestion(question._id);
																							},
																							onCancel() {},
																						});
																					}}
																				/>
																			),
																		};
																	})}
																/>
															) : (
																<Empty description="Belum ada pertanyaan" />
															)}
														</Space>
													),
												},
												{
													key: "2",
													icon: <FileTextOutlined />,
													label: "Submisi",
													children: (
														<div style={{ width: "100%" }}>
															<Table
																size="small"
																columns={[
																	{
																		title: "NISN",
																		dataIndex: "number",
																		key: "number",
																		width: 48,
																		fixed: "left",
																	},
																	{
																		title: "Nama",
																		dataIndex: "name",
																		key: "name",
																		fixed: "left",
																	},
																	{
																		title: "Status",
																		dataIndex: "status",
																		key: "status",
																		align: "center",
																		width: 48,
																		render: (text, record) => {
																			return (
																				<Tag
																					style={{
																						fontWeight: "bold",
																						marginInlineEnd: 0,
																					}}
																					color={
																						text === "STARTED"
																							? "orange"
																							: text === "SUBMITTED" || text === "MARKED"
																							? "green"
																							: "red"
																					}>
																					{text === "STARTED"
																						? "Berlangsung"
																						: text === "SUBMITTED"
																						? "Selesai"
																						: text === "MARKED"
																						? "Dinilai"
																						: "Dibatalkan"}
																				</Tag>
																			);
																		},
																	},
																	{
																		title: "Mulai",
																		dataIndex: "startedAt",
																		key: "startedAt",
																		width: 48,
																		align: "center",
																		render: (text, record) => {
																			const startedAt = record.startedAt;

																			return (
																				<Typography.Text
																					style={{
																						fontFamily: "monospace",
																						fontWeight: "bold",
																						whiteSpace: "nowrap",
																					}}>
																					{dayjs(startedAt).format("HH:mm:ss")}
																				</Typography.Text>
																			);
																		},
																	},
																	{
																		title: "Selesai",
																		dataIndex: "submittedAt",
																		key: "submittedAt",
																		width: 48,
																		align: "center",
																		render: (text, record) => {
																			const submittedAt = record.submittedAt;

																			if (submittedAt) {
																				return (
																					<Typography.Text
																						style={{
																							fontFamily: "monospace",
																							fontWeight: "bold",
																							whiteSpace: "nowrap",
																						}}>
																						{dayjs(submittedAt).format("HH:mm:ss")}
																					</Typography.Text>
																				);
																			} else {
																				return (
																					<Typography.Text
																						style={{
																							fontFamily: "monospace",
																							fontWeight: "bold",
																							whiteSpace: "nowrap",
																						}}>
																						XX:XX:XX
																					</Typography.Text>
																				);
																			}
																		},
																	},
																	{
																		title: "Durasi",
																		dataIndex: "duration",
																		key: "duration",
																		width: 48,
																		align: "center",
																		render: (text, record) => {
																			if (record.status === "STARTED") {
																				return (
																					<Typography.Text
																						style={{
																							fontFamily: "monospace",
																							fontWeight: "bold",
																							whiteSpace: "nowrap",
																						}}>
																						XX:XX:XX
																					</Typography.Text>
																				);
																			}

																			const startedAt = record.startedAt;
																			const submittedAt = record.submittedAt;
																			const duration = dayjs(submittedAt).diff(
																				dayjs(startedAt),
																				"second"
																			);

																			return (
																				<Typography.Text
																					style={{
																						fontFamily: "monospace",
																						fontWeight: "bold",
																						whiteSpace: "nowrap",
																					}}>
																					{durationToString(duration, true)}
																				</Typography.Text>
																			);
																		},
																	},
																	{
																		title: "Aksi",
																		key: "actions",
																		align: "right",
																		width: 24,
																		render: (text, record) => {
																			return (
																				<Space>
																					<Dropdown
																						trigger={["click"]}
																						menu={{
																							onClick: (e) => {
																								if (e.key === "show") {
																									setSubmissionDetailVisible(true);
																									setSubmission(record);
																								} else if (e.key === "delete") {
																									Modal.confirm({
																										title: "Apakah Anda Yakin?",
																										icon: <ExclamationCircleOutlined />,
																										content:
																											"Setelah menghapus, siswa dapat mengerjakan ulang bila masih ada waktu.",
																										okText: "Ya",
																										okType: "danger",
																										cancelText: "Tidak",
																										onOk() {
																											setLoading(true);
																											requestDeleteExamSubmission(
																												exam._id,
																												record.key
																											)
																												.then((response) => {
																													message.success(response.data.message);
																													setSubmissions([
																														...submissions.filter(
																															(s) => s._id !== record.key
																														),
																													]);
																												})
																												.catch((error) => {
																													if (
																														error.response &&
																														error.response.data
																													) {
																														notification.error({
																															message: "Kesalahan!",
																															description:
																																error.response.data.message,
																														});
																													} else {
																														notification.error({
																															message: "Kesalahan!",
																															description: error.message,
																														});
																													}
																												})
																												.finally(() => {
																													setLoading(false);
																												});
																										},
																										onCancel() {},
																									});
																								}
																							},
																							items: [
																								{
																									key: "show",
																									label: "Lihat",
																									icon: <HiOutlineEye />,
																								},
																								{
																									type: "divider",
																								},
																								{
																									key: "delete",
																									label: "Hapus",
																									icon: <HiOutlineTrash />,
																									danger: true,
																								},
																							],
																						}}
																						placement="bottomRight"
																						arrow>
																						<Button
																							type="default"
																							shape="circle"
																							size="small"
																							icon={<MoreOutlined />}
																						/>
																					</Dropdown>
																				</Space>
																			);
																		},
																	},
																]}
																dataSource={[
																	...submissions.map((submission) => {
																		const startedEvent = submission?.events.find(
																			(event) => event.status === "STARTED"
																		);
																		const submittedEvent = submission?.events.find(
																			(event) => event.status === "SUBMITTED"
																		);
																		return {
																			key: submission?._id,
																			number: submission?.student.number,
																			name: submission?.student.name,
																			...submission,
																			startedAt: startedEvent?.date,
																			submittedAt: submittedEvent?.date,
																		};
																	}),
																]}
																loading={isLoading}
																bordered
																pagination={false}
															/>
														</div>
													),
												},
											]}
											onChange={(key) => {
												setTab(key);
											}}
										/>
									</Space>
								</Col>
							</Row>
						)}
					</Spin>
				</Modal>
			</Watermark>
		</>
	);
}

function ExamQuestion({
	exam,
	question,
	questions,
	setQuestions,
	newQuestionOptions,
	setNewQuestionOptions,
	isLoading,
}) {
	const [attachmentList, setAttachmentList] = React.useState([]);
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const [previewImage, setPreviewImage] = React.useState("");

	const handleRemoveAttachment = (attachment) => {
		requestEditQuestion(exam._id, question._id, {
			deletedAttachmentIds: [attachment.uid],
		})
			.then(() => {
				message.success("Berhasil menghapus lampiran gambar");
				setAttachmentList(attachmentList.filter((item) => item.uid !== attachment.uid));
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	React.useEffect(() => {
		setAttachmentList(
			question.attachments?.map((attachment) => {
				return {
					uid: attachment._id,
					name: attachment.name,
					status: "done",
					url: attachment.url.replace(
						"storage/app/media/attachments",
						process.env.REACT_APP_API_URL
					),
				};
			})
		);
	}, [question]);

	return (
		<Table
			key={question._id}
			size="small"
			columns={[
				{
					title: "Key",
					dataIndex: "key",
					key: "key",
					width: 160,
				},
				{
					title: "Value",
					dataIndex: "value",
					key: "value",
				},
			]}
			showHeader={false}
			dataSource={[
				{
					key: "Tipe",
					value: (
						<Select
							size="small"
							disabled
							placeholder="Pilih tipe pertanyaan"
							allowClear
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							value={question.type}>
							<Select.Option value="MULTIPLE_CHOICE">Pilihan Ganda</Select.Option>
							<Select.Option value="MULTIPLE_ANSWER">Jawaban Ganda</Select.Option>
							<Select.Option value="SHORT_ANSWER">Jawaban Singkat</Select.Option>
							<Select.Option value="TRUE_FALSE">Benar/Salah</Select.Option>
							<Select.Option value="FILL_BLANK">Isian</Select.Option>
							<Select.Option value="ESSAY">Esai</Select.Option>
							<Select.Option value="FILE_UPLOAD">Unggah</Select.Option>
							<Select.Option value="ARRANGEMENT">Penyusunan</Select.Option>
						</Select>
					),
				},
				{
					key: "Pertanyaan",
					value: (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 8,
							}}>
							<Input.TextArea
								disabled={exam?.status === "PUBLISHED"}
								rows={1}
								autoSize
								style={{
									minHeight: 0,
								}}
								size="small"
								defaultValue={question.body}
								onBlur={(e) => {
									const payload = {
										body: e.target.value,
									};
									requestEditQuestion(exam._id, question._id, payload)
										.then((response) => {
											message.success(response.data.message);
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification.error({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification.error({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										});
								}}
							/>
							<Upload
								multiple
								accept="image/*"
								maxCount={1}
								listType="picture-card"
								fileList={attachmentList}
								onPreview={handlePreview}
								onRemove={handleRemoveAttachment}
								beforeUpload={(file) => {
									// check only image allowed
									if (!file.type.includes("image")) {
										notification.error({
											message: "Tidak dapat mengunggah file",
											description: "Hanya file gambar yang diperbolehkan",
										});
										return false;
									}

									const formData = new FormData();
									formData.append("attachments", file);

									requestEditQuestion(exam._id, question._id, formData)
										.then((response) => {
											message.success(response.data.message);

											const exam = response.data.data;
											const que = exam.questions.find((item) => item._id === question._id);
											const attachmentIds = que.attachmentIds;
											const newAttachmentId = attachmentIds[attachmentIds.length - 1];
											setAttachmentList([
												...attachmentList,
												{
													uid: newAttachmentId,
													...file,
												},
											]);
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification.error({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification.error({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										});
								}}>
								<button
									style={{
										border: 0,
										background: "none",
									}}
									type="button">
									<UploadOutlined />
									<div
										style={{
											marginTop: 8,
										}}>
										Unggah Gambar
									</div>
								</button>
							</Upload>
							{previewImage && (
								<Image
									wrapperStyle={{
										display: "none",
									}}
									preview={{
										visible: previewOpen,
										onVisibleChange: (visible) => setPreviewOpen(visible),
										afterOpenChange: (visible) => !visible && setPreviewImage(""),
									}}
									src={previewImage}
								/>
							)}
						</div>
					),
				},
				{
					key: "Pilihan",
					value: (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 8,
							}}>
							{question.options.map((option, index) => (
								<ExamQuestionOption
									key={option._id}
									examId={exam._id}
									questionId={question._id}
									option={option}
									onDeleted={() => {
										const tempOptions = [...question.options];
										tempOptions.splice(index, 1);
										setQuestions(
											questions.map((q) => {
												if (q._id === question._id) {
													return {
														...q,
														options: tempOptions,
													};
												}
												return q;
											})
										);
									}}
								/>
							))}
							{newQuestionOptions.filter((o) => o.questionId === question._id).length > 0 && (
								<ExamQuestionOption
									examId={exam._id}
									questionId={question._id}
									option={{
										type: "TEXT",
										body: "",
									}}
									onDeleted={() => {
										setNewQuestionOptions(
											newQuestionOptions.filter((o) => o.questionId !== question._id)
										);
									}}
									onAdded={(exam) => {
										setNewQuestionOptions(
											newQuestionOptions.filter((o) => o.questionId !== question._id)
										);

										const questions = exam.questions;
										const que = questions.find((q) => q._id === question._id);

										setQuestions(
											questions.map((q) => {
												if (q._id === question._id) {
													return {
														...q,
														options: que.options,
													};
												}
												return q;
											})
										);
									}}
								/>
							)}
							{exam.status !== "PUBLISHED" &&
								(question.type === "MULTIPLE_CHOICE" || question.type === "MULTIPLE_ANSWER") && (
									<Button
										size="small"
										type="dashed"
										onClick={() => {
											setNewQuestionOptions([
												...newQuestionOptions,
												{
													questionId: question._id,
													type: "TEXT",
													body: "",
												},
											]);
										}}
										block
										icon={<PlusOutlined />}>
										Tambah Pilihan
									</Button>
								)}
						</div>
					),
				},
				{
					key: "Jawaban",
					value:
						question.type === "MULTIPLE_CHOICE" ||
						question.type === "MULTIPLE_ANSWER" ||
						question.type === "TRUE_FALSE" ? (
							<Select
								style={{
									minWidth: 160,
								}}
								size="small"
								mode={question.type === "MULTIPLE_ANSWER" ? "multiple" : null}
								placeholder="Pilih jawaban"
								allowClear
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								defaultValue={
									question.type === "MULTIPLE_ANSWER"
										? question.answers.length > 0
											? question.answers
											: []
										: question.answers.length > 0
										? question.answers[0]
										: ""
								}
								onChange={(value) => {
									const payload = {
										answers: question.type === "MULTIPLE_ANSWER" ? value : [value],
									};
									requestEditQuestion(exam._id, question._id, payload)
										.then((response) => {
											message.success(response.data.message);
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification.error({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification.error({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										});
								}}>
								{question.options.map((option, index) => (
									<Select.Option key={index} value={option._id}>
										{option.type === "BOOLEAN"
											? option.body === "true"
												? "Benar"
												: "Salah"
											: option.body}
									</Select.Option>
								))}
							</Select>
						) : null,
				},
				{
					key: "Bobot Nilai",
					value: (
						<InputNumber
							disabled={exam?.status === "PUBLISHED"}
							defaultValue={question.point}
							size="small"
							min={0}
							onBlur={(e) => {
								const payload = {
									point: e.target.value,
								};
								requestEditQuestion(exam._id, question._id, payload)
									.then((response) => {
										message.success(response.data.message);

										setQuestions(
											questions.map((q) => {
												if (q._id === question._id) {
													return {
														...q,
														point: parseFloat(e.target.value),
													};
												}
												return q;
											})
										);
									})
									.catch((error) => {
										if (error.response && error.response.data) {
											notification.error({
												message: "Kesalahan!",
												description: error.response.data.message,
											});
										} else {
											notification.error({
												message: "Kesalahan!",
												description: error.message,
											});
										}
									});
							}}
						/>
					),
				},
			]}
			loading={isLoading}
			bordered
			pagination={false}
		/>
	);
}

function ExamQuestionOption({ examId, questionId, option, onAdded, onDeleted }) {
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [types, setTypes] = React.useState([
		{
			label: "Teks",
			value: "TEXT",
		},
	]);

	const [body, setBody] = React.useState(option.body);
	const [type, setType] = React.useState(option.type);

	React.useEffect(() => {
		if (option.type === "BOOLEAN") {
			setTypes([
				{
					label: "Bool",
					value: "BOOLEAN",
				},
			]);
		} else {
			setTypes([
				{
					label: "Teks",
					value: "TEXT",
				},
			]);
		}
	}, [option.type]);

	const handleDelete = () => {
		if (!option._id) {
			onDeleted();
			return;
		}

		setIsDeleting(true);
		requestDeleteQuestionOption(examId, questionId, option._id)
			.then((response) => {
				message.success(response.data.message);

				onDeleted();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsDeleting(false);
			});
	};

	const handleSave = () => {
		setIsSaving(true);
		if (option._id) {
			requestEditQuestionOption(examId, questionId, option._id, {
				body: body,
				type: type,
			})
				.then((response) => {
					message.success(response.data.message);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification.error({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					setIsSaving(false);
				});
		} else {
			requestAddQuestionOption(examId, questionId, {
				body: body,
				type: type,
			})
				.then((response) => {
					message.success(response.data.message);

					onAdded(response.data.data);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification.error({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	return (
		<Space style={{ display: "flex", marginBottom: 0 }} align="baseline">
			<Select
				readOnly={option.type === "BOOLEAN"}
				size="small"
				defaultValue={option.type ?? "TEXT"}
				placeholder="Pilih jenis"
				options={types}
				onChange={(value) => {
					setType(value);
				}}
			/>
			<Input
				readOnly={option.type === "BOOLEAN"}
				size="small"
				placeholder="Opsi"
				defaultValue={
					option.type === "BOOLEAN"
						? option.body === "true"
							? "Benar"
							: "Salah"
						: option.body ?? ""
				}
				onChange={(e) => {
					setBody(e.target.value);
				}}
				onBlur={() => {
					handleSave();
				}}
			/>
			{option.type !== "BOOLEAN" && (
				<>
					{isDeleting ? (
						<Loading3QuartersOutlined spin />
					) : (
						<MinusCircleOutlined
							onClick={() => {
								handleDelete();
							}}
						/>
					)}
					{isSaving ? (
						<Loading3QuartersOutlined spin />
					) : (
						<CheckCircleOutlined
							onClick={() => {
								handleSave();
							}}
						/>
					)}
				</>
			)}
		</Space>
	);
}

function ExamQuestionFormModal({ isVisible, exam, onClose, onAdded }) {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = React.useState(false);
	const type = Form.useWatch("type", form);

	const handleClose = () => {
		form.resetFields();
		onClose(true);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setIsLoading(true);
				requestAddQuestion(exam._id, values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onAdded(response.data.data.questions[response.data.data.questions.length - 1]);
					})
					.catch((error) => {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description: "Silahkan lengkapi form dengan benar",
				});
			});
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				centered
				style={{
					top: 32,
				}}
				title="Tambah Pertanyaan"
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menambah pertanyaan ini?"
						okText="Ya"
						cancelText="Tidak">
						<Button loading={isLoading} type="primary">
							{"Tambah"}
						</Button>
					</Popconfirm>,
				]}>
				<Form layout="vertical" form={form} requiredMark="optional">
					<Form.Item
						style={{ marginTop: 0, marginBottom: 0 }}
						name="type"
						label="Jenis Pertanyaan"
						required
						rules={[{ required: true, message: "Jenis Pertanyaan wajib diisi" }]}
						tooltip="Jenis Pertanyaan">
						<Select
							placeholder="Jenis Pertanyaan"
							allowClear
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}>
							<Select.Option value="MULTIPLE_CHOICE">Pilihan Ganda</Select.Option>
							<Select.Option value="MULTIPLE_ANSWER">Jawaban Ganda</Select.Option>
							<Select.Option value="SHORT_ANSWER">Jawaban Singkat</Select.Option>
							<Select.Option value="TRUE_FALSE">Benar/Salah</Select.Option>
							<Select.Option value="FILL_BLANK">Isian</Select.Option>
							<Select.Option value="ESSAY">Esai</Select.Option>
							{/* <Select.Option value="FILE_UPLOAD">Unggah</Select.Option>
						<Select.Option value="ARRANGEMENT">Penyusunan</Select.Option> */}
						</Select>
					</Form.Item>
					{type === "MULTIPLE_CHOICE" ? (
						<Alert
							message="Pilihan ganda memiliki beberapa opsi, dan memungkinkan siswa untuk memilih satu opsi saja."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "MULTIPLE_ANSWER" ? (
						<Alert
							message="Jawaban ganda memiliki beberapa opsi, dan memungkinkan siswa untuk memilih opsi lebih dari satu."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "SHORT_ANSWER" ? (
						<Alert
							message="Jawaban singkat dapat memungkinkan siswa untuk mengisi jawaban dalam kata atau beberapa kata singkat"
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "TRUE_FALSE" ? (
						<Alert
							message="Siswa dapat menilai suatu pernyataan dengan opsi benar/salah"
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "FILL_BLANK" ? (
						<Alert
							message="Soal dapat berisi beberapa titik kosong, dan memungkinkan siswa untuk mengisi setiap titik kosong tersebut."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "ESSAY" ? (
						<Alert
							message="Esai merupakan soal yang dapat diisi dengan jawaban yang lebih kompleks."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "FILE_UPLOAD" ? (
						<Alert
							message="Siswa diharuskan untuk mengunggah file yang berisi jawaban."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : type === "ARRANGEMENT" ? (
						<Alert
							message="Tipe mengurutkan memungkinkan siswa untuk dapat melakukan drag dan drop untuk mengurutkan jawaban."
							type="warning"
							showIcon
							style={{
								marginTop: 8,
							}}
						/>
					) : null}
					<Form.Item
						style={{ marginTop: 12, marginBottom: 0 }}
						name="body"
						label="Pertanyaan"
						required
						rules={[{ required: true, message: "Pertanyaan wajib diisi" }]}
						tooltip="Pertanyaan">
						<Input.TextArea
							disabled={exam?.status === "PUBLISHED"}
							rows={4}
							placeholder="Pertanyaan"
							autoSize={{ minRows: 3, maxRows: 6 }}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}

function ScheduleFormModal({ isVisible, exam, onClose, onSuccess }) {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = React.useState(false);
	const [semester, setSemester] = React.useState(null);

	useEffect(() => {
		if (isVisible && exam) {
			const semester = exam.year?.semesters.find(
				(s) => dayjs(s.startedAt).isBefore(dayjs()) && dayjs().isBefore(s.endedAt)
			);
			setSemester(semester);

			if (exam.startedAt && exam.endedAt) {
				form.setFieldsValue({
					date: dayjs(exam.startedAt),
					times: [dayjs(exam.startedAt), dayjs(exam.endedAt)],
				});
			}
		} else {
			setSemester(null);
			form.resetFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, exam]);

	const handleClose = () => {
		form.resetFields();
		onClose(true);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				const date = dayjs(values.date).format("YYYY-MM-DD");
				const times = values.times.map((time) => {
					return dayjs(time).format("HH:mm");
				});

				const startedAt = `${date} ${times[0]}`;
				const endedAt = `${date} ${times[1]}`;

				setIsLoading(true);

				const payload = {
					startedAt: startedAt,
					endedAt: endedAt,
				};

				requestScheduleExam(exam._id, payload)
					.then((response) => {
						message.success(response.data.message);
						form.resetFields();

						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch((error) => {});
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			title="Jadwalkan"
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Batal
				</Button>,
				<Popconfirm
					key={2}
					onConfirm={handleSubmit}
					title="Apakah Anda yakin untuk menjadwalkan ujian ini?"
					okText="Ya"
					cancelText="Tidak">
					<Button loading={isLoading} type="primary">
						{"Jadwalkan"}
					</Button>
				</Popconfirm>,
			]}>
			<Form layout="vertical" form={form} requiredMark="optional">
				{/* Date */}
				<Form.Item
					style={{ marginTop: 0, marginBottom: 12 }}
					name="date"
					label="Tanggal"
					required
					rules={[{ required: true, message: "Tanggal wajib diisi" }]}>
					<DatePicker
						picker="date"
						placeholder="Tanggal"
						style={{
							width: "100%",
						}}
						minDate={semester ? dayjs(semester.startedAt) : null}
						maxDate={semester ? dayjs(semester.endedAt) : null}
					/>
				</Form.Item>
				<Form.Item
					style={{ marginTop: 0, marginBottom: 12 }}
					name="times"
					label="Waktu"
					required
					rules={[{ required: true, message: "Waktu wajib diisi" }]}>
					<TimePicker.RangePicker
						format={"HH:mm"}
						style={{
							width: "100%",
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

function ExamSubmissionDetailModal({ isVisible, examId, submissionId, onClose }) {
	const [submission, setSubmission] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [answers, setAnswers] = React.useState([]);
	const [saving, setSaving] = React.useState(false);
	const [syncing, setSyncing] = React.useState(false);

	React.useEffect(() => {
		if (isVisible && submissionId) {
			fetchSubmissionDetail();
		} else {
			setSubmission(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible && submissionId]);

	React.useEffect(() => {
		if (submission && submission?.exam.questions.length > 0) {
			const answers = submission?.exam.questions.map((question, index) => {
				const answer = submission?.answers.find((a) => a.questionId === question._id);

				// show answer entry
				let entry = answer?.entry;
				if (answer) {
					if (question.type === "MULTIPLE_CHOICE" || question.type === "TRUE_FALSE") {
						entry = question.options.find((o) => o._id === entry)?.body;
					} else if (question.type === "MULTIPLE_ANSWER") {
						if (answer?.entry) {
							const entries = JSON.parse(answer?.entry ?? "[]");
							entry = entries
								.map((e) => question.options.find((o) => o._id === e)?.body)
								.join(", ");
						} else {
							entry = "";
						}
					}
				}

				// show answer status
				let status = answer?.status ?? "";
				if (
					question.type === "MULTIPLE_CHOICE" ||
					question.type === "MULTIPLE_ANSWER" ||
					question.type === "TRUE_FALSE"
				) {
					if (answer) {
						if (question.type === "MULTIPLE_CHOICE" || question.type === "TRUE_FALSE") {
							const isCorrect = answer?.entry === question.answers[0];
							status = isCorrect ? "CORRECT" : "INCORRECT";
						} else if (question.type === "MULTIPLE_ANSWER") {
							if (answer?.entry) {
								const entries = JSON.parse(answer?.entry ?? "[]");
								// CORRECT if all answers inside the entries
								const isCorrect = entries.every((e) => question.answers.includes(e));
								status = isCorrect ? "CORRECT" : "INCORRECT";
							} else {
								status = "INCORRECT";
							}
						}
					} else {
						status = "INCORRECT";
					}
				}

				// show score
				let score = answer?.score ?? 0;
				if (
					answer &&
					(question.type === "MULTIPLE_CHOICE" ||
						question.type === "MULTIPLE_ANSWER" ||
						question.type === "TRUE_FALSE")
				) {
					if (status === "CORRECT") {
						score = question.point;
					}
				}

				return {
					questionId: question._id,
					entry: answer?.entry ?? "",
					key: question._id,
					order: index + 1,
					question: question.body,
					answer: entry,
					status: status,
					type: question.type,
					score: score,
					point: question.point,
				};
			});

			setAnswers(answers);
		} else {
			setAnswers([]);
		}
	}, [submission]);

	const fetchSubmissionDetail = () => {
		setLoading(true);
		requestShowExamSubmission(examId, submissionId)
			.then((response) => {
				const submission = response.data.data;
				setSubmission(submission);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnSaved = () => {
		setSaving(true);

		// make sure every answer has a questionId, status, score
		let isValid = true;
		answers.forEach((answer) => {
			if (!answer?.questionId || !answer?.status) {
				isValid = false;
			}
		});

		if (!isValid) {
			notification.error({
				message: "Kesalahan!",
				description: "Silahkan isi semua status jawaban",
			});

			setSaving(false);
			return;
		}

		requestMarkExamSubmission(submission?.examId, submission?._id, {
			answers: answers.map((answer) => {
				return {
					questionId: answer?.questionId,
					entry: answer?.entry,
					status: answer?.status,
					score: answer?.score,
				};
			}),
		})
			.then((response) => {
				message.success(response.data.message);

				fetchSubmissionDetail();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	return (
		<Modal
			maskClosable={false}
			width={"calc(100% - 64px)"}
			style={{
				top: 32,
			}}
			title="Lembar Jawaban Siswa"
			open={isVisible}
			cancelText="Batal"
			onCancel={onClose}
			footer={[
				<Button type="primary" key="save" onClick={handleOnSaved} loading={saving}>
					Simpan Penilaian
				</Button>,
				<Button key="close" onClick={onClose}>
					Tutup
				</Button>,
			]}>
			<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
				<Col lg={8} md={24}>
					<Space
						direction="vertical"
						size="middle"
						className="layout-popup"
						style={{ width: "100%" }}>
						<Card title="Detail" size="small" loading={loading}>
							<Descriptions size="small" layout="vertical" column={2}>
								<Descriptions.Item label="NISN">{submission?.student.number}</Descriptions.Item>
								<Descriptions.Item label="Nama">{submission?.student.name}</Descriptions.Item>
								<Descriptions.Item label="Mulai">
									{dayjs(submission?.startedAt).format("DD MMMM YYYY HH:mm:ss")}
								</Descriptions.Item>
								<Descriptions.Item label="Selesai">
									{submission?.submittedAt
										? dayjs(submission?.submittedAt).format("DD MMMM YYYY HH:mm:ss")
										: "-"}
								</Descriptions.Item>
								<Descriptions.Item label="Durasi">
									{durationToString(
										dayjs(submission?.submittedAt).diff(submission?.startedAt, "second"),
										true
									)}
								</Descriptions.Item>
								<Descriptions.Item label="Status">
									{submission?.status === "STARTED"
										? "Sedang berlangsung"
										: submission?.status === "SUBMITTED"
										? "Selesai dikerjakan"
										: submission?.status === "MARKED"
										? "Telah dinilai"
										: "Ditolak"}
								</Descriptions.Item>
							</Descriptions>
						</Card>
						{submission?.status === "MARKED" && (
							<Card title="Hasil Penilaian" loading={loading} size="small">
								{/* centered items */}
								<Typography.Title style={{ textAlign: "center", marginBottom: 0 }}>
									{submission?.score}
								</Typography.Title>
								<Typography.Title
									type="secondary"
									level={5}
									style={{
										textAlign: "center",
										marginBottom: 0,
										marginTop: 0,
									}}>
									dari {submission?.exam.questions.map((q) => q.point).reduce((a, b) => a + b, 0)}
								</Typography.Title>
								{submission?.exam.assessment && (
									<div style={{ textAlign: "center", marginTop: 8 }}>
										<Typography.Text type="secondary" style={{ textAlign: "center" }}>
											Terhubung dengan rapor{" "}
											<Typography.Text strong>{submission?.exam.assessment.name}</Typography.Text>
											<Popconfirm
												key="sync"
												title={`Yakin ingin menyinkronkan dengan buku rapor (${submission?.exam.assessment.name}) untuk tahun ajaran ini dan semester ini?`}
												okText="Ya"
												cancelText="Tidak"
												onConfirm={() => {
													setSyncing(true);
													requestSyncExamSubmission(submission?.examId, submission?._id)
														.then((response) => {
															message.success(response.data.message);
														})
														.catch((error) => {
															if (error.response && error.response.data) {
																notification.error({
																	message: "Kesalahan!",
																	description: error.response.data.message,
																});
															} else {
																notification.error({
																	message: "Kesalahan!",
																	description: error.message,
																});
															}
														})
														.finally(() => {
															setSyncing(false);
														});
												}}
												okButtonProps={{ loading: syncing }}>
												<Button
													type="primary"
													style={{
														marginLeft: 4,
													}}
													size="small"
													loading={loading}>
													Sinkronkan
												</Button>
											</Popconfirm>
										</Typography.Text>
									</div>
								)}
							</Card>
						)}
					</Space>
				</Col>
				<Col lg={16} md={24}>
					<Space
						direction="vertical"
						size="middle"
						className="layout-popup"
						style={{ width: "100%" }}>
						<Card title="Jawaban" size="small">
							<Table
								loading={loading}
								size="small"
								columns={[
									{
										title: "No",
										dataIndex: "order",
										fixed: "left",
										key: "order",
										align: "center",
										width: 36,
									},
									{
										title: "Soal",
										dataIndex: "question",
										key: "question",
										render: (text, record) => {
											return (
												<div
													style={{
														display: "flex",
														flexDirection: "column",
													}}>
													<div>
														<Tag
															style={{
																marginInlineEnd: 0,
															}}>
															{record.type === "MULTIPLE_CHOICE"
																? "Pilihan Ganda"
																: record.type === "MULTIPLE_ANSWER"
																? "Jawaban Ganda"
																: record.type === "TRUE_FALSE"
																? "Benar/Salah"
																: record.type === "SHORT_ANSWER"
																? "Jawaban Singkat"
																: record.type === "ESSAY"
																? "Esai"
																: "Tidak Diketahui"}
														</Tag>
													</div>
													<Typography.Text>{text}</Typography.Text>
												</div>
											);
										},
									},
									{
										title: "Jawaban",
										dataIndex: "answer",
										key: "answer",
									},
									{
										title: "Status",
										dataIndex: "status",
										key: "status",
										align: "center",
										width: 48,
										render: (text, record) => {
											return (
												<Select
													size="small"
													disabled={
														record.type === "MULTIPLE_CHOICE" ||
														record.type === "MULTIPLE_ANSWER" ||
														record.type === "TRUE_FALSE"
													}
													defaultValue={record.status}
													onChange={(value) => {
														const newAnswer = { ...record, status: value };
														const newAnswers = answers.map((answer) => {
															if (answer?.key === record.key) {
																return newAnswer;
															}
															return answer;
														});
														setAnswers(newAnswers);
													}}
													style={{ width: "100%" }}>
													<Select.Option value="">Belum dinilai</Select.Option>
													<Select.Option value="CORRECT">Benar</Select.Option>
													<Select.Option value="INCORRECT">Salah</Select.Option>
													<Select.Option value="PARTIAL">Sebagian</Select.Option>
												</Select>
											);
										},
									},
									{
										title: "Bobot",
										dataIndex: "point",
										key: "point",
										width: 80,
										align: "center",
									},
									{
										title: "Nilai",
										dataIndex: "score",
										key: "score",
										width: 80,
										align: "center",
										render: (text, record) => {
											return (
												<InputNumber
													size="small"
													disabled={
														record.type === "MULTIPLE_CHOICE" ||
														record.type === "MULTIPLE_ANSWER" ||
														record.type === "TRUE_FALSE"
													}
													max={record.point}
													defaultValue={record.score}
													style={{ width: "100%" }}
													onChange={(value) => {
														const newAnswer = { ...record, score: value };
														const newAnswers = answers.map((answer) => {
															if (answer?.key === record.key) {
																return newAnswer;
															}
															return answer;
														});
														setAnswers(newAnswers);
													}}
												/>
											);
										},
									},
								]}
								dataSource={[...answers]}
								bordered
								pagination={false}
							/>
						</Card>
					</Space>
				</Col>
			</Row>
		</Modal>
	);
}

export default ExamDetailModal;
