import { Button, InputNumber, message, notification, Space } from "antd";
import { useState } from "react";
import { requestEditReportMark } from "../../../../services/teacher.service";
import { CheckOutlined } from "@ant-design/icons";

function LessonReportMarkCell({
  inputRef,
  markId,
  assessmentId,
  score,
  onSaved,
  onMove,
}) {
  const [newScore, setNewScore] = useState(score);
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveMarkButton = () => {
    setIsSaving(true);
    requestEditReportMark(markId, {
      assessmentId: assessmentId,
      score: newScore ?? 0,
    })
      .then((response) => {
        message.success(response.data.message);

        onSaved();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Space size={0}>
      <InputNumber
        ref={inputRef}
        size="small"
        min={0}
        max={100}
        onChange={(value) => setNewScore(value)}
        value={newScore}
        controls={false}
        keyboard={false}
        style={{
          width: 50,
          borderTopRightRadius: score !== newScore ? 0 : 4,
          borderBottomRightRadius: score !== newScore ? 0 : 4,
        }}
        onPressEnter={() => {
          if (score === newScore) {
            return;
          }
          handleSaveMarkButton();
        }}
        onKeyDown={(e) => {
          if (
            e.key === "ArrowRight" ||
            e.key === "ArrowDown" ||
            e.key === "ArrowUp" ||
            e.key === "ArrowLeft"
          ) {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.preventDefault();

            onMove(e.key);
          }
        }}
      />
      {score !== newScore && (
        <Button
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 50,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          type={score === newScore ? "default" : "primary"}
          loading={isSaving}
          size="small"
          onClick={() => {
            if (score === newScore) {
              return;
            }
            handleSaveMarkButton();
          }}
        >
          <CheckOutlined />
        </Button>
      )}
    </Space>
  );
}

export default LessonReportMarkCell;
