/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import { addSubject, editSubject } from "../../../../services/admin.service";

const SubjectFormModal = ({
  subject,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (subject !== null) {
      form.setFieldsValue({
        name: subject.name,
      });
    }
  }, [subject]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        if (subject) {
          values.id = subject._id;
          editSubject(values)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          addSubject(values)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          subject === null || subject === undefined
            ? "Tambah Mata Pelajaran"
            : "Ubah Mata Pelajaran"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data mata pelajaran?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">{subject ? "Simpan" : "Tambah"}</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              tooltip="Masukkan nama mata pelajaran"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default SubjectFormModal;
