/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  requestCloneClassroom,
  requestGetClassroomList,
} from "../../../../services/admin.service";

const ClassroomCloneFormModal = ({
  activeYear,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
  years,
}) => {
  const { notification } = App.useApp();
  const [yearSourceId, setYearSourceId] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (yearSourceId !== "") {
      fetchClassroomList();
    } else {
      setClassrooms([]);
    }
  }, [yearSourceId]);

  const fetchClassroomList = () => {
    requestGetClassroomList({
      filter: `year:${yearSourceId}`,
    })
      .then((response) => {
        setClassrooms(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        values.ids = checkedList;
        requestCloneClassroom(values)
          .then((response) => {
            message.success(response.data.message);

            handleSuccess();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              notification["error"]({
                message: "Kesalahan!",
                description: error.response.data.message,
              });
            } else {
              notification["error"]({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            onLoading(false);
          });
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    setYearSourceId("");
    setCheckAll(false);
    setIndeterminate(false);
    setCheckedList([]);
    onClose();
  };

  const handleSuccess = () => {
    form.resetFields();
    setYearSourceId("");
    setCheckAll(false);
    setIndeterminate(false);
    setCheckedList([]);
    onSuccess();
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < classrooms.length);
    setCheckAll(list.length === classrooms.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? classrooms.map((classroom) => classroom._id) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={"Klon Kelas"}
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan melakukan proses kloning?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">{"Aplikasikan"}</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Typography.Text>
              Klon kelas dari tahun akademik asal ke tahun akademik tujuan.
              Kloning kelas akan membuat kelas baru{" "}
              <strong>lengkap dengan pelajaran dan wali kelasnya</strong>.
              Fungsi ini berguna untuk membuat kelas baru dengan pelajaran yang
              sama dari tahun asal beserta wali kelasnya, sehingga tidak perlu
              membuat kelas baru satu per satu. Namun Anda perlu menambahkan
              siswa ke kelas baru secara manual.
            </Typography.Text>
            <Divider />
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="yearSourceId"
              label="Tahun Akademik Asal"
              required
              rules={[
                {
                  required: true,
                  message: "Tahun akademik asal wajib dipilih!",
                },
              ]}
              tooltip="Pilih tahun akademik asal"
            >
              <Select
                placeholder="Pilih Tahun Akademik Asal"
                showSearch
                onChange={(value) => {
                  if (value !== "") {
                    setYearSourceId(value);
                    form.setFieldsValue({ yearId: null });
                  }
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {years !== undefined &&
                  years.map((year) => {
                    return (
                      <Select.Option key={year._id} value={year._id}>
                        {year.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {classrooms.length > 0 && (
              <>
                <Form.Item
                  style={{ marginTop: 12, marginBottom: 0 }}
                  name="ids"
                  label="Kelas"
                  required
                  tooltip="Pilih kelas yang akan diklon"
                >
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    Pilih Semua
                  </Checkbox>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checkedList}
                    onChange={onChange}
                  >
                    <Row>
                      {classrooms.map((classroom) => {
                        return (
                          <Col key={classroom._id} span={12}>
                            <Checkbox
                              value={classroom._id}
                            >{`${classroom.name}`}</Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="yearId"
              label="Tahun Akademik Tujuan"
              required
              rules={[
                {
                  required: true,
                  message: "Tahun akademik tujuan wajib dipilih!",
                },
              ]}
              tooltip="Pilih tahun akademik tujuan"
            >
              <Select
                placeholder="Pilih Tahun Akademik Tujuan"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {years !== undefined &&
                  years
                    .filter(
                      (year) =>
                        year._id !== yearSourceId || yearSourceId === "",
                    )
                    .map((year) => {
                      return (
                        <Select.Option key={year._id} value={year._id}>
                          {year.name}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ClassroomCloneFormModal;
