/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ExclamationCircleFilled,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  App,
  Button,
  Card,
  Col,
  Dropdown,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  deleteCurriculum,
  requestGetCurriculumList,
  requestGetSubjectList,
} from "../../../services/admin.service";
import CurriculumFormModal from "./components/CurriculumFormModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "Nama",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Mata Pelajaran",
    dataIndex: "subjects",
    key: "subjects",
    render: (text, record) => {
      return (
        <Space size="small">
          {record.subjects.map((subject, index) => (
            <Tag key={index} color="blue">
              {subject.name}
            </Tag>
          ))}
        </Space>
      );
    },
  },
  {
    title: "Skala Nilai",
    dataIndex: "scale",
    key: "scale",
  },
  {
    title: "Aksi",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];

const otherButtons = [
  {
    key: "edit",
    label: "Edit",
    icon: <HiOutlinePencil />,
  },
  {
    type: "divider",
  },
  {
    key: "delete",
    label: "Hapus",
    icon: <HiOutlineTrash />,
    danger: true,
  },
];

const CurriculumPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notification, modal } = App.useApp();

  useLayoutEffect(() => {
    document.title = "Ruang Admin | Akademik | Kurikulum";
  }, []);

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({});
  const [sorter, setSorter] = useState({
    _id: "desc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow startedAt and endedAt
      Object.keys(filter).forEach((key) => {
        if (key === "startedAt" || key === "endedAt") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        _id: "desc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  const [curriculums, setCurriculums] = useState([]);
  const [mappedCurriculums, setMappedCurriculums] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const [curriculum, setCurriculum] = useState(null);
  const [isCurriculumFormVisible, setCurriculumFormVisible] = useState(false);

  useEffect(() => {
    fetchSubjectList();
  }, []);

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    fetchCurriculumList();
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, []);

  const fetchCurriculumList = () => {
    setLoading(true);
    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetCurriculumList({
      page: page,
      limit: limit,
      order: tempSorter ?? "_id:desc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTotal(response.data.pagination.total);
        setCurriculums(response.data.data);
        updateMappedCurriculum(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    requestGetSubjectList({
      page: 1,
      limit: 1000,
    })
      .then((response) => {
        setSubjects(response.data.data);
      })
      .catch((error) => {});
  };

  const updateMappedCurriculum = (data) => {
    setMappedCurriculums(
      data.map((d) => ({
        key: d._id,
        name: d.name,
        description: d.description,
        subjects: d.subjects,
        scale: d.scale,
        actions: (
          <Space>
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: (e) => {
                  if (e.key === "edit") {
                    handleEditButton(d);
                  } else if (e.key === "delete") {
                    modal.confirm({
                      title: "Sebentar",
                      icon: <ExclamationCircleFilled />,
                      content:
                        "Anda yakin akan menghapus kurikulum ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
                      okText: "Ya",
                      okType: "danger",
                      cancelText: "Tidak",
                      onOk() {
                        handleDeleteButton(d._id);
                      },
                      onCancel() {},
                    });
                  }
                },
                items: otherButtons,
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        ),
      })),
    );
  };

  const handleAddButton = () => {
    setCurriculumFormVisible(true);
  };

  const handleEditButton = (curriculum) => {
    setCurriculum(curriculum);
    setCurriculumFormVisible(true);
  };

  const handleDeleteButton = (id) => {
    setLoading(true);
    deleteCurriculum(id)
      .then((response) => {
        if (response.data.code === 200) {
          message.success(response.data.message);

          fetchCurriculumList();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseCurriculumForm = () => {
    setCurriculum(null);
    setCurriculumFormVisible(false);
  };

  const handleOnSuccessCurriculumForm = () => {
    setCurriculum(null);
    setCurriculumFormVisible(false);
    fetchCurriculumList();
  };

  return (
    <>
      <CurriculumFormModal
        isLoading={isLoading}
        isVisible={isCurriculumFormVisible}
        onLoading={(v) => setLoading(v)}
        curriculum={curriculum}
        onClose={handleOnCloseCurriculumForm}
        onSuccess={handleOnSuccessCurriculumForm}
        subjects={subjects}
      />
      <Card
        bordered={false}
        title="Daftar Kurikulum"
        extra={
          <Space>
            <SearchInput
              placeholder="Nama kurikulum"
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
            <Button
              icon={<PlusCircleFilled />}
              type="primary"
              onClick={handleAddButton}
            >
              <span className="ant-btn-text">Tambah</span>
            </Button>
          </Space>
        }
      >
        <div>
          <Table
            columns={columns}
            loading={isLoading}
            bordered
            dataSource={mappedCurriculums}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onChange={handlePaginate}
          />
        </div>
      </Card>
    </>
  );
};

export default CurriculumPage;
