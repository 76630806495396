import axios from "axios";

// MODULE
export async function requestGetModuleList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/modules?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// MODULE

// ANNOUNCEMENT
export async function requestGetAnnouncementList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/announcements?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddAnnouncement(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/announcements/add`, data);
}

export async function requestEditAnnouncement(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/announcements/${id}/edit`, data);
}

export async function requestDeleteAnnouncement(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/announcements/${id}/delete`);
}
// ANNOUNCEMENT

// SCHOOL
export async function requestShowSchool({ join = "" }) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/schools/us?join=${join}`);
}
export async function requestUpdateSchool(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/schools/us/update`, data);
}
// SCHOOL

// CURRICULUM
export async function requestGetCurriculumList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/curriculums?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addCurriculum(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/curriculums/add`, data);
}

export async function editCurriculum(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/curriculums/${id}/edit`, data);
}

export async function deleteCurriculum(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/curriculums/${id}/delete`);
}
// CURRICULUM

// YEAR
export async function requestGetYearList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/years?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addYear(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/years/add`, data);
}

export async function editYear(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/years/edit`, data);
}

export async function deleteYear(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/years/delete`, {
		data: { id },
	});
}

export async function getActiveYear() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/years/active`);
}
// YEAR

// SUBJECT
export async function requestGetSubjectList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/subjects?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addSubject(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/subjects/add`, data);
}

export async function editSubject(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/subjects/edit`, data);
}

export async function deleteSubject(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/subjects/delete`, {
		data: { id },
	});
}
// SUBJECT

// CLASSROOM
export async function requestGetClassroomList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/classrooms?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function showClassroom(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}`);
}

export async function addClassroom(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/classrooms/add`, data);
}

export async function editClassroom(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/classrooms/${data.id}/edit`, data);
}

export async function deleteClassroom(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/classrooms/delete`, {
		data: { id },
	});
}

export async function requestEnrollStudentsToClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/students/enroll`,
		data
	);
}

export async function requestUnenrollStudentsFromClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/students/unenroll`,
		data
	);
}

export async function requestMoveStudent(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/students/move`,
		payload
	);
}

export async function requestAssignTeacherToClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/teachers/assign`,
		data
	);
}

export async function requestUnassignTeacherFromClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/teachers/unassign`,
		data
	);
}

export async function requestCloneClassroom(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/classrooms/clone`, data);
}
// CLASSROOM

// EXTRACURRICULAR
export async function requestGetExtracurricularList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/extracurriculars?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExtracurricular(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}`);
}

export async function requestAddExtracurricular(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/extracurriculars/add`, data);
}

export async function requestEditExtracurricular(id, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/edit`,
		payload
	);
}

export async function requestDeleteExtracurricular(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/extracurriculars/delete`, {
		data: { id },
	});
}

export async function requestEnrollStudentsToExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/students/enroll`,
		data
	);
}

export async function requestUnenrollStudentsFromExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/students/unenroll`,
		data
	);
}

export async function requestAssignTeacherToExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/teachers/assign`,
		data
	);
}

export async function requestUnassignTeacherFromExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/teachers/unassign`,
		data
	);
}

export async function requestCloneExtracurricular(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/extracurriculars/clone`, data);
}

export async function requestScheduleExtracurricular(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/extracurriculars/${id}/schedule`,
		payload
	);
}
// EXTRACURRICULAR

// LESSON
export async function requestGetLessonList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/lessons?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGenerateLessons(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/lessons/generate`, data);
}

export async function requestShowLesson(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}`);
}

export async function addLesson(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/lessons/add`, data);
}

export async function requestEditLesson(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/edit`, data);
}

export async function deleteLesson(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/delete`);
}
export async function requestScheduleLesson(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/schedule`, data);
}

export async function requestAssignTeacherToLesson(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/teachers/assign`,
		data
	);
}

export async function requestUnassignTeacherFromLesson(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/teachers/unassign`,
		data
	);
}
// LESSON

// STUDENT
export async function requestGetStudentList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/students?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowStudent(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/students/${id}`);
}

export async function addStudent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/add`, data);
}

export async function requestEditStudent(id, payload) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/students/${id}/edit`, payload);
}

export async function deleteStudent(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/students/delete`, {
		data: { id },
	});
}

export async function importStudentList(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/import`, data);
}

export async function requestBillStudent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/bill`, data);
}

export async function requestPromoteStudents(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/promote`, data);
}
// STUDENT

// TEACHER
export async function requestGetTeacherList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/teachers?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addTeacher(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/teachers/add`, data);
}

export async function requestEditTeacher(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/teachers/${id}/edit`, data);
}

export async function deleteTeacher(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/teachers/delete`, {
		data: { id },
	});
}

export async function importTeacherList(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/teachers/import`, data);
}
// TEACHER

// EVENT
export async function getEventList(start, end, filter = "type:LESSON|EXAM|EXTRA|OTHER") {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/events?order=startedAt:asc&start=${start}&end=${end}&filter=${filter}`
	);
}

export async function requestGetEventList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/events?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowEvent(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/${id}`);
}

export async function requestAddEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/add`, data);
}

export async function requestEditEvent(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/events/${id}/edit`, data);
}

export async function requestDeleteEvent(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/events/${id}/delete`);
}

export async function requestReportEvent(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/${id}/report`, data);
}
// EVENT

// FEE
export async function requestGetFeeList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/fees?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addFee(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/fees/add`, data);
}

export async function editFee(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/fees/edit`, data);
}

export async function deleteFee(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/fees/delete`, {
		data: { id },
	});
}
// FEE

// INVOICE
export async function requestGetInvoiceList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/invoices?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddInvoice(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/add`, data);
}

export async function requestEditInvoice(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/invoices/edit`, data);
}

export async function requestUpdateInvoiceStatus(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/update-status`, data);
}

export async function requestGenerateInvoices(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/generate`, data);
}
// INVOICE

// TRANSACTION
export async function requestGetTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddTransaction(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/transactions/add`, data);
}

export async function requestUpdateTransactionStatus(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/transactions/update-status`,
		data
	);
}

export async function requestShowTransaction(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/transactions/${id}`);
}
// TRANSACTION

// USER
export async function requestGetUserList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/users?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddUser(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/users/add`, data);
}

export async function requestEditUser(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/users/${id}/edit`, data);
}
// USER

// ARTICLE
export async function requestGetArticleList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/articles?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddArticle(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/add`, data);
}

export async function requestEditArticle(slug, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/articles/${slug}/edit`, data);
}

export async function requestDeleteArticle(slug) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/articles/${slug}/delete`);
}
// ARTICLE

// BILL
export async function requestGetBillList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/bills?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestPayBill(id, channel) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/bills/${id}/pay`, {
		channel: channel,
	});
}

export async function requestPrintBill(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/bills/${id}/print`);
}
// BILL

// POCKET
export async function requestGetPocketList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@pocket-module/pockets?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowPocket(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}`);
}

export async function requestBlockPocket(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/block`
	);
}

export async function requestUnblockPocket(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/unblock`
	);
}

export async function requestWithdrawPocket(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/withdraw`,
		data
	);
}

export async function requestDepositPocket(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/deposit`,
		data
	);
}

export async function requestGetTotalPocketBalance() {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/get-total-balance`
	);
}

export async function requestLinkPocketToCard(id, number) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/link`,
		{
			number: number,
		}
	);
}

export async function requestUnlinkPocketFromCard(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/unlink`
	);
}
// POCKET

// POCKET-TRANSACTION
export async function requestGetPocketTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@pocket-module/transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestUpdatePocketTransactionStatus(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/transactions/update-status`,
		data
	);
}
// POCKET-TRANSACTION

// PAYMENT-TRANSACTION
export async function requestGetPaymentTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@payment-module/payment-transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// PAYMENT-TRANSACTION

// REPORT-ASSESSMENT
export async function requestGetReportAssessmentList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@report-module/report-assessments?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddReportAssessment(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-assessments/add`,
		data
	);
}

export async function requestEditReportAssessment(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-assessments/${id}/edit`,
		data
	);
}

export async function requestDeleteReportAssessment(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-assessments/${id}/delete`
	);
}
// REPORT-ASSESSMENT

// REPORT-CARD
export async function requestGetReportCardList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@report-module/report-cards?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGenerateReportCards(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/generate`,
		data
	);
}

export async function requestShowReportCard(id) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/${id}`
	);
}

export async function requestEditReportCard(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/${id}/edit`,
		data
	);
}

export async function requestDeleteReportCard(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/${id}/delete`
	);
}

export async function requestSignReportCard(payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/sign`,
		payload
	);
}

export async function requestPublishReportCards(ids) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/publish`,
		{
			ids: ids,
		}
	);
}

export async function requestPrintReportCard(id, type) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-cards/${id}/print?type=${type}`,
		{},
		{
			responseType: "blob",
		}
	);
}
// REPORT-CARD

// REPORT-MARK
export async function requestGetReportMarkList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@report-module/report-marks?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestEditReportMark(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-marks/${id}/edit`,
		data
	);
}

export async function requestSignReportMarks(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-marks/sign`,
		data
	);
}
// REPORT-MARK

// REPORT-EXTRACURRICULAR-MARK
export async function requestGetReportExtracurricularMarkList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@report-module/report-extracurricular-marks?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestEditReportExtracurricularMark(id, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@report-module/report-extracurricular-marks/${id}/edit`,
		payload
	);
}
// REPORT-EXTRACURRICULAR-MARK

// ATTENDANCE REPORT
export async function requestGetAttendanceReportList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@attendance-module/attendance-reports?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowAttendanceReport(id) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/@attendance-module/attendance-reports/${id}`
	);
}

export async function requestAddAttendanceReport(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@attendance-module/attendance-reports/add`,
		data
	);
}

export async function requestEditAttendanceReport(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@attendance-module/attendance-reports/${id}/edit`,
		data
	);
}
// ATTENDANCE REPORT

// EXAM
export async function requestGetExamList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@exam-module/exams?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExam(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}`);
}

export async function requestAddExam(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/add`, data);
}

export async function requestEditExam(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/edit`,
		data
	);
}

export async function requestPublishExam(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/publish`
	);
}

export async function requestArchiveExam(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/archive`
	);
}

export async function requestLinkExam(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/link`,
		payload
	);
}

export async function requestUnlinkExam(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/unlink`);
}

export async function requestScheduleExam(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/schedule`,
		payload
	);
}

export async function requestGenerateExamToken(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/generate-token`
	);
}

export async function requestDeleteExamToken(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/delete-token`
	);
}

export async function requestDeleteExam(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/delete`
	);
}

export async function requestAddQuestion(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/add`,
		payload
	);
}

export async function requestEditQuestion(id, questionId, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/${questionId}/edit`,
		payload
	);
}

export async function requestDeleteQuestion(id, questionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/${questionId}/delete`
	);
}

export async function requestAddQuestionOption(id, questionId, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/${questionId}/options/add`,
		payload
	);
}

export async function requestEditQuestionOption(id, questionId, optionId, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/${questionId}/options/${optionId}/edit`,
		payload
	);
}

export async function requestDeleteQuestionOption(id, questionId, optionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/questions/${questionId}/options/${optionId}/delete`
	);
}

export async function requestGetExamSubmissionList(
	id,
	{ page = 1, limit = 10, order = "_id:desc", keyword = "", filter = "" }
) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@exam-module/exams/${id}/submissions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExamSubmission(id, submissionId) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/submissions/${submissionId}`
	);
}

export async function requestMarkExamSubmission(id, submissionId, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/submissions/${submissionId}/mark`,
		payload
	);
}

export async function requestSyncExamSubmission(id, submissionId) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/submissions/${submissionId}/sync`
	);
}

export async function requestDeleteExamSubmission(id, submissionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@exam-module/exams/${id}/submissions/${submissionId}/delete`
	);
}
// EXAM

// CARD
export async function requestGetCardList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@card-module/cards?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowCard(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/${id}`);
}

export async function requestAddCard(payload) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/add`, payload);
}

// export async function requestEditCard(id, payload) {
// 	return await axios.put(
// 		`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/${id}/edit`,
// 		payload
// 	);
// }

export async function requestDeleteCard(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/${id}/delete`
	);
}

export async function requestBlockCard(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/${id}/block`);
}

export async function requestUnblockCard(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@card-module/cards/${id}/unblock`
	);
}
// CARD

// STORE
export async function requestGetStoreList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@store-module/stores?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowStore(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${id}`);
}

export async function requestAddStore(payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/add`,
		payload
	);
}

export async function requestEditStore(id, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${id}/edit`,
		payload
	);
}

export async function requestDeleteStore(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${id}/delete`
	);
}

export async function requestAssignMerchantToStore(storeId, email) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${storeId}/assign`,
		{
			email: email,
		}
	);
}

export async function requestUnassignMerchantFromStore(storeId, email) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${storeId}/unassign`,
		{
			email: email,
		}
	);
}

export async function requestWithdrawStore(storeId, amount) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/stores/${storeId}/withdraw`,
		{
			amount: amount,
		}
	);
}
// STORE

// STORE TRANSACTION
export async function requestGetStoreTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@store-module/transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAcceptStoreTransaction(transactionId) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/transactions/${transactionId}/accept`
	);
}

export async function requestRejectStoreTransaction(transactionId) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@store-module/transactions/${transactionId}/reject`
	);
}
// STORE TRANSACTION
