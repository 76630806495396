/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, message, Modal, notification, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { requestAddStore, requestEditStore } from "../../../../services/admin.service";

const StoreFormModal = ({ open, store, onClose, onSuccess }) => {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (store) {
			form.setFieldsValue({
				name: store.name,
				description: store.description,
			});
		}
	}, [store]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setSubmitting(true);
				if (store) {
					requestEditStore(store._id, values)
						.then((response) => {
							message.success(response.data.message);

							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							setSubmitting(false);
						});
				} else {
					requestAddStore(values)
						.then((response) => {
							message.success(response.data.message);

							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							setSubmitting(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={store ? "Ubah Toko" : "Tambah Toko"}
				open={open}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data toko?"
						okText="Ya"
						cancelText="Tidak">
						<Button loading={submitting} type="primary">
							{store ? "Simpan" : "Tambah"}
						</Button>
					</Popconfirm>,
				]}>
				<Form form={form} layout="vertical" requiredMark="optional">
					<Form.Item
						style={{ marginTop: 0, marginBottom: 0 }}
						name="name"
						label="Nama Toko"
						required
						rules={[{ required: true, message: "Masukkan nama toko!" }]}
						tooltip="Masukkan nama toko">
						<Input style={{ width: "100%" }} />
					</Form.Item>
					<Form.Item
						style={{ marginTop: 12, marginBottom: 0 }}
						name="description"
						label="Deskripsi"
						required
						rules={[{ required: true, message: "Masukkan deskripsi!" }]}
						tooltip="Masukkan deskripsi">
						<Input style={{ width: "100%" }} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default StoreFormModal;
