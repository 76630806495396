import {
	Button,
	Descriptions,
	Form,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
} from "antd";
import { requestWithdrawPocket } from "../../../../services/admin.service";
import { useEffect, useState } from "react";
import PocketTransactionConfirmationFormModal from "./PocketTransactionConfirmationFormModal";

export default function PocketWithdrawalFormModal({ isVisible, pocket, onSuccess, onClose }) {
	const [isLoading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const [isConfirmationVisible, setConfirmationVisible] = useState(false);
	const [transaction, setTransaction] = useState(null);

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			setLoading(true);
			requestWithdrawPocket(pocket._id, values)
				.then((response) => {
					message.success(response.data.message);

					setTransaction(response.data.data);
					setConfirmationVisible(true);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification.error({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	useEffect(() => {
		if (!isVisible) {
			form.resetFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const handleConfirmationSuccess = () => {
		setConfirmationVisible(false);
		setTransaction(null);
		onSuccess();
	};

	const handleConfirmationClose = () => {
		setConfirmationVisible(false);
		setTransaction(null);
		onSuccess();
	};

	return (
		<>
			{transaction && (
				<PocketTransactionConfirmationFormModal
					isVisible={isConfirmationVisible}
					transaction={transaction}
					onSuccess={handleConfirmationSuccess}
					onClose={handleConfirmationClose}
				/>
			)}
			<Modal
				maskClosable={false}
				width={512}
				title="Tarik Tunai"
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan melakukan penarikan?"
						okText="Ya"
						cancelText="Batal">
						<Button loading={isLoading} type="primary">
							Yakin
						</Button>
					</Popconfirm>,
				]}>
				{pocket && (
					<Form form={form} layout="vertical" requiredMark="optional">
						<Descriptions size="small" column={2} bordered layout="vertical">
							<Descriptions.Item label="Nomor Kartu" span={2}>
								{pocket.number.match(/.{1,4}/g).join("-")}
							</Descriptions.Item>
							<Descriptions.Item label="Saldo">
								{Intl.NumberFormat("id-ID", {
									style: "currency",
									currency: "IDR",
									minimumFractionDigits: 0,
								}).format(pocket.balance)}
							</Descriptions.Item>
							<Descriptions.Item label="Limit Harian">
								{pocket.limit
									? Intl.NumberFormat("id-ID", {
											style: "currency",
											currency: "IDR",
											minimumFractionDigits: 0,
									  }).format(pocket.limit)
									: "Tak terbatas"}
							</Descriptions.Item>
							<Descriptions.Item label="Nama">{pocket.name}</Descriptions.Item>
							<Descriptions.Item label="NISN">{pocket.student?.number}</Descriptions.Item>
						</Descriptions>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							required
							label="Jumlah"
							name="amount"
							rules={[
								{
									required: true,
									message: "Masukkan jumlah penarikan!",
								},
								{
									type: "number",
									message: "Masukkan angka!",
								},
								{
									validator: (rule, value) => {
										if (value < 10000) {
											return Promise.reject("Minimal 10.000!");
										}

										return Promise.resolve();
									},
								},
								{
									validator: (rule, value) => {
										if (value > pocket.balance) {
											return Promise.reject("Saldo tidak mencukupi!");
										}

										return Promise.resolve();
									},
								},
								{
									validator: (rule, value) => {
										if (value > pocket.limit) {
											return Promise.reject("Melebihi limit harian!");
										}

										return Promise.resolve();
									},
								},
							]}>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								step={1000}
								formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value.replace(/Rp\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Form>
				)}
			</Modal>
		</>
	);
}
