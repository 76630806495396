/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ExclamationCircleFilled,
  FileExcelOutlined,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  App,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  deleteTeacher,
  requestGetTeacherList,
} from "../../../services/admin.service";
import TeacherFormModal from "./components/TeacherFormModal";
import dayjs from "dayjs";
import TeacherImportFormModal from "./components/TeacherImportFormModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "NIP",
    dataIndex: "number",
    key: "number",
    sorter: true,
    render: (text, record) => {
      return (
        <Typography.Text
          style={{
            fontFamily: "monospace",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
          copyable
        >
          {text}
        </Typography.Text>
      );
    },
  },
  {
    title: "Nama",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "TTL",
    dataIndex: "birthDate",
    key: "birthDate",
  },
  {
    title: "Jenis Kelamin",
    dataIndex: "gender",
    key: "gender",
    render: (text, record) => {
      return text === "MALE" ? "Laki-laki" : "Perempuan";
    },
    filters: [
      {
        text: "Laki-laki",
        value: "MALE",
      },
      {
        text: "Perempuan",
        value: "FEMALE",
      },
    ],
  },
  {
    title: "Alamat",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Aksi",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];

const otherButtons = [
  {
    key: "edit",
    label: "Edit",
    icon: <HiOutlinePencil />,
  },
  {
    type: "divider",
  },
  {
    key: "delete",
    label: "Hapus",
    icon: <HiOutlineTrash />,
    danger: true,
  },
];

const TeacherPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { message, notification, modal } = App.useApp();

  useLayoutEffect(() => {
    document.title = "Ruang Admin | Pengajar";
  }, []);

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({
    startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
    endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [sorter, setSorter] = useState({
    name: "asc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow name
      Object.keys(filter).forEach((key) => {
        if (key === "name") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        name: "asc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  const [teachers, setTeachers] = useState([]);
  const [mappedTeachers, setMappedTeachers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [teacher, setTeacher] = useState(null);
  const [isTeacherFormVisible, setTeacherFormVisible] = useState(false);
  const [isTeacherImportFormVisible, setTeacherImportFormVisible] =
    useState(false);

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    fetchTeacherList();
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, []);

  const fetchTeacherList = () => {
    setLoading(true);
    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetTeacherList({
      page: page,
      limit: limit,
      order: tempSorter ?? "name:asc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTeachers(response.data.data);
        updateMappedTeacher(response.data.data);
        setTotal(response.data.pagination.total);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMappedTeacher = (data) => {
    setMappedTeachers(
      data.map((d) => ({
        key: d._id,
        number: d.number,
        name: d.name,
        birthDate: `${d.birthPlace}, ${dayjs(d.birthDate).format("DD MMMM YYYY")}`,
        gender: d.gender,
        address: d.address,
        actions: (
          <Space>
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: (e) => {
                  if (e.key === "edit") {
                    handleEditButton(d);
                  } else if (e.key === "delete") {
                    modal.confirm({
                      title: "Sebentar",
                      icon: <ExclamationCircleFilled />,
                      content:
                        "Anda yakin akan menghapus pengajar ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
                      okText: "Ya",
                      okType: "danger",
                      cancelText: "Tidak",
                      onOk() {
                        handleDeleteButton(d._id);
                      },
                      onCancel() {},
                    });
                  }
                },
                items: otherButtons,
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        ),
      })),
    );
  };

  const handleAddButton = () => {
    setTeacherFormVisible(true);
  };

  const handleImportButton = () => {
    setTeacherImportFormVisible(true);
  };

  const handleEditButton = (teacher) => {
    setTeacher(teacher);
    setTeacherFormVisible(true);
  };

  const handleDeleteButton = (id) => {
    setLoading(true);
    deleteTeacher(id)
      .then((response) => {
        if (response.data.code === 200) {
          message.success(response.data.message);

          fetchTeacherList();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseTeacherForm = () => {
    setTeacher(null);
    setTeacherFormVisible(false);
  };

  const handleOnSuccessTeacherForm = () => {
    setTeacher(null);
    setTeacherFormVisible(false);
    fetchTeacherList();
  };

  const handleOnCloseTeacherImportForm = () => {
    setTeacherImportFormVisible(false);
  };

  const handleOnSuccessTeacherImportForm = () => {
    setTeacherImportFormVisible(false);
    fetchTeacherList();
  };

  return (
    <>
      <TeacherFormModal
        isLoading={isLoading}
        isVisible={isTeacherFormVisible}
        onLoading={(v) => setLoading(v)}
        teacher={teacher}
        onClose={handleOnCloseTeacherForm}
        onSuccess={handleOnSuccessTeacherForm}
      />
      <TeacherImportFormModal
        isLoading={isLoading}
        isVisible={isTeacherImportFormVisible}
        onLoading={(v) => setLoading(v)}
        onClose={handleOnCloseTeacherImportForm}
        onSuccess={handleOnSuccessTeacherImportForm}
      />
      <Card
        bordered={false}
        title="Daftar Pengajar"
        extra={
          <Space>
            <SearchInput
              placeholder="NIP atau nama pengajar"
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
            <Button
              icon={<FileExcelOutlined />}
              type="primary"
              onClick={handleImportButton}
            >
              <span className="ant-btn-text">Impor</span>
            </Button>
            <Button
              icon={<PlusCircleFilled />}
              type="primary"
              onClick={handleAddButton}
            >
              <span className="ant-btn-text">Tambah</span>
            </Button>
          </Space>
        }
      >
        <div>
          <Table
            bordered
            columns={columns}
            loading={isLoading}
            dataSource={mappedTeachers}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onChange={handlePaginate}
          />
        </div>
      </Card>
    </>
  );
};

export default TeacherPage;
