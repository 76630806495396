import {
  Alert,
  Button,
  Card,
  Col,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  HiAcademicCap,
  HiArrowDown,
  HiBriefcase,
  HiChartBar,
} from "react-icons/hi2";
import { useEffect, useLayoutEffect, useState } from "react";
import { requestShowSchool } from "../../../services/admin.service";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

function DashboardPage() {
  useLayoutEffect(() => {
    document.title = "Ruang Admin | Dasbor";
  }, []);

  const { Title } = Typography;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);

  const count = [
    {
      today: "Siswa",
      title: "N/A",
      persent: "",
      icon: HiAcademicCap,
      bnb: "bnb2",
    },
    {
      today: "Pengajar",
      title: "N/A",
      persent: "",
      icon: HiBriefcase,
      bnb: "bnb2",
    },
    {
      today: "Kelas",
      title: "N/A",
      persent: "",
      icon: HiChartBar,
      bnb: "bnb2",
    },
    {
      today: "Uang masuk",
      title: "N/A",
      persent: "",
      icon: HiArrowDown,
      bnb: "redtext",
    },
  ];

  useEffect(() => {
    fetchSchool();
  }, []);

  const fetchSchool = () => {
    setLoading(true);
    requestShowSchool({
      join: "students",
    })
      .then((response) => {
        setSchool(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 0]}>
        {count.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="circlebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.today}</span>
                    <Title level={3}>
                      {c.title} <small className={c.bnb}>{c.persent}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <c.icon />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      {school && (
        <Space
          direction="vertical"
          size="middle"
          style={{
            marginTop: 16,
            width: "100%",
          }}
        >
          {dayjs(school.expiredAt).diff(dayjs(), "day") < 30 && (
            <Alert
              key="school-expired"
              message="Peringatan!"
              description={
                <Typography.Text>
                  Masa aktif akun sekolah akan berakhir dalam{" "}
                  <strong>
                    {dayjs(school.expiredAt).diff(dayjs(), "day")}
                  </strong>{" "}
                  hari, segera bayar tagihan untuk memperpanjang masa aktif.
                </Typography.Text>
              }
              type="error"
              showIcon
              action={
                <Button
                  type="primary"
                  size="small"
                  onClick={() => navigate("/admin/settings")}
                >
                  Menuju ke pengaturan
                </Button>
              }
            />
          )}
          {school.quotas
            .filter(
              (quota) =>
                quota.used >= quota.value * 0.8 && quota.used < quota.value,
            )
            .map((quota) => {
              return (
                <Alert
                  key={quota.key}
                  message="Peringatan!"
                  description={`Kuota ${quota.name} sudah mencapai ${quota.used} dari ${quota.value}`}
                  type="warning"
                  showIcon
                  action={
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => navigate("/admin/settings")}
                    >
                      Menuju ke pengaturan
                    </Button>
                  }
                />
              );
            })}
          {school.quotas
            .filter((quota) => quota.used >= quota.value)
            .map((quota) => {
              return (
                <Alert
                  key={quota.key}
                  message="Peringatan!"
                  description={
                    <Typography.Text>
                      Penggunaan kuota <strong>{quota.name}</strong> sudah
                      mencapai <strong>{quota.used}</strong> dari batas maksimal{" "}
                      <strong>{quota.value}</strong>, hubungi admin untuk
                      menambah kuota atau akun sekolah akan dinonaktifkan.
                    </Typography.Text>
                  }
                  type="error"
                  showIcon
                  action={
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => navigate("/admin/settings")}
                    >
                      Menuju ke pengaturan
                    </Button>
                  }
                />
              );
            })}
        </Space>
      )}
    </Spin>
  );
}

export default DashboardPage;
