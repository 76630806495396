/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Form,
  message,
  Modal,
  notification,
  Popconfirm,
  Spin,
} from "antd";
import React from "react";
import { importStudentList } from "../../../../services/admin.service";
import Dragger from "antd/lib/upload/Dragger";
import { HiInbox } from "react-icons/hi2";
import dayjs from "dayjs";

const StudentImportFormModal = ({
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [file, setFile] = React.useState(null);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);

        const formData = new FormData();
        formData.append("file", file);

        importStudentList(formData)
          .then((response) => {
            message.success(response.data.message);

            form.resetFields();
            onSuccess();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              notification.error({
                message: "Kesalahan!",
                description: error.response.data.message,
              });
            } else {
              notification.error({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            onLoading(false);
          });
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      form.setFieldsValue({
        file: [file],
      });
      setFile(file);
    };

    // then upload `file` from the argument manually
    return false;
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={"Impor Siswa"}
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan mengunggah file excel untuk diimport?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">{"Unggah"}</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="file"
              label="File Excel"
              required
              tooltip="Unggah file excel yang telah diisi dengan data siswa"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "File excel harus diunggah",
                },
              ]}
              getValueFromEvent={normFile}
            >
              <Dragger
                beforeUpload={beforeUpload}
                style={{ width: "100%" }}
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <HiInbox
                    style={{
                      fontSize: 48,
                      color: "#1890ff",
                    }}
                  />
                </p>
                <p className="ant-upload-text">
                  Klik atau seret file ke area ini untuk mengunggah
                </p>
              </Dragger>
              <p
                className="ant-upload-hint"
                style={{ marginTop: 8, marginBottom: 0 }}
              >
                Untuk contoh file excel yang telah diisi dengan data siswa, klik{" "}
                <a
                  href={`${process.env.PUBLIC_URL}/uploads/import-students-20241001.xlsx`}
                  download={`import-students-template-20241001-${dayjs().format(
                    "YYYYMMDDHHmmss",
                  )}.xlsx`}
                  target="_blank"
                  rel="noreferrer"
                >
                  disini
                </a>
              </p>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default StudentImportFormModal;
