/* eslint-disable no-unused-vars */
import {
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	message,
	Modal,
	notification,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
	requestGetPocketTransactionList,
	requestShowPocket,
	requestUpdatePocketTransactionStatus,
} from "../../../../services/admin.service";
import { HiArrowDown, HiArrowUp, HiOutlineCheck, HiOutlineXMark } from "react-icons/hi2";
import PocketDepositForm from "./PocketDepositForm";
import PocketWithdrawalFormModal from "./PocketWithdrawalFormModal";
import { MoreOutlined } from "@ant-design/icons";

export default function PocketDetailModal({ isVisible, id, onClose }) {
	const [isLoading, setLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [mappedTransactions, setMappedTransactions] = useState([]);
	const [pocket, setPocket] = useState(null);

	const [isWithdrawVisible, setWithdrawVisible] = useState(false);
	const [isDepositFormVisible, setDepositFormVisible] = useState(false);

	useEffect(() => {
		if (isVisible && id) {
			fetchPocket();
		} else {
			setPocket(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, id]);

	const fetchPocket = async () => {
		setLoading(true);

		requestShowPocket(id)
			.then((response) => {
				setPocket(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		if (isVisible && pocket) {
			fetchTransactions();
		} else {
			setTransactions([]);
			setMappedTransactions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword, filter, page, limit, isVisible, pocket]);

	const fetchTransactions = async () => {
		setLoading(true);

		requestGetPocketTransactionList({
			page: page,
			limit: limit,
			order: "createdAt:desc",
			filter: `pocket:${pocket._id}`,
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setTransactions(response.data.data);
				setMappedTransactions(
					response.data.data.map((transaction) => ({
						...transaction,
						key: transaction._id,
					}))
				);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		setTransactions([]);
		setMappedTransactions([]);
		setDepositFormVisible(false);
		onClose();
	};

	const handleWithdrawSuccess = () => {
		fetchPocket();
		fetchTransactions();
		setWithdrawVisible(false);
	};

	const handleWithdrawClose = () => {
		setWithdrawVisible(false);
	};

	const handleConfirmButton = (id) => {
		setLoading(true);
		requestUpdatePocketTransactionStatus({
			ids: [id],
			status: "COMPLETED",
		})
			.then((response) => {
				message.success(response.data.message);

				fetchPocket();
				fetchTransactions();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleVoidButton = (id) => {
		setLoading(true);
		requestUpdatePocketTransactionStatus({
			ids: [id],
			status: "VOID",
		})
			.then((response) => {
				message.success(response.data.message);

				fetchPocket();
				fetchTransactions();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<PocketWithdrawalFormModal
				isVisible={isWithdrawVisible}
				pocket={pocket}
				onSuccess={handleWithdrawSuccess}
				onClose={handleWithdrawClose}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title="Detail Tabungan (Pocket)"
				open={isVisible}
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{pocket && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col
								span={8}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Tabungan" size="small">
										<Descriptions
											size="small"
											style={{ width: "100%" }}
											column={2}
											layout="vertical">
											<Descriptions.Item label="Tipe">
												<Tag
													style={{ fontWeight: "bold" }}
													color={pocket.type === "SMARTIVA" ? "gold" : "default"}>
													{pocket.type === "SMARTIVA" ? "SMARTIVA" : "SEKOLAH"}
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item label="Nomor">
												{pocket.number.match(/.{1,4}/g).join("-")}
											</Descriptions.Item>
											<Descriptions.Item label="Saldo">
												{Intl.NumberFormat("id-ID", {
													style: "currency",
													currency: "IDR",
													minimumFractionDigits: 0,
												}).format(pocket.balance)}
											</Descriptions.Item>
											<Descriptions.Item label="Limit Harian">
												{pocket.limit
													? Intl.NumberFormat("id-ID", {
															style: "currency",
															currency: "IDR",
															minimumFractionDigits: 0,
													  }).format(pocket.limit)
													: "Tak terbatas"}
											</Descriptions.Item>
											<Descriptions.Item label="Nama">{pocket.name}</Descriptions.Item>
											<Descriptions.Item label="NISN">{pocket.student?.number}</Descriptions.Item>
										</Descriptions>
									</Card>
									{isDepositFormVisible && (
										<PocketDepositForm
											pocket={pocket}
											onSuccess={() => {
												setDepositFormVisible(false);
												fetchPocket();
												fetchTransactions();
											}}
											onClose={() => setDepositFormVisible(false)}
										/>
									)}
									<Space direction="horizontal" size="small">
										<Button
											type="primary"
											disabled={pocket.type === "SMARTIVA"}
											style={{ width: "100%" }}
											onClick={() => {
												setDepositFormVisible(true);
											}}>
											Deposit
										</Button>
										<Button
											type="default"
											style={{ width: "100%" }}
											onClick={() => {
												setWithdrawVisible(true);
											}}>
											Tarik Tunai
										</Button>
									</Space>
									{pocket.type === "SMARTIVA" && (
										<Typography.Text type="secondary">
											* Fitur deposit manual melalui admin hanya untuk{" "}
											<strong>Tabungan Sekolah</strong>. Sedangkan{" "}
											<strong>Tabungan Smartiva</strong> dapat ditop-up secara instan melalui
											aplikasi Smartiva.
										</Typography.Text>
									)}
								</Space>
							</Col>
							<Col
								span={16}
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Transaksi Terakhir" size="small">
										<Table
											size="small"
											columns={[
												{
													title: "Tanggal",
													dataIndex: "createdAt",
													key: "createdAt",
													render: (createdAt) => dayjs(createdAt).format("DD MMMM YYYY HH:mm:ss"),
												},
												{
													title: "Kategori",
													dataIndex: "category",
													key: "category",
													render: (category, record) => (
														<Space direction="horizontal" size="small">
															{record?.type === "INCOME" ? (
																<Tag color="success">
																	<HiArrowDown />
																</Tag>
															) : (
																<Tag color="error">
																	<HiArrowUp />
																</Tag>
															)}
															<Typography.Text>{category}</Typography.Text>
															{record?.status === "CREATED" ? (
																<Tag color="processing" style={{ fontWeight: "bold" }}>
																	Proses
																</Tag>
															) : record?.status === "COMPLETED" ? (
																<Tag color="success" style={{ fontWeight: "bold" }}>
																	Selesai
																</Tag>
															) : record?.status === "FAILED" ? (
																<Tag color="error" style={{ fontWeight: "bold" }}>
																	Gagal
																</Tag>
															) : (
																<Tag color="default" style={{ fontWeight: "bold" }}>
																	Tidak sah
																</Tag>
															)}
														</Space>
													),
												},
												{
													title: "Jumlah",
													dataIndex: "amount",
													key: "amount",
													render: (amount) =>
														Intl.NumberFormat("id-ID", {
															style: "currency",
															currency: "IDR",
															minimumFractionDigits: 0,
														}).format(amount),
												},
												{
													title: "Aksi",
													dataIndex: "actions",
													key: "actions",
													align: "right",
													render: (_, record) => (
														<Space>
															<Dropdown
																trigger={["click"]}
																menu={{
																	onClick: (e) => {
																		if (e.key === "confirm") {
																			handleConfirmButton(record._id);
																		} else if (e.key === "void") {
																			handleVoidButton(record._id);
																		}
																	},
																	items: [
																		{
																			key: "confirm",
																			label: "Konfirmasi",
																			disabled: record.status !== "CREATED",
																			icon: <HiOutlineCheck />,
																		},
																		{
																			key: "void",
																			label: "Batalkan",
																			disabled: record.status !== "CREATED",
																			icon: <HiOutlineXMark />,
																		},
																	],
																}}
																placement="bottomRight"
																arrow>
																<Button
																	type="default"
																	shape="circle"
																	size="small"
																	icon={<MoreOutlined />}
																/>
															</Dropdown>
														</Space>
													),
												},
											]}
											dataSource={mappedTransactions}
											loading={isLoading}
											bordered
											pagination={{
												current: page,
												total: total,
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
											onChange={handlePaginate}
										/>
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
}
