/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Select,
	Spin,
} from "antd";
import { requestAddTransaction } from "../../../../services/admin.service";

const InvoicePaymentFormModal = ({
	invoice,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				values.type = "INCOME";
				values.category = "TUITION";
				values.referenceId = invoice._id;
				values.isCompleted = true;

				requestAddTransaction(values)
					.then((response) => {
						message.success(response.data.message);
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={`Tambah Pembayaran #${invoice?.number}`}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Anda yakin akan menambah pembayaran?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="amount"
							label="Jumlah"
							required
							rules={[{ required: true, message: "Jumlah pembayaran wajib diisi" }]}
							tooltip="Masukkan amount pembayaran">
							<InputNumber addonBefore="Rp" controls={false} min={0} style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="channel"
							label="Metode Pembayaran"
							required
							rules={[{ required: true, message: "Pilih metode pembayaran" }]}
							tooltip="Pilih metode pembayaran">
							<Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
								<Select.Option value="CASH">Cash</Select.Option>
							</Select>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default InvoicePaymentFormModal;
