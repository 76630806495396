import { Button, Form, Input, message, Modal, notification, Popconfirm, Select, Spin } from "antd";
import { requestAddExam } from "../../../../services/admin.service";
import { useEffect, useState } from "react";

const ExamFormModal = ({ isVisible, isLoading, onLoading, onClose, onSuccess, subjects }) => {
	const [form] = Form.useForm();

	const [subjectsOptions, setSubjectsOptions] = useState([]);

	useEffect(() => {
		if (subjects) {
			setSubjectsOptions(
				subjects?.map((subject) => {
					return {
						label: subject.name,
						value: subject._id,
					};
				})
			);
		}
	}, [subjects]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				values.blueprinted = true;

				requestAddExam(values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={"Tambah Bank Soal"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data bank soal?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[{ required: true, message: "Nama ujian harus diisi!" }]}
							tooltip="Masukkan nama bank soal">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							label="Deskripsi"
							tooltip="Masukkan deskripsi ujian">
							<Input.TextArea style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="subjectId"
							label="Mata Pelajaran"
							tooltip="Pilih mata pelajaran">
							<Select
								style={{ width: "100%" }}
								placeholder="Pilih mata pelajaran"
								options={subjectsOptions}
							/>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="grade"
							label="Tingkat"
							tooltip="Pilih tingkat">
							<Select
								style={{ width: "100%" }}
								placeholder="Pilih tingkat"
								options={[
									{
										label: "1 SD",
										value: 1,
									},
									{
										label: "2 SD",
										value: 2,
									},
									{
										label: "3 SD",
										value: 3,
									},
									{
										label: "4 SD",
										value: 4,
									},
									{
										label: "5 SD",
										value: 5,
									},
									{
										label: "6 SD",
										value: 6,
									},
									{
										label: "7 SMP",
										value: 7,
									},
									{
										label: "8 SMP",
										value: 8,
									},
									{
										label: "9 SMP",
										value: 9,
									},
									{
										label: "10 SMA",
										value: 10,
									},
									{
										label: "11 SMA",
										value: 11,
									},
									{
										label: "12 SMA",
										value: 12,
									},
								]}
							/>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default ExamFormModal;
