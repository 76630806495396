/* eslint-disable no-unused-vars */
import {
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	message,
	Modal,
	notification,
	Popconfirm,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
	requestAcceptStoreTransaction,
	requestGetStoreTransactionList,
	requestRejectStoreTransaction,
	requestShowStore,
	requestUnassignMerchantFromStore,
} from "../../../../services/admin.service";
// import StoreWithdrawalFormModal from "./StoreWithdrawalFormModal";
import { CloseCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import StoreAssignmentFormModal from "./StoreAssignmentFormModal";
import StoreWithdrawalFormModal from "./StoreWithdrawalFormModal";
import {
	PiArrowDownDuotone,
	PiArrowUpDuotone,
	PiCheckCircleDuotone,
	PiXCircleDuotone,
} from "react-icons/pi";

export default function StoreDetailModal({ open, id, onClose }) {
	const [isLoading, setLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [mappedTransactions, setMappedTransactions] = useState([]);
	const [store, setStore] = useState(null);

	const [storeAssignmentOpen, setStoreAssignmentOpen] = useState(false);
	const [withdrawOpen, setWithdrawOpen] = useState(false);

	useEffect(() => {
		if (open && id) {
			fetchStore();
		} else {
			setStore(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, id]);

	const fetchStore = async () => {
		setLoading(true);

		requestShowStore(id)
			.then((response) => {
				setStore(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		if (open && store) {
			fetchTransactions();
		} else {
			setTransactions([]);
			setMappedTransactions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword, filter, page, limit, open, store]);

	const fetchTransactions = async () => {
		setLoading(true);

		requestGetStoreTransactionList({
			page: page,
			limit: limit,
			order: "date:desc",
			filter: `store:${store._id}`,
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setTransactions(response.data.data);
				setMappedTransactions(
					response.data.data.map((transaction) => ({
						...transaction,
						key: transaction._id,
					}))
				);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		setTransactions([]);
		setMappedTransactions([]);
		onClose();
	};

	const handleWithdrawSuccess = () => {
		fetchStore();
		fetchTransactions();
		setWithdrawOpen(false);
	};

	const handleWithdrawClose = () => {
		setWithdrawOpen(false);
	};

	return (
		<>
			<StoreWithdrawalFormModal
				open={withdrawOpen}
				store={store}
				onSuccess={handleWithdrawSuccess}
				onClose={handleWithdrawClose}
			/>
			<StoreAssignmentFormModal
				open={storeAssignmentOpen}
				store={store}
				onClose={() => {
					setStoreAssignmentOpen(false);
				}}
				onSuccess={() => {
					setStoreAssignmentOpen(false);
					fetchStore();
				}}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={store ? store.name : "Detail Toko"}
				open={open}
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{store && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col
								span={8}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Toko" size="small">
										<Descriptions
											size="small"
											style={{ width: "100%" }}
											column={2}
											layout="vertical">
											<Descriptions.Item label="Nama">{store.name}</Descriptions.Item>
											<Descriptions.Item label="Deskripsi">{store.description}</Descriptions.Item>
											<Descriptions.Item label="Saldo">
												{Intl.NumberFormat("id-ID", {
													style: "currency",
													currency: "IDR",
													minimumFractionDigits: 0,
												}).format(store.balance)}
											</Descriptions.Item>
											<Descriptions.Item label="Penjual">
												{/* wrap */}
												<Space wrap>
													{store.merchants.length > 0 &&
														store.merchants.map((merchant, index) => {
															return (
																<span key={index}>
																	{merchant.name}
																	<Popconfirm
																		placement="topRight"
																		title={`Anda yakin akan mencopot ${merchant.name} dari ${store.name}?`}
																		onConfirm={(e) => {
																			requestUnassignMerchantFromStore(store._id, merchant.email)
																				.then((response) => {
																					message.success(response.data.message);

																					fetchStore();
																				})
																				.catch((error) => {
																					if (error.response && error.response.data) {
																						notification["error"]({
																							message: "Kesalahan!",
																							description: error.response.data.message,
																						});
																					} else {
																						notification["error"]({
																							message: "Kesalahan!",
																							description: error.message,
																						});
																					}
																				});
																		}}
																		okText="Yes"
																		cancelText="No">
																		<Button
																			type="link"
																			shape="default"
																			size="small"
																			icon={<CloseCircleFilled />}
																			danger
																		/>
																	</Popconfirm>
																</span>
															);
														})}
													<Button
														type="dashed"
														size="small"
														onClick={() => {
															setStoreAssignmentOpen(true);
														}}>
														<PlusCircleFilled /> Tambah
													</Button>
												</Space>
											</Descriptions.Item>
										</Descriptions>
									</Card>
									<div style={{ width: "100%" }}>
										<Button
											type="primary"
											style={{ width: "100%" }}
											onClick={() => {
												setWithdrawOpen(true);
											}}>
											Tarik Tunai
										</Button>
									</div>
								</Space>
							</Col>
							<Col
								span={16}
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Transaksi Terakhir" size="small">
										<Table
											size="small"
											columns={[
												{
													title: "Tanggal",
													dataIndex: "date",
													key: "date",
													render: (date) => dayjs(date).format("DD MMMM YYYY HH:mm:ss"),
												},
												{
													title: "Kategori",
													dataIndex: "category",
													key: "category",
													render: (category, record) => (
														<Space direction="horizontal" size="small">
															{record?.type === "INCOME" ? (
																<Tag color="success">
																	<PiArrowDownDuotone />
																</Tag>
															) : (
																<Tag color="error">
																	<PiArrowUpDuotone />
																</Tag>
															)}
															<Typography.Text>{category}</Typography.Text>
															{record?.status === "CREATED" ? (
																<Tag color="processing" style={{ fontWeight: "bold" }}>
																	Proses
																</Tag>
															) : record?.status === "COMPLETED" ? (
																<Tag color="success" style={{ fontWeight: "bold" }}>
																	Selesai
																</Tag>
															) : record?.status === "FAILED" ? (
																<Tag color="error" style={{ fontWeight: "bold" }}>
																	Gagal
																</Tag>
															) : (
																<Tag color="default" style={{ fontWeight: "bold" }}>
																	Tidak sah
																</Tag>
															)}
														</Space>
													),
												},
												{
													title: "Jumlah",
													dataIndex: "amount",
													key: "amount",
													render: (amount) =>
														Intl.NumberFormat("id-ID", {
															style: "currency",
															currency: "IDR",
															minimumFractionDigits: 0,
														}).format(amount),
												},
												{
													title: "Aksi",
													dataIndex: "actions",
													key: "actions",
													align: "right",
													render: (_, record) => (
														<Space>
															<Dropdown
																trigger={["click"]}
																menu={{
																	onClick: (e) => {
																		if (e.key === "accept") {
																			requestAcceptStoreTransaction(record._id)
																				.then((response) => {
																					message.success(response.data.message);

																					fetchStore();
																					fetchTransactions();
																				})
																				.catch((error) => {
																					if (error.response && error.response.data) {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.response.data.message,
																						});
																					} else {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.message,
																						});
																					}
																				});
																		} else if (e.key === "reject") {
																			requestRejectStoreTransaction(record._id)
																				.then((response) => {
																					message.success(response.data.message);

																					fetchStore();
																					fetchTransactions();
																				})
																				.catch((error) => {
																					if (error.response && error.response.data) {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.response.data.message,
																						});
																					} else {
																						notification.error({
																							message: "Kesalahan!",
																							description: error.message,
																						});
																					}
																				});
																		}
																	},
																	items:
																		record.category === "WITHDRAWAL"
																			? [
																					{
																						key: "accept",
																						label: "Terima permintaan",
																						disabled: record.status !== "CREATED",
																						icon: <PiCheckCircleDuotone />,
																					},
																					{
																						key: "reject",
																						label: "Tolak permintaan",
																						disabled: record.status !== "CREATED",
																						icon: <PiXCircleDuotone />,
																					},
																			  ]
																			: [],
																}}
																placement="bottomRight"
																arrow>
																<Button
																	type="default"
																	shape="circle"
																	size="small"
																	icon={<MoreOutlined />}
																/>
															</Dropdown>
														</Space>
													),
												},
											]}
											dataSource={mappedTransactions}
											loading={isLoading}
											bordered
											pagination={{
												current: page,
												total: total,
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
											onChange={handlePaginate}
										/>
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
}
