import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useLayoutEffect } from "react";
import SearchInput from "../../components/SearchInput";
import SchoolForm from "./components/SchoolForm";
import {
  requestEditTeacher,
  requestGetBillList,
  requestGetModuleList,
  requestGetTeacherList,
  requestPrintBill,
  requestShowSchool,
  requestUpdateSchool,
} from "../../services/admin.service";
import SchoolQuotaProgressBar from "./components/SchoolQuotaProgressBar";
import { format } from "../../utils/Formatter";
import {
  DeleteFilled,
  FilePdfFilled,
  SaveFilled,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import SchoolBillPaymentFormModal from "./components/SchoolBillPaymentFormModal";

const SettingPage = () => {
  useLayoutEffect(() => {
    document.title = "Pengaturan";
  }, []);

  const [headmasterForm] = Form.useForm();
  const [bankAccountForm] = Form.useForm();
  const [teacherForm] = Form.useForm();

  const [isLoading, setLoading] = React.useState(false);
  const [isPrinting, setPrinting] = React.useState(false);
  const [modules, setModules] = React.useState([]);
  const [school, setSchool] = React.useState({});
  const [teachers, setTeachers] = React.useState([]);
  const [isAddBankAccount, setIsAddBankAccount] = React.useState(false);
  const [signatureList, setSignatureList] = React.useState([]);

  const [bills, setBills] = React.useState([]);
  const [bill, setBill] = React.useState(null);
  const [isPayBillModalVisible, setIsPayBillModalVisible] =
    React.useState(false);

  useEffect(() => {
    fetchModuleList();
    fetchSchool();
    fetchTeacherList();
    fetchBillList();
  }, []);

  const fetchSchool = () => {
    setLoading(true);
    requestShowSchool({
      join: "students",
    })
      .then((response) => {
        setSchool(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchModuleList = () => {
    requestGetModuleList({})
      .then((response) => {
        setModules(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTeacherList = () => {
    setLoading(true);
    requestGetTeacherList({ page: 1, limit: 1000 })
      .then((response) => {
        setTeachers(response.data.data);
      })

      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBillList = () => {
    setLoading(true);

    requestGetBillList({
      page: 1,
      limit: 1000,
      order: "issuedAt:desc",
    })
      .then((response) => {
        setBills(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = () => {};

  const handleSubmitBankAccount = () => {
    bankAccountForm
      .validateFields()
      .then((values) => {
        setLoading(true);
        requestUpdateSchool({
          bankAccounts: [...school.bankAccounts, values],
        })
          .then((response) => {
            setSchool(response.data.data);
            setIsAddBankAccount(false);
            bankAccountForm.resetFields();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteBankAccount = (bankAccount) => {
    setLoading(true);
    requestUpdateSchool({
      bankAccounts: school.bankAccounts.filter(
        (item) => item._id !== bankAccount._id,
      ),
    })
      .then((response) => {
        setSchool(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayButton = (billId) => {
    const bill = bills.find((bill) => bill._id === billId);
    setBill(bill);
    setIsPayBillModalVisible(true);
  };

  const handlePrintButton = (billId) => {
    setPrinting(true);
    requestPrintBill(billId)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data;
        link.target = "_blank";
        link.setAttribute("href", response.data.data);
        link.setAttribute("download", `Tagihan-${billId}.pdf`);
        link.click();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setPrinting(false);
      });
  };

  return (
    <>
      <SchoolBillPaymentFormModal
        open={isPayBillModalVisible}
        bill={bill}
        onClose={() => {
          setBill(null);
          setIsPayBillModalVisible(false);
        }}
        onSuccess={() => {
          setBill(null);
          setIsPayBillModalVisible(false);
          fetchBillList();
        }}
      />
      <Spin
        style={{
          width: "100%",
          height: "100%",
        }}
        spinning={isLoading}
      >
        {school && school.quotas && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  width: "100%",
                }}
              >
                {dayjs(school.expiredAt).diff(dayjs(), "day") < 30 && (
                  <Alert
                    key="school-expired"
                    message="Peringatan!"
                    description={
                      <Typography.Text>
                        Masa aktif akun sekolah akan berakhir dalam{" "}
                        <strong>
                          {dayjs(school.expiredAt).diff(dayjs(), "day")}
                        </strong>{" "}
                        hari, segera bayar tagihan untuk memperpanjang masa
                        aktif.
                      </Typography.Text>
                    }
                    type="error"
                    showIcon
                    action={
                      bills.length > 0 && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() =>
                            handlePayButton(
                              bills.find((bill) => bill.status === "CREATED")
                                ._id,
                            )
                          }
                        >
                          Bayar Tagihan
                        </Button>
                      )
                    }
                  />
                )}
                {school.quotas
                  .filter(
                    (quota) =>
                      quota.used >= quota.value * 0.8 &&
                      quota.used < quota.value,
                  )
                  .map((quota) => {
                    return (
                      <Alert
                        key={quota.key}
                        message="Peringatan!"
                        description={`Kuota ${quota.name} sudah mencapai ${quota.used} dari ${quota.value}`}
                        type="warning"
                        showIcon
                      />
                    );
                  })}
                {school.quotas
                  .filter((quota) => quota.used >= quota.value)
                  .map((quota) => {
                    return (
                      <Alert
                        key={quota.key}
                        message="Peringatan!"
                        description={
                          <Typography.Text>
                            Penggunaan kuota <strong>{quota.name}</strong> sudah
                            mencapai <strong>{quota.used}</strong> dari batas
                            maksimal <strong>{quota.value}</strong>, hubungi
                            admin untuk menambah kuota atau akun sekolah akan
                            dinonaktifkan.
                          </Typography.Text>
                        }
                        type="error"
                        showIcon
                      />
                    );
                  })}
              </Space>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  width: "100%",
                }}
              >
                <Card
                  bordered={false}
                  title="Umum"
                  extra={
                    <Space>
                      <SearchInput onSearch={handleSearch} />
                    </Space>
                  }
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <SchoolForm school={school} />
                  </div>
                </Card>

                <Card bordered={false} title="Kepala Sekolah">
                  {school.headmaster ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Form form={teacherForm}>
                        <Descriptions size="small" column={1} bordered>
                          <Descriptions.Item label="Nama">
                            <Form.Item
                              name="name"
                              initialValue={school.headmaster.name}
                              required
                              style={{
                                marginBottom: 0,
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Nama harus diisi!",
                                },
                              ]}
                            >
                              <Input
                                size="small"
                                variant="borderless"
                                placeholder="Nama Kepala Sekolah"
                              />
                            </Form.Item>
                          </Descriptions.Item>
                          <Descriptions.Item label="NIP">
                            <Form.Item
                              name="number"
                              initialValue={school.headmaster.number}
                              required
                              style={{
                                marginBottom: 0,
                              }}
                              rules={[
                                { required: true, message: "NIP harus diisi!" },
                              ]}
                            >
                              <Input
                                size="small"
                                variant="borderless"
                                placeholder="NIP Kepala Sekolah"
                              />
                            </Form.Item>
                          </Descriptions.Item>
                          <Descriptions.Item label="TTD">
                            {school.headmaster.signatureId && (
                              <img
                                src={school.headmaster.signature.url}
                                alt="signature"
                                style={{
                                  width: 120,
                                }}
                              />
                            )}
                            <Form.Item
                              name="signature"
                              style={{
                                marginTop: 12,
                                marginBottom: 0,
                              }}
                            >
                              <Upload
                                onRemove={(file) => {
                                  const index = signatureList.indexOf(file);
                                  const newFileList = signatureList.slice();
                                  newFileList.splice(index, 1);
                                  setSignatureList(newFileList);
                                }}
                                beforeUpload={(file) => {
                                  setSignatureList([...signatureList, file]);
                                  return false;
                                }}
                                maxCount={1}
                              >
                                <Button type="dashed" icon={<UploadOutlined />}>
                                  Klik untuk pilih ttd baru
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Descriptions.Item>
                        </Descriptions>
                        <div
                          style={{
                            textAlign: "right",
                            marginTop: 12,
                          }}
                        >
                          <Button
                            loading={isLoading}
                            type="primary"
                            onClick={() => {
                              teacherForm.validateFields().then((values) => {
                                setLoading(true);

                                const formData = new FormData();
                                formData.append("name", values.name);
                                formData.append("number", values.number);

                                if (signatureList.length > 0) {
                                  formData.append(
                                    "signature",
                                    signatureList[0],
                                  );
                                }

                                requestEditTeacher(
                                  school.headmasterId,
                                  formData,
                                )
                                  .then((response) => {
                                    message.success(
                                      "Kepala Sekolah berhasil diubah!",
                                    );
                                    setSignatureList([]);
                                    fetchSchool();
                                  })
                                  .catch((error) => {
                                    if (error.response && error.response.data) {
                                      notification.error({
                                        message: "Kesalahan!",
                                        description:
                                          error.response.data.message,
                                      });
                                    } else {
                                      notification.error({
                                        message: "Kesalahan!",
                                        description: error.message,
                                      });
                                    }
                                  })
                                  .finally(() => {
                                    setLoading(false);
                                  });
                              });
                            }}
                          >
                            Simpan
                          </Button>
                        </div>
                      </Form>
                      <Divider />
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size="middle"
                      >
                        <Form
                          form={headmasterForm}
                          onFinish={() => {}}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            name="headmasterId"
                            label="Ubah Kepala Sekolah"
                            required
                            style={{
                              width: "100%",
                              marginBottom: 0,
                            }}
                            rules={[
                              { required: true, message: "Pilih pengajar!" },
                            ]}
                            tooltip="Pilih pengajar untuk dijadikan kepala sekolah"
                          >
                            <Select
                              placeholder="Pilih Pengajar"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {teachers.map((teacher) => (
                                <Select.Option
                                  key={teacher._id}
                                  value={teacher._id}
                                >
                                  {`[${teacher.number}] ${teacher.name}`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Form>
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Button
                            loading={isLoading}
                            type="primary"
                            onClick={() => {
                              headmasterForm.validateFields().then((values) => {
                                setLoading(true);
                                requestUpdateSchool({
                                  headmasterId: values.headmasterId,
                                })
                                  .then((response) => {
                                    message.success(
                                      "Kepala Sekolah berhasil diubah!",
                                    );
                                    fetchSchool();
                                  })
                                  .catch((error) => {
                                    if (error.response && error.response.data) {
                                      notification.error({
                                        message: "Kesalahan!",
                                        description:
                                          error.response.data.message,
                                      });
                                    } else {
                                      notification.error({
                                        message: "Kesalahan!",
                                        description: error.message,
                                      });
                                    }
                                  })
                                  .finally(() => {
                                    setLoading(false);
                                  });
                              });
                            }}
                          >
                            Simpan
                          </Button>
                        </div>
                      </Space>
                    </div>
                  ) : (
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size="middle"
                    >
                      <Form
                        form={headmasterForm}
                        onFinish={() => {}}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          name="headmasterId"
                          required
                          style={{
                            width: "100%",
                            marginBottom: 0,
                          }}
                          label="Pilih Kepala Sekolah"
                          rules={[
                            { required: true, message: "Pilih pengajar!" },
                          ]}
                          tooltip="Pilih pengajar untuk dijadikan kepala sekolah"
                        >
                          <Select
                            placeholder="Pilih Pengajar"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {teachers.map((teacher) => (
                              <Select.Option
                                key={teacher._id}
                                value={teacher._id}
                              >
                                {`[${teacher.number}] ${teacher.name}`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form>
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Button
                          loading={isLoading}
                          type="primary"
                          onClick={() => {
                            headmasterForm.validateFields().then((values) => {
                              setLoading(true);
                              requestUpdateSchool({
                                headmasterId: values.headmasterId,
                              })
                                .then((response) => {
                                  message.success(
                                    "Kepala Sekolah berhasil diubah!",
                                  );
                                  fetchSchool();
                                })
                                .catch((error) => {
                                  if (error.response && error.response.data) {
                                    notification.error({
                                      message: "Kesalahan!",
                                      description: error.response.data.message,
                                    });
                                  } else {
                                    notification.error({
                                      message: "Kesalahan!",
                                      description: error.message,
                                    });
                                  }
                                })
                                .finally(() => {
                                  setLoading(false);
                                });
                            });
                          }}
                        >
                          Simpan
                        </Button>
                      </div>
                    </Space>
                  )}
                </Card>
                <Card
                  bordered={false}
                  title="Rekening Bank"
                  extra={
                    <Space>
                      <SearchInput onSearch={handleSearch} />
                      <Button
                        type="primary"
                        onClick={() => setIsAddBankAccount(!isAddBankAccount)}
                      >
                        Tambah
                      </Button>
                    </Space>
                  }
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography.Text>
                      <strong>Rekening Bank</strong> dapat digunakan untuk
                      transaksi pembayaran transfer manual.
                    </Typography.Text>
                    <Table
                      style={{
                        marginTop: 16,
                        width: "100%",
                      }}
                      bordered
                      dataSource={school.bankAccounts.map((bankAccount) => ({
                        ...bankAccount,
                        key: bankAccount._id,
                      }))}
                      columns={[
                        {
                          title: "Bank",
                          dataIndex: "bankName",
                          key: "bankName",
                        },
                        {
                          title: "Nomor Rekening",
                          dataIndex: "accountNumber",
                          key: "accountNumber",
                        },
                        {
                          title: "Atas Nama",
                          dataIndex: "accountName",
                          key: "accountName",
                        },
                        {
                          title: "Aksi",
                          key: "action",
                          render: (text, record) => (
                            <Space size="middle">
                              <Button
                                type="dashed"
                                danger
                                size="small"
                                onClick={() => handleDeleteBankAccount(record)}
                              >
                                <DeleteFilled />
                              </Button>
                            </Space>
                          ),
                        },
                      ]}
                      pagination={false}
                      size="small"
                    />
                    {isAddBankAccount && (
                      <div
                        style={{
                          marginTop: 16,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      >
                        <Form
                          form={bankAccountForm}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Row gutter={16}>
                            <Col span={6}>
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                }}
                                name="bankName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Nama Bank harus diisi!",
                                  },
                                ]}
                              >
                                <Input placeholder="Nama Bank" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                }}
                                name="accountNumber"
                                rules={[
                                  {
                                    required: true,
                                    message: "Nomor Rekening harus diisi!",
                                  },
                                ]}
                              >
                                <Input placeholder="Nomor Rekening" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                }}
                                name="accountName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Atas Nama harus diisi!",
                                  },
                                ]}
                              >
                                <Input placeholder="Atas Nama" />
                              </Form.Item>
                            </Col>
                            <Col
                              span={2}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <Button
                                  loading={isLoading}
                                  style={{
                                    padding: 0,
                                    width: "100%",
                                  }}
                                  onClick={handleSubmitBankAccount}
                                  type="primary"
                                >
                                  <SaveFilled />
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                </Card>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  width: "100%",
                }}
              >
                <Card
                  bordered={false}
                  title="Tagihan"
                  extra={
                    <Space>
                      <SearchInput onSearch={handleSearch} />
                    </Space>
                  }
                >
                  {/* bills contain number, issuedAt, dueAt, total, and button to pay if status is CREATED */}
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Table
                      style={{
                        width: "100%",
                      }}
                      bordered
                      dataSource={bills.map((bill) => ({
                        ...bill,
                        key: bill._id,
                        issuedAt: dayjs(bill.issuedAt).format("DD MMM YYYY"),
                        dueAt: dayjs(bill.dueAt).format("DD MMM YYYY"),
                        total: format(bill.total),
                      }))}
                      columns={[
                        {
                          title: "Nomor",
                          dataIndex: "number",
                          key: "number",
                          render: (text, record) => (
                            <Space size={0} direction="vertical">
                              <Typography.Text strong>{text}</Typography.Text>
                              <Typography.Text type="secondary">
                                {record.issuedAt}
                              </Typography.Text>
                            </Space>
                          ),
                        },
                        {
                          title: "Jatuh tempo",
                          dataIndex: "dueAt",
                          key: "dueAt",
                          render: (text, record) => (
                            <Space size={0} direction="vertical">
                              {record.status === "CREATED" ? (
                                <Typography.Text strong>{text}</Typography.Text>
                              ) : record.status === "PAID" ? (
                                <Tag color="success">Lunas</Tag>
                              ) : (
                                <Tag color="error">Gagal</Tag>
                              )}
                            </Space>
                          ),
                        },
                        {
                          title: "Total",
                          dataIndex: "total",
                          key: "total",
                        },
                        {
                          title: "Aksi",
                          key: "action",
                          align: "right",
                          render: (text, record) => (
                            <Space size="small">
                              <Button
                                type="dashed"
                                size="small"
                                onClick={() => handlePayButton(record._id)}
                              >
                                {record.status === "CREATED"
                                  ? "Bayar"
                                  : "Detail"}
                              </Button>
                              <Button
                                loading={isPrinting}
                                type="dashed"
                                size="small"
                                icon={<FilePdfFilled />}
                                onClick={() => handlePrintButton(record._id)}
                              ></Button>
                            </Space>
                          ),
                        },
                      ]}
                      pagination={false}
                      size="small"
                    />
                  </div>
                </Card>
                <Card
                  bordered={false}
                  title="Modul"
                  extra={
                    <Space>
                      <SearchInput onSearch={handleSearch} />
                    </Space>
                  }
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Table
                      style={{
                        width: "100%",
                      }}
                      bordered
                      dataSource={modules.map((module) => ({
                        ...module,
                        key: module._id,
                        price: module.price,
                        status: school.modules.includes(module.code)
                          ? true
                          : false,
                      }))}
                      columns={[
                        {
                          title: "Modul",
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: "Harga",
                          dataIndex: "price",
                          key: "price",
                          render: (price) => (
                            <span>
                              {format(price.actual)}
                              {/* <span
																style={{
																	fontSize: 10,
																	color: "#999",
																}}>
																{price.type === "flat" ? "" : "/siswa/thn"}
															</span> */}
                            </span>
                          ),
                        },
                        {
                          title: "Status",
                          dataIndex: "status",
                          key: "status",
                          render: (status, record) => (
                            <Switch
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              checked={status}
                              disabled
                            />
                          ),
                        },
                      ]}
                      pagination={false}
                      size="small"
                      summary={(pageData) => {
                        let total = 0;
                        pageData.forEach(({ price, status }) => {
                          if (status) {
                            total += price.actual;
                          }
                        });
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={1}>
                                <strong>Total</strong>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell colSpan={2}>
                                <strong>{format(total)}</strong>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </div>
                </Card>
                <Card
                  bordered={false}
                  title="Quota"
                  extra={
                    <Space>
                      <SearchInput onSearch={handleSearch} />
                    </Space>
                  }
                >
                  <Space>
                    <SchoolQuotaProgressBar school={school} />
                  </Space>
                </Card>
              </Space>
            </Col>
          </Row>
        )}
      </Spin>
    </>
  );
};

export default SettingPage;
