/* eslint-disable react-hooks/exhaustive-deps */
import {
	CloseCircleFilled,
	ExclamationCircleOutlined,
	MoreOutlined,
	PlusCircleFilled,
} from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Dropdown,
	message,
	Modal,
	Popconfirm,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
	requestDeleteStore,
	requestGetStoreList,
	requestUnassignMerchantFromStore,
} from "../../../services/admin.service";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import {
	PiMagnifyingGlassDuotone,
	PiPencilDuotone,
	PiStorefrontDuotone,
	PiTrashDuotone,
	PiUserPlusDuotone,
} from "react-icons/pi";
import { format } from "../../../utils/Formatter";
import StoreAssignmentFormModal from "./components/StoreAssignmentFormModal";
import StoreDetailModal from "./components/StoreDetailModal";
import StoreFormModal from "./components/StoreFormModal";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
		render: (text, record) => (
			<Space direction="horizontal">
				<PiStorefrontDuotone
					style={{
						fontSize: "1.6em",
						verticalAlign: "middle",
					}}
				/>
				<Space direction="vertical">
					<Typography.Text>{record.name}</Typography.Text>
				</Space>
			</Space>
		),
	},
	{
		title: "Saldo",
		dataIndex: "balance",
		key: "balance",
		render: (text, record) => (
			<Tag style={{ fontWeight: "bold" }} color="default">
				{format(record.balance)}
			</Tag>
		),
	},
	{
		title: "Penjual/Penjaga",
		dataIndex: "merchants",
		key: "merchants",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat Detail",
		icon: <PiMagnifyingGlassDuotone />,
	},
	{
		key: "edit",
		label: "Ubah",
		icon: <PiPencilDuotone />,
	},
	{
		key: "assign",
		label: "Tugaskan Penjual",
		icon: <PiUserPlusDuotone />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <PiTrashDuotone />,
		danger: true,
	},
];

const StorePage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Pertokoan | Daftar Toko";
	}, []);

	// eslint-disable-next-line no-unused-vars
	const [stores, setStores] = useState([]);
	const [mappedStores, setMappedStores] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [isDetailOpen, setDetailOpen] = useState(false);
	const [store, setStore] = useState(null);
	const [storeFormOpen, setStoreFormOpen] = useState(false);
	const [storeAssignmentOpen, setStoreAssignmentOpen] = useState(false);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchStoreList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchStoreList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetStoreList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setStores(response.data.data);
				updateMappedStore(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedStore = (data) => {
		setMappedStores(
			data.map((d) => ({
				key: d._id,
				...d,
				merchants: (
					<Space size={0}>
						{d.merchants?.map((merchant) => (
							<>
								<Tag
									color="blue"
									key={merchant._id}
									style={{
										marginInlineEnd: 0,
									}}>
									{merchant.name} ({merchant.email})
								</Tag>
								<Popconfirm
									title="Yakin akan menghapus merchant ini?"
									onConfirm={() => {
										requestUnassignMerchantFromStore(d._id, merchant.email)
											.then((response) => {
												message.success(response.data.message);

												fetchStoreList();
											})
											.catch((error) => {
												if (error.response && error.response.data) {
													notification["error"]({
														message: "Kesalahan!",
														description: error.response.data.message,
													});
												} else {
													notification["error"]({
														message: "Kesalahan!",
														description: error.message,
													});
												}
											});
									}}
									okText="Ya"
									cancelText="Tidak">
									<Button
										type="link"
										size="small"
										danger
										icon={<CloseCircleFilled />}
										onClick={() => {
											// handleDeleteMerchantButton(d, merchant);
										}}
									/>
								</Popconfirm>
							</>
						))}
					</Space>
				),
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										setStore(d);
										setDetailOpen(true);
									} else if (e.key === "assign") {
										setStore(d);
										setStoreAssignmentOpen(true);
									} else if (e.key === "edit") {
										setStore(d);
										setStoreFormOpen(true);
									} else if (e.key === "delete") {
										Modal.confirm({
											icon: <ExclamationCircleOutlined />,
											title: "Hapus Toko",
											content: "Apakah anda yakin akan menghapus toko ini?",
											okText: "Ya",
											cancelText: "Tidak",
											okButtonProps: { danger: true },
											onOk: () => {
												requestDeleteStore(d._id)
													.then((response) => {
														message.success(response.data.message);
														fetchStoreList();
													})
													.catch((error) => {
														if (error.response && error.response.data) {
															notification.error({
																message: "Kesalahan!",
																description: error.response.data.message,
															});
														} else {
															notification.error({
																message: "Kesalahan!",
																description: error.message,
															});
														}
													});
											},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<StoreFormModal
				open={storeFormOpen}
				store={store}
				onClose={() => setStoreFormOpen(false)}
				onSuccess={() => {
					setStoreFormOpen(false);
					fetchStoreList();
				}}
			/>
			<StoreDetailModal
				open={isDetailOpen}
				id={store ? store._id : ""}
				onClose={() => {
					setStore(null);
					setDetailOpen(false);
				}}
			/>
			<StoreAssignmentFormModal
				open={storeAssignmentOpen}
				store={store}
				onClose={() => {
					setStoreAssignmentOpen(false);
					setStore(null);
				}}
				onSuccess={() => {
					setStoreAssignmentOpen(false);
					setStore(null);
					fetchStoreList();
				}}
			/>
			<Card
				bordered={false}
				title="Daftar Toko"
				extra={
					<Space>
						<SearchInput
							placeholder="Nomor atau nama siswa"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<Button
							icon={<PlusCircleFilled />}
							type="primary"
							onClick={() => {
								setStoreFormOpen(true);
							}}>
							<span className="ant-btn-text">Tambah</span>
						</Button>
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedStores}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default StorePage;
