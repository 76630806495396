/* eslint-disable react-hooks/exhaustive-deps */
import {
	Alert,
	App,
	Avatar,
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Divider,
	Dropdown,
	Empty,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Popover,
	Radio,
	Row,
	Select,
	Space,
	Steps,
	Table,
	Tabs,
	Tag,
	TimePicker,
	Tooltip,
	Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	requestEditLesson,
	requestGetExamList,
	requestGetReportAssessmentList,
	requestGetReportMarkList,
	requestScheduleLesson,
	requestShowLesson,
	requestSignReportMarks,
} from "../../../../services/admin.service";
import dayjs from "dayjs";
import LessonReportFormModal from "./LessonReportFormModal";
import LessonReportMarkCell from "./LessonReportMarkCell";
import LessonCompetencyFormModal from "./LessonCompetencyFormModal";
import {
	BarChartOutlined,
	BookOutlined,
	CheckCircleFilled,
	CommentOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	EyeOutlined,
	HomeOutlined,
	LikeOutlined,
	LikeTwoTone,
	MoreOutlined,
	PlusOutlined,
	SaveOutlined,
	SendOutlined,
	SnippetsOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { HiOutlineTrash } from "react-icons/hi2";
import LessonExamDetailModal from "../../../../components/ExamDetailModal";
import LessonExamFormModal from "./LessonExamFormModal";
import {
	requestAddSocialComment,
	requestAddSocialStream,
	requestDeleteSocialComment,
	requestDeleteSocialStream,
	requestGetSocialCommentList,
	requestGetSocialStreamList,
	requestReactSocialStream,
} from "../../../../services/user.service";
import ReactQuill from "react-quill";
import REACTIONS from "../../../../services/reactions";

const studentColumns = [
	{
		title: "NISN",
		dataIndex: "number",
		key: "number",
		width: 100,
		fixed: "left",
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		fixed: "left",
	},
];

function timestampToTime(timestamp) {
	// if less than a day, show x hours ago
	if (dayjs().diff(timestamp, "week") < 1) {
		return dayjs(timestamp).fromNow();
	}

	return dayjs(timestamp).format("DD MMM YYYY HH:mm");
}

const LessonDetailModal = ({ id, isVisible, onClose, onSuccess }) => {
	const { notification } = App.useApp();
	const [mappedStudents, setMappedStudents] = React.useState([]);
	const [lesson, setLesson] = React.useState(null);
	const [selectedMonth, setSelectedMonth] = React.useState(dayjs());
	const [semesterTimes, setSemesterTimes] = React.useState([]);
	const [requireRefresh, setRequireRefresh] = React.useState(false);

	const [isLoading, setLoading] = React.useState(false);

	const [isReportLessonVisible, setReportLessonVisible] = React.useState(false);
	const [event, setEvent] = React.useState(null);

	const [columns, setColumns] = React.useState(studentColumns);
	const [mode, setMode] = React.useState("student");

	const [isCompetencyFormVisible, setCompetencyFormVisible] = React.useState(false);
	const [isSignedMarks, setIsSignedMarks] = React.useState(false);

	const [threshold, setThreshold] = React.useState(0);
	const [isSavingThreshold, setIsSavingThreshold] = React.useState(false);

	const [exams, setExams] = React.useState([]);
	const [isExamDetailVisible, setExamDetailVisible] = React.useState(false);
	const [isExamFormVisible, setExamFormVisible] = React.useState(false);
	const [examId, setExamId] = React.useState(null);

	const [streams, setStreams] = React.useState([]);

	const [tab, setTab] = React.useState("lms");

	const [isCommentVisible, setCommentVisible] = React.useState(false);
	const [streamId, setStreamId] = React.useState(null);

	useEffect(() => {
		if (isVisible && id) {
			fetchLessonDetail();
			fetchExamList();
			fetchStreamList();
		} else {
			setLesson(null);
			setMappedStudents([]);
			setIsSignedMarks(false);
			setMode("student");
			setTab("lms");
			setExams([]);
			setStreams([]);
		}
	}, [isVisible, id]);

	useEffect(() => {
		setColumns(studentColumns);
		if (mode === "assessment") {
			fetchAssessments();
		} else {
			setColumns(studentColumns);
		}
	}, [mode]);

	const fetchLessonDetail = () => {
		setLoading(true);
		requestShowLesson(id)
			.then((response) => {
				const lesson = response.data.data;
				const students = lesson.students;
				const mappedStudents = students.map((student) => {
					return {
						key: student._id,
						number: student.number,
						name: student.name,
					};
				});
				setMappedStudents(mappedStudents);

				setThreshold(lesson.threshold);
				setLesson(lesson);
				const events = lesson.events;
				const semesters = lesson.year.semesters;

				const semesterTimes = [];

				// group events by time and semesters
				semesters.forEach((semester) => {
					events.forEach((event) => {
						if (
							dayjs(event.startedAt).toDate() >= dayjs(semester.startedAt).toDate() &&
							dayjs(event.startedAt).toDate() <= dayjs(semester.endedAt).toDate()
						) {
							// get day from event
							const day = dayjs(event.startedAt).day();
							// get time from event
							const startedAt = dayjs(event.startedAt).format("HH:mm");
							const endedAt = dayjs(event.endedAt).format("HH:mm");

							// concat
							const time = `${day}-${startedAt}-${endedAt}`;

							// group by day and time
							if (!semesterTimes[semester._id]) {
								semesterTimes[semester._id] = [];
							}
							if (
								!semesterTimes[semester._id]
									.map((s) => `${s.day}-${s.startedAt}-${s.endedAt}`)
									.includes(time)
							) {
								semesterTimes[semester._id].push({
									day: day,
									startedAt: startedAt,
									endedAt: endedAt,
								});
							}
						}
					});
				});

				setSemesterTimes(semesterTimes);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchAssessments = () => {
		setLoading(true);

		requestGetReportAssessmentList({
			page: 1,
			limit: 100,
			filter: `curriculum:${lesson.curriculumId}`,
			order: "order:asc",
		})
			.then((response) => {
				const assessments = response.data.data;

				fetchMarks(assessments);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchMarks = (assessments) => {
		setLoading(true);

		requestGetReportMarkList({
			page: 1,
			limit: 100,
			filter: `curriculum:${lesson.curriculumId},lesson:${lesson._id},semester:current`,
			order: "order:asc",
		})
			.then((response) => {
				const marks = response.data.data;
				const isSigned = marks.every((mark) => mark.signature && mark.signature.teacher._id);

				setIsSignedMarks(isSigned);

				const refs = {};
				const cols = [...studentColumns];

				for (const assessment of assessments) {
					const markAssessments = [];

					for (const mark of marks) {
						// mark has assessments, check the assessment._id that eq to assessment._id
						const markAssessment = mark.assessments.find(
							(a) => a._id.toString() === assessment._id.toString()
						);

						if (
							markAssessment &&
							markAssessments.findIndex((a) => a.studentId === mark.card?.studentId) === -1
						) {
							markAssessments.push({
								...markAssessment,
								studentId: mark.card?.studentId,
								markId: mark._id,
							});
						}

						for (const ma of markAssessments) {
							refs[`${ma.studentId}-${assessment._id}`] = React.createRef();
						}
					}

					cols.push({
						title: assessment.name,
						dataIndex: assessment._id,
						key: assessment._id,
						width: 100,
						align: "center",
						render: (text, record) => {
							const markAssessment = markAssessments.find((a) => a.studentId === record.key);
							const ref = refs[`${record.key}-${assessment._id}`];

							return markAssessment ? (
								<LessonReportMarkCell
									inputRef={ref}
									markId={markAssessment.markId}
									assessmentId={assessment._id}
									score={markAssessment.score}
									onSaved={() => {
										fetchMarks(assessments);
									}}
									onMove={(direction) => {
										if (direction === "ArrowLeft") {
											const index = assessments.findIndex((a) => a._id === assessment._id);
											if (index > 0) {
												const prevAssessment = assessments[index - 1];
												const prevRef = refs[`${record.key}-${prevAssessment._id}`];
												prevRef?.current.focus({
													cursor: "all",
												});
											}
										} else if (direction === "ArrowRight") {
											const index = assessments.findIndex((a) => a._id === assessment._id);
											if (index < assessments.length - 1) {
												const nextAssessment = assessments[index + 1];
												const nextRef = refs[`${record.key}-${nextAssessment._id}`];
												nextRef?.current.focus({
													cursor: "all",
												});
											}
										} else if (direction === "ArrowUp") {
											const index = mappedStudents.findIndex((s) => s.key === record.key);
											if (index > 0) {
												const prevStudent = mappedStudents[index - 1];
												const prevRef = refs[`${prevStudent.key}-${assessment._id}`];
												prevRef?.current.focus({
													cursor: "all",
												});
											}
										} else if (direction === "ArrowDown") {
											const index = mappedStudents.findIndex((s) => s.key === record.key);
											if (index < mappedStudents.length - 1) {
												const nextStudent = mappedStudents[index + 1];
												const nextRef = refs[`${nextStudent.key}-${assessment._id}`];
												nextRef?.current.focus({
													cursor: "all",
												});
											}
										}
									}}
								/>
							) : (
								"-"
							);
						},
					});
				}

				// add total and final score
				cols.push(
					...[
						{
							title: "Total",
							dataIndex: "total",
							key: "total",
							width: 80,
							align: "center",
							render: (text, record) => {
								const mark = marks.find((m) => m.card?.studentId === record.key);

								if (mark) {
									const total = mark.assessments.reduce((acc, a) => acc + a.score, 0);

									return <Typography.Text strong>{total}</Typography.Text>;
								}

								return "-";
							},
						},
						{
							title: "Nilai Akhir",
							dataIndex: "final",
							key: "final",
							width: 80,
							align: "center",
							render: (text, record) => {
								const mark = marks.find((m) => m.card?.studentId === record.key);

								if (mark) {
									const total = mark.assessments.reduce((acc, a) => acc + a.score * a.weight, 0);

									return <Typography.Text strong>{total}</Typography.Text>;
								}

								return "-";
							},
						},
					]
				);

				if (lesson.curriculum.classifications && lesson.curriculum.classifications.length > 0) {
					cols.push({
						title: "Klasifikasi",
						dataIndex: "classification",
						key: "classification",
						width: 100,
						render: (text, record) => {
							const mark = marks.find((m) => m.card?.studentId === record.key);

							if (mark) {
								const total = mark.assessments.reduce((acc, a) => acc + a.score * a.weight, 0);
								const classification = lesson.curriculum.classifications.find(
									(c) => total >= c.threshold
								);

								return (
									<Tag>
										<Typography.Text strong>
											{classification ? classification.label : "-"}
										</Typography.Text>
									</Tag>
								);
							}

							return (
								<Tag>
									<Typography.Text strong>-</Typography.Text>
								</Tag>
							);
						},
					});
				}

				if (lesson.competencies && lesson.competencies.length > 0) {
					cols.push({
						title: "Capaian Kompetensi",
						dataIndex: "competency",
						key: "competency",
						width: 240,
						render: (text, record) => {
							const mark = marks.find((m) => m.card?.studentId === record.key);

							if (mark) {
								const total = mark.assessments.reduce((acc, a) => acc + a.score * a.weight, 0);
								const competency = lesson.competencies.find((c) => total >= c.threshold);

								return competency ? competency.description : "-";
							}

							return "-";
						},
					});
				}

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchExamList = () => {
		setLoading(true);
		requestGetExamList({
			page: 1,
			filter: `lesson:${id}`,
		})
			.then((response) => {
				setExams(response.data.data);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchStreamList = () => {
		requestGetSocialStreamList({
			page: 1,
			filter: `lesson:${id}`,
		})
			.then((response) => {
				setStreams(response.data.data);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleReportButton = (event) => {
		setEvent(event);
		setReportLessonVisible(true);
	};

	const handleSubmit = (semesterId, values) => {
		setLoading(true);
		if (values.remaining) {
			values.mode = "REMAINING";
		}
		values.type = "LESSON";
		values.referenceId = lesson._id;
		values.semesterId = semesterId;
		values.times = values.times.map((time) => {
			return {
				day: time.day,
				startedAt: dayjs(time.time[0]).format("HH:mm"),
				endedAt: dayjs(time.time[1]).format("HH:mm"),
			};
		});

		requestScheduleLesson(lesson._id, values)
			.then((response) => {
				message.success(response.data.message);

				fetchLessonDetail();
				setRequireRefresh(true);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		onClose(requireRefresh);
	};

	const handleOnReportSuccess = (lesson, event) => {
		const tempLesson = { ...lesson };
		const tempEvents = [...tempLesson.events];
		const index = tempEvents.findIndex((e) => e._id === event._id);
		if (index !== -1) {
			tempEvents[index] = event;
			tempLesson.events = tempEvents;
			setLesson(tempLesson);
		}
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const handleDeleteCompetencyButton = (competencyId) => {
		const payload = {
			competencies: lesson.competencies.filter((c) => c._id !== competencyId),
		};

		requestEditLesson(lesson._id, payload)
			.then(() => {
				message.success("Kompetensi berhasil dihapus");
				fetchLessonDetail();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const handleSignButton = () => {
		setLoading(true);

		const payload = {
			lessonId: lesson._id,
		};

		requestSignReportMarks(payload)
			.then(() => {
				message.success("Nilai siswa berhasil ditanda tangani");
				fetchLessonDetail();
				fetchAssessments();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnExamDetailOpen = (exam) => {
		setExamId(exam._id);
		setExamDetailVisible(true);
	};

	return (
		<>
			<LessonReportFormModal
				isVisible={isReportLessonVisible}
				onClose={() => {
					setReportLessonVisible(false);
				}}
				onSuccess={handleOnReportSuccess}
				lesson={lesson}
				event={event}
				setLoading={setLoading}
				isLoading={isLoading}
			/>
			<LessonCompetencyFormModal
				open={isCompetencyFormVisible}
				lesson={lesson}
				curriculum={lesson?.curriculum}
				onClose={() => {
					setCompetencyFormVisible(false);
				}}
				onSuccess={() => {
					setCompetencyFormVisible(false);
					fetchLessonDetail();
				}}
			/>
			<LessonExamFormModal
				isVisible={isExamFormVisible}
				lesson={lesson}
				onClose={() => {
					setExamFormVisible(false);
				}}
				onSuccess={() => {
					setExamFormVisible(false);
					fetchExamList();
				}}
			/>
			<LessonExamDetailModal
				id={examId}
				isVisible={isExamDetailVisible}
				onClose={(requireRefresh) => {
					setExamDetailVisible(false);
					setExamId(null);

					if (requireRefresh) {
						fetchExamList();
					}
				}}
			/>
			<LessonStreamCommentModal
				isVisible={isCommentVisible}
				streamId={streamId}
				onClose={() => {
					setCommentVisible(false);
					setStreamId(null);
				}}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={lesson ? `${lesson.subject.name} - ${lesson.classroom.name}` : "Detail Pelajaran"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				{lesson && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col lg={8} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail" size="small">
									<Descriptions size="small" layout="vertical" column={2}>
										<Descriptions.Item label="Mata Pelajaran">
											{lesson.subject?.name}
										</Descriptions.Item>
										<Descriptions.Item label="Kelas">
											{lesson.classroom?.grade} ({lesson.classroom?.name})
										</Descriptions.Item>
										<Descriptions.Item label="Kurikulum">
											{lesson.curriculum?.name}
										</Descriptions.Item>
										<Descriptions.Item label="Tahun Ajaran">{lesson.year?.name}</Descriptions.Item>
										<Descriptions.Item label="Pengajar Pengajar">
											{lesson.teachers?.map((teacher) => teacher.name).join(", ")}
										</Descriptions.Item>
										<Descriptions.Item label="Total Jam">
											{lesson.totalHour ?? 0} Jam
										</Descriptions.Item>
										<Descriptions.Item label="Nilai Minimum (KKM/KKTP)">
											<Space.Compact>
												<InputNumber
													variant="outlined"
													size="small"
													value={threshold}
													onChange={(value) => {
														setThreshold(value);
													}}
												/>
												<Button
													loading={isSavingThreshold}
													type="default"
													size="small"
													icon={<SaveOutlined />}
													onClick={() => {
														setIsSavingThreshold(true);

														const payload = {
															threshold: threshold,
														};

														requestEditLesson(lesson._id, payload)
															.then(() => {
																message.success("Nilai minimum berhasil disimpan");
															})
															.catch((error) => {
																if (error.response && error.response.data) {
																	notification.error({
																		message: "Kesalahan!",
																		description: error.response.data.message,
																	});
																} else {
																	notification.error({
																		message: "Kesalahan!",
																		description: error.message,
																	});
																}
															})
															.finally(() => {
																setIsSavingThreshold(false);
															});
													}}></Button>
											</Space.Compact>
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card title="Sinkronisasi Jadwal" size="small">
									<Tabs
										type="line"
										size="small"
										items={lesson.year.semesters.map((semester) => {
											return {
												label: `${semester.name}`,
												key: semester._id,
												children: (
													<SemesterForm
														onSubmit={(values) => handleSubmit(semester._id, values)}
														times={semesterTimes[semester._id]}
													/>
												),
											};
										})}
									/>
								</Card>
								<Card
									title={
										<Row justify="space-between">
											Pertemuan{" "}
											<DatePicker
												size="small"
												picker="month"
												placeholder="Pilih bulan"
												onChange={(v) => {
													setSelectedMonth(v);
												}}
											/>
										</Row>
									}
									size="small">
									<Space style={{ width: "100%" }} direction="vertical">
										<Steps
											progressDot
											size="small"
											direction="vertical"
											current={
												lesson.events.filter(
													(event) =>
														dayjs(event.startedAt).format("MM-YYYY") ===
															dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
												).length - 1
											}>
											{lesson.events &&
												lesson.events
													.filter(
														(event) =>
															dayjs(event.startedAt).format("MM-YYYY") ===
																dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
													)
													.map((event) => (
														<Steps.Step
															key={event._id}
															title={dayjs(event.startedAt).format("dddd, D MMMM YYYY")}
															description={
																<span>
																	{dayjs(event.startedAt).format("HH:mm") +
																		" - " +
																		dayjs(event.endedAt).format("HH:mm")}
																	{dayjs(event.startedAt).toDate() < dayjs().toDate() && (
																		<Button
																			type="dashed"
																			style={{ marginLeft: 4 }}
																			size="small"
																			onClick={() => {
																				handleReportButton(event);
																			}}>
																			Laporan
																		</Button>
																	)}
																</span>
															}
														/>
													))}
										</Steps>
									</Space>
								</Card>
							</Space>
						</Col>
						<Col lg={16} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%", paddingTop: 8 }}>
								<Tabs
									size="small"
									type="card"
									activeKey={tab}
									onChange={(key) => {
										setTab(key);
									}}
									tabBarExtraContent={
										tab === "lms" ? null : tab === "student" ? (
											<div
												style={{
													display: "flex",
													gap: 4,
												}}>
												<Radio.Group
													size="small"
													value={mode}
													onChange={(e) => handleModeChange(e.target.value)}>
													<Radio.Button value="student">Siswa</Radio.Button>
													<Radio.Button value="assessment">Penilaian</Radio.Button>
												</Radio.Group>
												{mode === "assessment" && (
													<Popconfirm
														title="Apakah Anda yakin akan menanda tangani nilai siswa? Tindakan ini tidak dapat dibatalkan. Anda tidak dapat mengubah nilai siswa setelah ditanda tangani."
														okText="Ya"
														cancelText="Tidak"
														onConfirm={() => {
															handleSignButton();
														}}>
														<Button size="small" type="primary" disabled={isSignedMarks}>
															Tanda tangani
														</Button>
													</Popconfirm>
												)}
											</div>
										) : tab === "competency" ? (
											<Button
												type="primary"
												size="small"
												onClick={() => {
													setCompetencyFormVisible(true);
												}}>
												Tambah
											</Button>
										) : null
									}
									items={[
										{
											key: "lms",
											label: "Manajemen Pembelajaran",
											icon: <BookOutlined />,
											children: (
												<Card size="small">
													<Tabs
														size="small"
														type="line"
														items={[
															{
																key: "stream",
																label: "Stream",
																icon: <HomeOutlined />,
																children: (
																	<LessonStreamSection
																		lesson={lesson}
																		streams={streams}
																		onSuccess={() => {
																			fetchStreamList();
																		}}
																		setCommentVisible={setCommentVisible}
																		setStreamId={setStreamId}
																		onDeleted={(stream) => {
																			const newStreams = streams.filter(
																				(s) => s._id !== stream._id
																			);
																			setStreams(newStreams);
																		}}
																	/>
																),
															},
															{
																key: "lesson",
																label: "Materi",
																icon: <BookOutlined />,
																children: <Empty description="Belum ada data" />,
															},
															{
																key: "exam",
																label: "Ujian",
																icon: <SnippetsOutlined />,
																children: (
																	<LessonExamSection
																		exams={exams}
																		onOpen={handleOnExamDetailOpen}
																		onSuccess={() => {
																			fetchExamList();
																		}}
																		onAdd={() => {
																			setExamFormVisible(true);
																		}}
																	/>
																),
															},
														]}
													/>
												</Card>
											),
										},
										{
											key: "competency",
											label: "Kompetensi Penilaian",
											icon: <BarChartOutlined />,
											children: (
												<>
													{lesson.competencies && lesson.competencies.length > 0 && (
														<Table
															loading={isLoading}
															size="small"
															columns={[
																{
																	title: "No",
																	dataIndex: "number",
																	key: "number",
																	align: "center",
																	width: 36,
																},
																{
																	title: "Deskripsi",
																	dataIndex: "description",
																	key: "description",
																},
																{
																	title: "Treshold",
																	dataIndex: "threshold",
																	key: "threshold",
																	align: "center",
																},
																{
																	title: "Klasifikasi",
																	dataIndex: "classification",
																	key: "classification",
																	render: (text, record) => {
																		return (
																			<Tag>
																				<Typography.Text strong>{text}</Typography.Text>
																			</Tag>
																		);
																	},
																},
																{
																	title: "Aksi",
																	key: "actions",
																	align: "right",
																	render: (text, record) => {
																		return (
																			<Space>
																				<Dropdown
																					trigger={["click"]}
																					menu={{
																						onClick: (e) => {
																							if (e.key === "delete") {
																								// handleShowButton(d);
																								Modal.confirm({
																									title: "Sebentar",
																									icon: <ExclamationCircleFilled />,
																									content:
																										"Apakah Anda yakin akan menghapus kompetensi ini?",
																									okText: "Ya",
																									okType: "danger",
																									cancelText: "Tidak",
																									onOk() {
																										handleDeleteCompetencyButton(record.key);
																									},
																									onCancel() {},
																								});
																							}
																						},
																						items: [
																							{
																								key: "delete",
																								label: "Hapus",
																								icon: <HiOutlineTrash />,
																								danger: true,
																							},
																						],
																					}}
																					placement="bottomRight"
																					arrow>
																					<Button
																						type="default"
																						shape="circle"
																						size="small"
																						icon={<MoreOutlined />}
																					/>
																				</Dropdown>
																			</Space>
																		);
																	},
																},
															]}
															dataSource={[
																...lesson.competencies.map((competency, index) => {
																	return {
																		key: competency._id,
																		number: index + 1,
																		description: competency.description,
																		threshold: competency.threshold,
																		classification:
																			lesson.curriculum.classifications.find(
																				(c) => competency.threshold >= c.threshold
																			)?.label ?? "-",
																	};
																}),
															]}
															bordered
															pagination={false}
														/>
													)}
													{(!lesson.competencies || lesson.competencies.length === 0) && (
														<Space
															direction="vertical"
															size="middle"
															align="center"
															style={{ width: "100%" }}>
															<Empty description="Belum ada kompetensi penilaian" />
															<Button
																type="primary"
																size="small"
																onClick={() => {
																	setCompetencyFormVisible(true);
																}}>
																Tambahkan
															</Button>
														</Space>
													)}
												</>
											),
										},
										{
											key: "student",
											label: "Siswa",
											icon: <UserOutlined />,
											children: (
												<Table
													size="small"
													columns={columns}
													dataSource={mappedStudents}
													loading={isLoading}
													bordered
													pagination={{
														position: "bottomRight",
														defaultPageSize: 100,
														pageSizeOptions: ["10", "20", "50", "100"],
														showSizeChanger: true,
														locale: { items_per_page: "" },
													}}
													scroll={{ x: "max-content" }}
												/>
											),
										},
									]}
								/>
							</Space>
						</Col>
					</Row>
				)}
			</Modal>
		</>
	);
};

export default LessonDetailModal;

function SemesterForm({ times, onSubmit }) {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			onSubmit(values);
		});
	};

	useEffect(() => {
		if (times) {
			form.setFieldsValue({
				times: times.map((time) => {
					return {
						day: time.day.toString(),
						time: [dayjs(time.startedAt, "HH:mm"), dayjs(time.endedAt, "HH:mm")],
					};
				}),
			});
		}
	}, [times]);

	return (
		<Form form={form} layout="vertical" requiredMark="optional">
			<Form.List name="times" label="Bahan" style={{ marginTop: 0, marginBottom: 12 }}>
				{(times, { add, remove }) => (
					<>
						{times.map(({ key, name, ...restField }, itemIndex) => (
							<Row gutter={8} key={key} align="baseline">
								<Col span={8}>
									<Form.Item
										required
										style={{ marginTop: 0, marginBottom: 12 }}
										{...restField}
										name={[name, "day"]}
										rules={[
											{
												required: true,
												message: "Nama harus diisi!",
											},
										]}>
										<Select placeholder="Hari">
											<Select.Option value="0">Ahad</Select.Option>
											<Select.Option value="1">Senin</Select.Option>
											<Select.Option value="2">Selasa</Select.Option>
											<Select.Option value="3">Rabu</Select.Option>
											<Select.Option value="4">Kamis</Select.Option>
											<Select.Option value="5">Jumat</Select.Option>
											<Select.Option value="6">Sabtu</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item requiredMark style={{ marginTop: 0, marginBottom: 0 }} {...restField}>
										<Input.Group compact>
											<Form.Item
												requiredMark
												name={[name, "time"]}
												style={{
													width: "calc(100% - 32px)",
													marginTop: 0,
													marginBottom: 0,
												}}
												rules={[
													{
														required: true,
														message: "Waktu harus diisi ya!",
													},
												]}>
												<TimePicker.RangePicker
													format={"HH:mm"}
													style={{
														width: "100%",
														marginTop: 0,
														marginBottom: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												/>
											</Form.Item>
											<Button
												icon={<DeleteOutlined />}
												onClick={() => remove(name)}
												danger></Button>
										</Input.Group>
									</Form.Item>
								</Col>
							</Row>
						))}
						<Form.Item style={{ marginTop: 0, marginBottom: 12 }}>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Tambah Jam
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item name="remaining" valuePropName="checked" initialValue={true}>
				<Checkbox value={"remaining"}>Ganti sisa jadwal saja (mulai hari ini)</Checkbox>
			</Form.Item>
			<Popconfirm
				placement="topRight"
				title={`Semua data yang berkaitan (termasuk laporan) dengan jadwal yang terhapus akan ikut terhapus. Apakah anda yakin?`}
				onConfirm={handleSubmit}
				okText="Yes"
				cancelText="No">
				<Button type="primary" style={{ width: "100%" }}>
					Terapkan
				</Button>
			</Popconfirm>
		</Form>
	);
}

function LessonExamSection({ exams, onOpen, onSuccess, onAdd }) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 8,
			}}>
			<Alert
				message="Anda dapat mengatur ujian untuk siswa Anda melalui menu ini."
				type="info"
				closable
			/>
			<Button type="dashed" size="middle" onClick={onAdd}>
				Tambah
			</Button>
			{exams.length === 0 && <Empty description="Belum ada data ujian" />}
			{exams.map((exam) => {
				return (
					<Badge.Ribbon
						key={exam._id}
						text={
							exam.status === "DRAFT" ? "Draf" : exam.status === "PUBLISHED" ? "Terbit" : "Arsip"
						}
						color={
							exam.status === "DRAFT" ? "orange" : exam.status === "PUBLISHED" ? "green" : "red"
						}>
						<Card key={exam._id} size="small">
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
									<Typography.Text strong>{exam.name}</Typography.Text>
									<Typography.Text>{exam.description}</Typography.Text>
									{exam.assessment && (
										<Typography.Text
											style={{
												fontStyle: "italic",
												fontSize: 10,
											}}
											type="secondary">
											terhubung dengan{" "}
											<Tag
												style={{
													marginLeft: 8,
													fontStyle: "normal",
													fontWeight: "bold",
													fontSize: 10,
												}}
												color="green">
												{exam.assessment.name} {exam.subject?.name}
											</Tag>
										</Typography.Text>
									)}
									{/* {exam.blueprint && (
										<Typography.Text
											type="secondary"
											style={{
												fontStyle: "italic",
												fontSize: 10,
											}}>
											dari template{" "}
											<Tag
												style={{
													marginLeft: 8,
													fontStyle: "normal",
													fontWeight: "bold",
													fontSize: 10,
												}}>
												{exam.blueprint.name}
											</Tag>
										</Typography.Text>
									)} */}
								</div>
								{(exam.startedAt || exam.endedAt) && (
									<div
										style={{
											display: "flex",
											justifyContent: "end",
											alignItems: "center",
										}}>
										<Typography.Text type="secondary">
											{/* {exam.startedAt && <>{dayjs(exam.startedAt).format("DD MMMM YYYY HH:mm")}</>}
											{exam.endedAt && (
												<>
													{" - "}
													{dayjs(exam.endedAt).format("DD MMMM YYYY HH:mm")}
												</>
											)} */}
											{dayjs(exam.startedAt).format("YYYY-MM-DD") ===
											dayjs(exam.endedAt).format("YYYY-MM-DD")
												? dayjs(exam.startedAt).format("DD MMM YYYY HH:mm") +
												  " - " +
												  dayjs(exam.endedAt).format("HH:mm")
												: dayjs(exam.startedAt).format("DD MMM YYYY HH:mm") +
												  " - " +
												  dayjs(exam.endedAt).format("DD MMM YYYY HH:mm")}
										</Typography.Text>
									</div>
								)}
								<div
									style={{
										display: "flex",
										justifyContent: "end",
										alignItems: "center",
										gap: "8px",
									}}>
									<Button
										icon={<EyeOutlined />}
										onClick={() => {
											onOpen(exam);
										}}>
										Lihat
									</Button>
								</div>
							</Space>
						</Card>
					</Badge.Ribbon>
				);
			})}
		</div>
	);
}

function LessonStreamSection({
	lesson,
	streams,
	onSuccess,
	onDeleted,
	setCommentVisible,
	setStreamId,
}) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 8,
			}}>
			<Alert
				message="Stream adalah sosial media ala Smartiva. Menghidupkan komunikasi yang sehat secara faktual."
				type="info"
				closable
			/>
			<LessonStreamFrom
				lesson={lesson}
				onSuccess={() => {
					onSuccess();
				}}
			/>
			{streams.length === 0 && <Empty description="Belum ada data stream" />}
			{streams.map((stream) => {
				return (
					<Card key={stream._id} size="small">
						<Space
							direction="vertical"
							size="middle"
							className="layout-popup"
							style={{ width: "100%" }}>
							<div
								style={{ display: "flex", flexDirection: "column", gap: 8, position: "relative" }}>
								<div
									style={{
										display: "flex",
										gap: 8,
									}}>
									<Avatar size="default">
										<UserOutlined />
									</Avatar>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: 4,
										}}>
										<div
											style={{
												display: "flex",
												gap: 4,
												alignItems: "center",
											}}>
											<Typography.Text strong>{stream.user.name}</Typography.Text>
											{stream.user.role === "MASTER" ? (
												<CheckCircleFilled style={{ color: "#faad14" }} />
											) : stream.user.role === "ADMIN" ? (
												<CheckCircleFilled style={{ color: "#1677ff" }} />
											) : stream.user.role === "TEACHER" ? (
												<CheckCircleFilled style={{ color: "#52c41a" }} />
											) : (
												<CheckCircleFilled style={{ color: "#bfbfbf" }} />
											)}
											<Typography.Text type="secondary">
												• {timestampToTime(stream.createdAt)}
											</Typography.Text>
										</div>
										<div
											className="social-stream-content"
											dangerouslySetInnerHTML={{
												__html: stream.content,
											}}
										/>
										<Space size="small" direction="horizontal">
											<Popover
												placement="top"
												content={
													<div
														style={{
															display: "flex",
															gap: 8,
														}}>
														{Object.keys(REACTIONS).map((key) => (
															<Tooltip key={key} title={key} color="green">
																<div
																	style={{
																		height: 24,
																		color: stream.reaction?.type === key ? "#52c41a" : "#999",
																		cursor: "pointer",
																		display: "flex",
																		alignItems: "center",
																	}}
																	// type={stream.reaction?.type === key ? "success" : "secondary"}
																	onClick={() => {
																		requestReactSocialStream(stream._id, {
																			type: key,
																		}).then((response) => {
																			message.success(response.data.message);
																			onSuccess();
																		});
																	}}>
																	{REACTIONS[key]}
																	{stream.reactions?.filter((r) => r.type === key).length > 0 && (
																		<Typography.Text type="secondary">
																			{stream.reactions?.filter((r) => r.type === key).length}
																		</Typography.Text>
																	)}
																</div>
															</Tooltip>
														))}
													</div>
												}>
												<Typography.Text type="secondary">
													{stream.reaction ? <LikeTwoTone /> : <LikeOutlined />}{" "}
													{stream.reactions?.length ?? 0}
												</Typography.Text>
											</Popover>
											<Divider type="vertical" />
											<Typography.Text
												onClick={() => {
													setCommentVisible(true);
													setStreamId(stream._id);
												}}
												type="secondary">
												<CommentOutlined /> {stream.commentCount}
											</Typography.Text>
										</Space>
									</div>
								</div>
								<div
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										display: "flex",
										gap: 4,
									}}>
									<Dropdown
										trigger={["click"]}
										menu={{
											onClick: (e) => {
												if (e.key === "delete") {
													Modal.confirm({
														title: "Sebentar",
														icon: <ExclamationCircleFilled />,
														content:
															"Anda yakin akan menghapus stream ini? Tindakan ini tidak dapat dibatalkan.",
														okText: "Ya",
														okType: "danger",
														cancelText: "Tidak",
														onOk() {
															requestDeleteSocialStream(stream._id)
																.then((response) => {
																	message.success(response.data.message);
																	onDeleted(stream);
																})
																.catch((error) => {
																	if (error.response && error.response.data) {
																		notification["error"]({
																			message: "Kesalahan!",
																			description: error.response.data.message,
																		});
																	} else {
																		notification["error"]({
																			message: "Kesalahan!",
																			description: error.message,
																		});
																	}
																})
																.finally(() => {});
														},
														onCancel() {},
													});
												}
											},
											items: [
												{
													key: "delete",
													label: "Hapus",
													icon: <HiOutlineTrash />,
													danger: true,
												},
											],
										}}
										placement="bottomRight"
										arrow>
										<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
									</Dropdown>
								</div>
							</div>
						</Space>
					</Card>
				);
			})}
		</div>
	);
}

function LessonStreamFrom({ lesson, onSuccess }) {
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);

				values.scope = "LESSON";
				values.lessonId = lesson._id;

				requestAddSocialStream(values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	return (
		<div>
			<Form form={form} layout="vertical" requiredMark="optional">
				<Form.Item
					style={{ marginTop: 0, marginBottom: 0 }}
					name="content"
					tooltip="Masukkan isi stream"
					required
					rules={[{ required: true, message: "Isi stream wajib diisi!" }]}>
					<ReactQuill
						onChange={(value) => {
							form.setFieldsValue({
								content: value,
							});
						}}
						theme="snow"
						style={{
							width: "100%",
						}}
					/>
				</Form.Item>
			</Form>
			<div
				style={{
					marginTop: 8,
					display: "flex",
					justifyContent: "flex-end",
				}}>
				<Button
					icon={<SendOutlined />}
					iconPosition="end"
					type="primary"
					htmlType="submit"
					loading={loading}
					onClick={handleSubmit}>
					Kirim
				</Button>
			</div>
		</div>
	);
}

function LessonStreamCommentModal({ isVisible, streamId, onClose }) {
	const [form] = Form.useForm();
	const [comments, setComments] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [posting, setPosting] = React.useState(false);

	React.useEffect(() => {
		if (isVisible && streamId) {
			fetchCommentList();
		} else {
			setComments([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible && streamId]);

	const fetchCommentList = async () => {
		setLoading(true);

		requestGetSocialCommentList(streamId, {
			page: 1,
			limit: 100,
			order: "_id:desc",
		})
			.then((response) => {
				const comments = response.data.data;
				setComments(comments);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			setPosting(true);
			requestAddSocialComment(streamId, values)
				.then((response) => {
					message.success(response.data.message);
					form.resetFields();
					fetchCommentList();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification.error({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					setPosting(false);
				});
		});
	};

	return (
		<>
			<Modal
				maskClosable={false}
				style={{ top: 32 }}
				title="Komentar"
				open={isVisible}
				cancelText="Batal"
				onCancel={onClose}
				footer={null}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "calc(100svh - 128px)",
					}}>
					<div
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							gap: 8,
							overflowY: "auto",
							scrollbarWidth: "thin",
							scrollbarColor: "#eaeaea transparent",
							scrollbarGutter: "0px",
						}}>
						{loading ? (
							<Card loading size="small" />
						) : comments.length > 0 ? (
							comments.map((comment) => {
								return (
									<SocialCommentCard
										key={comment._id}
										comment={comment}
										onDeleted={() => {
											const newComments = comments.filter((c) => c._id !== comment._id);

											setComments(newComments);
										}}
									/>
								);
							})
						) : (
							<Empty description="Belum ada komentar" />
						)}
					</div>
					{/* comment form */}
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 8,
						}}>
						<Form form={form} layout="vertical" requiredMark="optional">
							<Form.Item
								style={{ marginTop: 0, marginBottom: 0 }}
								name="content"
								tooltip="Masukkan isi komentar"
								required
								rules={[{ required: true, message: "Isi komentar wajib diisi!" }]}>
								<ReactQuill
									onChange={(value) => {
										form.setFieldsValue({
											content: value,
										});
									}}
									theme="snow"
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Form>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end",
								gap: 8,
							}}>
							{/* <Button onClick={onClose}>Batal</Button> */}
							<Button
								icon={<SendOutlined />}
								iconPosition="end"
								type="primary"
								htmlType="submit"
								loading={posting}
								onClick={handleSubmit}>
								Kirim
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

function SocialCommentCard({ comment, onDeleted }) {
	// const [deleting, setDeleting] = useEffect(false);

	return (
		<Card key={comment._id} size="small">
			<div style={{ width: "100%", position: "relative" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 4,
					}}>
					<div
						style={{
							display: "flex",
							gap: 4,
							alignItems: "center",
						}}>
						<Typography.Text strong>{comment.user.name}</Typography.Text>
						{comment.user.role === "MASTER" ? (
							<CheckCircleFilled style={{ color: "#faad14" }} />
						) : comment.user.role === "ADMIN" ? (
							<CheckCircleFilled style={{ color: "#1677ff" }} />
						) : comment.user.role === "TEACHER" ? (
							<CheckCircleFilled style={{ color: "#52c41a" }} />
						) : (
							<CheckCircleFilled style={{ color: "#bfbfbf" }} />
						)}
						<Typography.Text type="secondary">
							• {timestampToTime(comment.createdAt)}
						</Typography.Text>
					</div>
					<div
						className="social-stream-content"
						dangerouslySetInnerHTML={{
							__html: comment.content,
						}}
					/>
				</div>
				<div
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						display: "flex",
						gap: 4,
					}}>
					<Dropdown
						trigger={["click"]}
						menu={{
							onClick: (e) => {
								if (e.key === "delete") {
									Modal.confirm({
										title: "Sebentar",
										icon: <ExclamationCircleFilled />,
										content:
											"Anda yakin akan menghapus komentar ini? Tindakan ini tidak dapat dibatalkan.",
										okText: "Ya",
										okType: "danger",
										cancelText: "Tidak",
										onOk() {
											requestDeleteSocialComment(comment.streamId, comment._id)
												.then((response) => {
													message.success(response.data.message);
													onDeleted();
												})
												.catch((error) => {
													if (error.response && error.response.data) {
														notification["error"]({
															message: "Kesalahan!",
															description: error.response.data.message,
														});
													} else {
														notification["error"]({
															message: "Kesalahan!",
															description: error.message,
														});
													}
												})
												.finally(() => {});
										},
										onCancel() {},
									});
								}
							},
							items: [
								{
									key: "delete",
									label: "Hapus",
									icon: <HiOutlineTrash />,
									danger: true,
								},
							],
						}}
						placement="bottomRight"
						arrow>
						<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
					</Dropdown>
				</div>
			</div>
		</Card>
	);
}
