import { BookFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";

export const CurriculumFilter = ({
  curriculums,
  filterBy,
  filteredBy,
  resettable = false,
  ...props
}) => {
  const onClick = ({ key }) => {
    filterBy(key);
  };

  const items = [
    ...curriculums.map((curriculum) => {
      return {
        key: curriculum._id,
        label: (
          <div
            onClick={() => filterBy(curriculum._id)}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {curriculum.name}
            {curriculum._id === filteredBy.key && <CheckCircleOutlined />}
          </div>
        ),
      };
    }),
    resettable && {
      key: "",
      label: <div onClick={() => filterBy("")}>Semua Kurikulum</div>,
    },
  ];

  return (
    <div {...props}>
      <Dropdown
        className="filter"
        trigger={["click"]}
        resettable={true}
        menu={{
          items: items,
          onClick: onClick,
        }}
      >
        <Button
          type={filteredBy.value !== "" ? "primary" : "default"}
          icon={<BookFilled />}
          className="ant-dropdown-link btn-filter"
        >
          <span className="btn-filter-text">
            {filteredBy.value && filteredBy.value !== ""
              ? `: ${filteredBy.value}`
              : "Semua Kurikulum"}
          </span>
        </Button>
      </Dropdown>
    </div>
  );
};
