import {
	Button,
	Descriptions,
	message,
	Modal,
	notification,
	Popconfirm,
	Select,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
	requestGetPocketList,
	requestLinkPocketToCard,
	requestShowCard,
	requestUnlinkPocketFromCard,
} from "../../../../services/admin.service";

const CardDetailModal = ({ cardId, open, onClose }) => {
	const [card, setCard] = useState(null);
	const [pockets, setPockets] = useState([]);
	const [pocketId, setPocketId] = useState(null);

	useEffect(() => {
		if (open && cardId) {
			fetchCard();
		} else {
			setCard(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, cardId]);

	useEffect(() => {
		if (card) {
			fetchPockets();
		} else {
			setPockets([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [card]);

	const fetchCard = () => {
		requestShowCard(cardId)
			.then((response) => {
				setCard(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const fetchPockets = () => {
		requestGetPocketList({
			filter: `student:${card.studentId}`,
		})
			.then((response) => {
				setPockets(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title="Detail Kartu"
				open={open}
				cancelText="Batal"
				onCancel={onClose}
				closeIcon={null}
				footer={[
					<Button key={1} onClick={onClose}>
						Tutup
					</Button>,
				]}>
				<Descriptions bordered column={1} size="small">
					<Descriptions.Item label="Nomor Kartu">{card?.number}</Descriptions.Item>
					<Descriptions.Item label="Nomor Siswa">{card?.student?.number}</Descriptions.Item>
					<Descriptions.Item label="Nama Siswa">{card?.student?.name}</Descriptions.Item>
					<Descriptions.Item label="Tabungan">
						{card?.pocketId ? (
							<>
								<Typography.Text>
									{card?.pocket?.number?.replace(/(.{4})/g, "$1-")?.slice(0, -1)}
								</Typography.Text>
								<Popconfirm
									title="Apakah Anda yakin?"
									onConfirm={() => {
										requestUnlinkPocketFromCard(card?.pocketId)
											.then((response) => {
												message.success(response.data.message);

												fetchCard();
											})
											.catch((error) => {
												if (error.response && error.response.data) {
													notification.error({
														message: "Kesalahan!",
														description: error.response.data.message,
													});
												} else {
													notification.error({
														message: "Kesalahan!",
														description: error.message,
													});
												}
											});
									}}
									okText="Ya"
									cancelText="Tidak">
									<Button size="small" danger type="default" style={{ marginLeft: 8 }}>
										Putuskan
									</Button>
								</Popconfirm>
							</>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "end",
									gap: 8,
								}}>
								<Select
									style={{
										width: "100%",
									}}
									placeholder="Pilih Tabungan"
									showSearch
									optionFilterProp="children"
									options={pockets.map((p) => ({
										value: p._id,
										label: `[${p.number}] ${p.name}`,
									}))}
									onChange={(value) => {
										setPocketId(value);
									}}
									size="small"
									virtual={true}
								/>
								<Button
									type="primary"
									size="small"
									onClick={() => {
										if (!pocketId) {
											notification.error({
												message: "Kesalahan!",
												description: "Pilih tabungan!",
											});
											return;
										}

										requestLinkPocketToCard(pocketId, card.number)
											.then((response) => {
												message.success(response.data.message);

												fetchCard();
											})
											.catch((error) => {
												if (error.response && error.response.data) {
													notification.error({
														message: "Kesalahan!",
														description: error.response.data.message,
													});
												} else {
													notification.error({
														message: "Kesalahan!",
														description: error.message,
													});
												}
											});
									}}>
									Hubungkan
								</Button>
							</div>
						)}
					</Descriptions.Item>
				</Descriptions>
			</Modal>
		</>
	);
};

export default CardDetailModal;
