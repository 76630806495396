import {
	Button,
	Descriptions,
	Form,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
} from "antd";
import { requestWithdrawStore } from "../../../../services/admin.service";
import { useEffect, useState } from "react";

export default function StoreWithdrawalFormModal({ open, store, onSuccess, onClose }) {
	const [isLoading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				requestWithdrawStore(store._id, values.amount)
					.then((response) => {
						message.success(response.data.message);

						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (!open) {
			form.resetFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title="Tarik Tunai"
				open={open}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan melakukan penarikan?"
						okText="Ya"
						cancelText="Batal">
						<Button loading={isLoading} type="primary">
							Yakin
						</Button>
					</Popconfirm>,
				]}>
				{store && (
					<Form form={form} layout="vertical" requiredMark="optional">
						<Descriptions size="small" column={1} bordered layout="horizontal">
							<Descriptions.Item label="Nama">{store.name}</Descriptions.Item>
							<Descriptions.Item label="Saldo">
								{Intl.NumberFormat("id-ID", {
									style: "currency",
									currency: "IDR",
									minimumFractionDigits: 0,
								}).format(store.balance)}
							</Descriptions.Item>
						</Descriptions>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							required
							label="Jumlah"
							name="amount"
							rules={[
								{
									required: true,
									message: "Masukkan jumlah penarikan!",
								},
								{
									type: "number",
									message: "Masukkan angka!",
								},
								{
									validator: (rule, value) => {
										if (value < 10000) {
											return Promise.reject("Minimal 10.000!");
										}

										return Promise.resolve();
									},
								},
								{
									validator: (rule, value) => {
										if (value > store.balance) {
											return Promise.reject("Saldo tidak mencukupi!");
										}

										return Promise.resolve();
									},
								},
								{
									validator: (rule, value) => {
										if (value > store.limit) {
											return Promise.reject("Melebihi limit harian!");
										}

										return Promise.resolve();
									},
								},
							]}>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								step={1000}
								formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value.replace(/Rp\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Form>
				)}
			</Modal>
		</>
	);
}
