/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ExclamationCircleFilled,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  requestDeleteAnnouncement,
  requestGetAnnouncementList,
} from "../../../services/admin.service";
import AnnouncementFormModal from "./components/AnnouncementFormModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    title: "Judul",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Kadaluarsa",
    dataIndex: "expiredAt",
    key: "expiredAt",
  },
  {
    title: "Aksi",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];

const otherButtons = [
  {
    key: "edit",
    label: "Edit",
    icon: <HiOutlinePencil />,
  },
  {
    type: "divider",
  },
  {
    key: "delete",
    label: "Hapus",
    icon: <HiOutlineTrash />,
    danger: true,
  },
];

const AnnouncementPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { message, notification, modal } = App.useApp();

  useLayoutEffect(() => {
    document.title = "Ruang Admin | Publikasi | Pengumuman";
  }, []);

  const [announcements, setAnnouncements] = useState([]);
  const [mappedAnnouncements, setMappedAnnouncements] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [announcement, setAnnouncement] = useState(null);
  const [isAnnouncementFormVisible, setAnnouncementFormVisible] =
    useState(false);

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({
    startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
    endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [sorter, setSorter] = useState({
    _id: "desc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow startedAt and endedAt
      Object.keys(filter).forEach((key) => {
        if (key === "startedAt" || key === "endedAt") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        _id: "desc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    fetchAnnouncementList();
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, []);

  const fetchAnnouncementList = () => {
    setLoading(true);

    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetAnnouncementList({
      page: page,
      limit: limit,
      order: tempSorter ?? "_id:desc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTotal(response.data.pagination.total);
        setAnnouncements(response.data.data);
        updateMappedAnnouncement(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMappedAnnouncement = (data) => {
    setMappedAnnouncements(
      data.map((d) => ({
        key: d._id,
        title: d.title,
        description: d.description,
        expiredAt: dayjs(d.expiredAt).format("DD MMMM YYYY"),
        actions: (
          <Space>
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: (e) => {
                  if (e.key === "edit") {
                    handleEditButton(d);
                  } else if (e.key === "delete") {
                    modal.confirm({
                      title: "Sebentar",
                      icon: <ExclamationCircleFilled />,
                      content:
                        "Anda yakin akan menghapus pengumuman ini? Tindakan ini tidak dapat dibatalkan..",
                      okText: "Ya",
                      okType: "danger",
                      cancelText: "Tidak",
                      onOk() {
                        handleDeleteButton(d._id);
                      },
                      onCancel() {},
                    });
                  }
                },
                items: otherButtons,
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        ),
      })),
    );
  };

  const handleAddButton = () => {
    setAnnouncementFormVisible(true);
  };

  const handleEditButton = (announcement) => {
    setAnnouncement(announcement);
    setAnnouncementFormVisible(true);
  };

  const handleDeleteButton = (id) => {
    setLoading(true);
    requestDeleteAnnouncement(id)
      .then((response) => {
        if (response.data.code === 200) {
          message.success(response.data.message);

          fetchAnnouncementList();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseAnnouncementForm = () => {
    setAnnouncement(null);
    setAnnouncementFormVisible(false);
  };

  const handleOnSuccessAnnouncementForm = () => {
    setAnnouncement(null);
    setAnnouncementFormVisible(false);
    fetchAnnouncementList();
  };

  return (
    <>
      <AnnouncementFormModal
        isLoading={isLoading}
        isVisible={isAnnouncementFormVisible}
        onLoading={(v) => setLoading(v)}
        announcement={announcement}
        onClose={handleOnCloseAnnouncementForm}
        onSuccess={handleOnSuccessAnnouncementForm}
      />
      <Card
        bordered={false}
        title="Daftar Pengumuman"
        extra={
          <Space>
            <SearchInput
              placeholder="Judul pengumuman"
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
            <Button
              icon={<PlusCircleFilled />}
              type="primary"
              onClick={handleAddButton}
            >
              <span className="ant-btn-text">Tambah</span>
            </Button>
          </Space>
        }
      >
        <div>
          <Table
            bordered
            columns={columns}
            loading={isLoading}
            dataSource={mappedAnnouncements}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onChange={handlePaginate}
          />
        </div>
      </Card>
    </>
  );
};

export default AnnouncementPage;
