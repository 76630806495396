import axios from "axios";

// CURRICULUM
export async function requestGetCurriculumList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/curriculums?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// CURRICULUM

// YEAR
export async function requestGetYearList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/years?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGetActiveYear() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/years/active`);
}
// YEAR

// CLASSROOM
export async function requestGetClassroomList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/classrooms?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowClassroom(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/classrooms/${id}`);
}

export async function requestMoveStudent(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/classrooms/${id}/students/move`,
		payload
	);
}
// CLASSROOM

// LESSON
export async function requestGetLessonList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/lessons?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowLesson(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}`);
}

export async function requestScheduleLesson(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}/schedule`, data);
}

export async function requestEditLesson(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}/edit`, data);
}
// LESSON

// EXTRACURRICULAR
export async function requestGetExtracurricularList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/extracurriculars?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExtracurricular(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/extracurriculars/${id}`);
}

export async function requestEnrollStudentsToExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/extracurriculars/${id}/students/enroll`,
		data
	);
}

export async function requestUnenrollStudentsFromExtracurricular(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/extracurriculars/${id}/students/unenroll`,
		data
	);
}

export async function requestScheduleExtracurricular(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/extracurriculars/${id}/schedule`,
		payload
	);
}
// EXTRACURRICULAR

// STUDENT
export async function unenrollStudents(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/students/unenroll`, data);
}
// STUDENT

// EVENT
export async function requestGetEventList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/events?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowEvent(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/events/${id}`);
}

export async function requestAddEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/add`, data);
}

export async function requestReportEvent(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/${id}/report`, data);
}
// EVENT

// REPORT-ASSESSMENT
export async function requestGetReportAssessmentList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-assessments?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// REPORT-ASSESSMENT

// REPORT-CARD
export async function requestGetReportCardList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-cards?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGenerateReportCards(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/generate`,
		data
	);
}

export async function requestShowReportCard(id) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}`
	);
}

export async function requestEditReportCard(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}/edit`,
		data
	);
}

export async function requestDeleteReportCard(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}/delete`
	);
}

export async function requestSignReportCard(payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/sign`,
		payload
	);
}

export async function requestPrintReportCard(id, type) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}/print?type=${type}`,
		{},
		{
			responseType: "blob",
		}
	);
}
// REPORT-CARD

// REPORT-MARK
export async function requestGetReportMarkList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-marks?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestEditReportMark(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-marks/${id}/edit`,
		data
	);
}

export async function requestSignReportMarks(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-marks/sign`,
		data
	);
}
// REPORT-MARK

// REPORT-EXTRACURRICULAR-MARK
export async function requestGetReportExtracurricularMarkList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-extracurricular-marks?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestEditReportExtracurricularMark(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-extracurricular-marks/${id}/edit`,
		data
	);
}

export async function requestSignReportExtracurricularMarks(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-extracurricular-marks/sign`,
		data
	);
}
// REPORT-EXTRACURRICULAR-MARK

// ATTENDANCE REPORT
export async function requestGetAttendanceReportList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@attendance-module/attendance-reports?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowAttendanceReport(id) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/teacher/@attendance-module/attendance-reports/${id}`
	);
}

export async function requestAddAttendanceReport(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@attendance-module/attendance-reports/add`,
		data
	);
}

export async function requestEditAttendanceReport(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@attendance-module/attendance-reports/${id}/edit`,
		data
	);
}
// ATTENDANCE REPORT

// EXAM
export async function requestGetExamList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@exam-module/exams?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExam(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}`);
}

export async function requestAddExam(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/add`, data);
}

export async function requestEditExam(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/edit`,
		data
	);
}

export async function requestPublishExam(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/publish`
	);
}

export async function requestArchiveExam(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/archive`
	);
}

export async function requestLinkExam(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/link`,
		payload
	);
}

export async function requestUnlinkExam(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/unlink`
	);
}

export async function requestScheduleExam(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/schedule`,
		payload
	);
}

export async function requestGenerateExamToken(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/generate-token`
	);
}

export async function requestDeleteExamToken(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/delete-token`
	);
}

export async function requestDeleteExam(id) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/delete`
	);
}

export async function requestAddQuestion(id, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/add`,
		payload
	);
}

export async function requestEditQuestion(id, questionId, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/${questionId}/edit`,
		payload
	);
}

export async function requestDeleteQuestion(id, questionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/${questionId}/delete`
	);
}

export async function requestAddQuestionOption(id, questionId, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/${questionId}/options/add`,
		payload
	);
}

export async function requestEditQuestionOption(id, questionId, optionId, payload) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/${questionId}/options/${optionId}/edit`,
		payload
	);
}

export async function requestDeleteQuestionOption(id, questionId, optionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/questions/${questionId}/options/${optionId}/delete`
	);
}

export async function requestGetExamSubmissionList(
	id,
	{ page = 1, limit = 10, order = "_id:desc", keyword = "", filter = "" }
) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@exam-module/exams/${id}/submissions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowExamSubmission(id, submissionId) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/submissions/${submissionId}`
	);
}

export async function requestMarkExamSubmission(id, submissionId, payload) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/submissions/${submissionId}/mark`,
		payload
	);
}

export async function requestSyncExamSubmission(id, submissionId) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/submissions/${submissionId}/sync`
	);
}

export async function requestDeleteExamSubmission(id, submissionId) {
	return await axios.delete(
		`${process.env.REACT_APP_API_URL}/teacher/@exam-module/exams/${id}/submissions/${submissionId}/delete`
	);
}
// EXAM
