import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	Form,
	Input,
	message,
	Modal,
	notification,
	Popconfirm,
	Radio,
	Row,
	Space,
	Spin,
	Table,
	Tooltip,
	Typography,
} from "antd";
import {
	requestEditReportCard,
	requestPrintReportCard,
	requestShowReportCard,
	requestSignReportCard,
} from "../../../../services/admin.service";
import {
	ArrowDownOutlined,
	ArrowRightOutlined,
	ArrowUpOutlined,
	CheckOutlined,
	FilePdfOutlined,
	FileWordOutlined,
	Loading3QuartersOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const ClassroomReportCardDetailModal = ({ isVisible, reportCardId, onCancel }) => {
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [isSigning, setIsSigning] = useState(false);
	const [isPrinting, setIsPrinting] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [reportCard, setReportCard] = useState(null);
	const [dataSource, setDataSource] = useState([]);
	const [columns, setColumns] = useState([]);
	const [marks, setMarks] = useState([]);
	const [assessments, setAssessments] = useState([]);
	const [extracurricularMarks, setExtracurricularMarks] = useState([]);
	const [attendanceSummary, setAttendanceSummary] = useState(null);

	const note = Form.useWatch("note", form);
	const action = Form.useWatch("action", form);

	useEffect(() => {
		if (isVisible && reportCardId) {
			fetchReportCardDetail();
		} else {
			setReportCard(null);
			setDataSource([]);
			form.resetFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, reportCardId]);

	useEffect(() => {
		if (reportCard) {
			if (reportCard.attendance) {
				setAttendanceSummary([
					{
						key: "absent",
						status: "absent",
						total: reportCard.attendance.absent,
					},
					{
						key: "present",
						status: "present",
						total: reportCard.attendance.present,
					},
					{
						key: "illness",
						status: "illness",
						total: reportCard.attendance.illness,
					},
					{
						key: "leave",
						status: "leave",
						total: reportCard.attendance.leave,
					},
					{
						key: "late",
						status: "late",
						total: reportCard.attendance.late,
					},
				]);
			} else {
				const attendances = reportCard.attendances;
				const summary = attendances.reduce(
					(acc, attendance) => {
						if (attendance.status === "EXCUSED" && attendance.permission.type === "ILLNESS") {
							attendance.status = "illness";
						} else if (attendance.status === "EXCUSED" && attendance.permission.type === "LEAVE") {
							attendance.status = "leave";
						}
						acc[attendance.status.toLowerCase()] += 1;
						return acc;
					},
					{
						absent: 0,
						present: 0,
						illness: 0,
						leave: 0,
						late: 0,
					}
				);

				setAttendanceSummary(
					Object.entries(summary).map(([status, total]) => ({
						key: status,
						status,
						total,
					}))
				);
			}
		} else {
			setAttendanceSummary(null);
		}
	}, [reportCard]);

	const fetchReportCardDetail = async () => {
		setIsLoading(true);

		requestShowReportCard(reportCardId)
			.then((response) => {
				const marks = response.data.data.marks;
				const assessments = response.data.data.marks[0]?.assessments ?? [];

				setMarks(marks);
				setAssessments(assessments);

				const refs = {};

				for (const mark of marks) {
					for (const assessment of assessments) {
						if (refs[`${mark._id}-${assessment._id}`] === undefined) {
							refs[`${mark._id}-${assessment._id}`] = React.createRef();
						}
					}
				}

				// map into data source
				const dataSource = response.data.data.marks.map((mark) => ({
					key: mark._id,
					...mark,
					...response.data.data.marks[0].assessments.reduce((acc, assessment) => {
						acc[`mark_${assessment._id}`] = mark.assessments.find(
							(a) => a._id === assessment._id
						)?.score;
						return acc;
					}, {}),
				}));

				const newColumns = [
					{
						title: "No",
						dataIndex: "no",
						key: "no",
						render: (text, record, index) => index + 1,
						width: 50,
						align: "center",
					},
					{
						title: "Mata Pelajaran",
						dataIndex: "subject",
						key: "subject",
						render: (text, record) => record.lesson.subject.name,
					},
					...response.data.data?.marks[0]?.assessments.map((assessment) => ({
						title: `${assessment.name} (${assessment.weight * 100}%)`,
						dataIndex: `mark_${assessment._id}`,
						key: `mark_${assessment._id}`,
						align: "center",
						width: 100,
					})),
					// {
					// 	title: "Total",
					// 	dataIndex: "total",
					// 	key: "total",
					// 	align: "center",
					// 	width: 80,
					// 	render: (text, record) => {
					// 		const score = record.score;
					// 		if (!score)
					// 			return (
					// 				<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
					// 					<Space>
					// 						<Loading3QuartersOutlined
					// 							style={{
					// 								color: "orange",
					// 							}}
					// 							spin
					// 						/>
					// 					</Space>
					// 				</Tooltip>
					// 			);
					// 		const total = score.total;
					// 		return total;
					// 	},
					// },
					// {
					// 	title: "Rata-rata",
					// 	dataIndex: "average",
					// 	key: "average",
					// 	align: "center",
					// 	width: 80,
					// 	render: (text, record) => {
					// 		const score = record.score;
					// 		if (!score)
					// 			return (
					// 				<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
					// 					<Space>
					// 						<Loading3QuartersOutlined
					// 							style={{
					// 								color: "orange",
					// 							}}
					// 							spin
					// 						/>
					// 					</Space>
					// 				</Tooltip>
					// 			);
					// 		const average = score.average;
					// 		return average;
					// 	},
					// },
					{
						title: "KKM",
						dataIndex: "threshold",
						key: "threshold",
						align: "center",
						width: 80,
						render: (text, record) => {
							const threshold = record.lesson.threshold ?? 0;
							return threshold;
						},
					},
					{
						title: "NA",
						dataIndex: "final",
						key: "final",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const final = score.final;
							return final;
						},
					},
					// classification
					{
						title: "Klasifikasi",
						dataIndex: "classification",
						key: "classification",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const classification = score.classification;
							return classification;
						},
					},
					// competency
					{
						title: "Keterangan",
						dataIndex: "competency",
						key: "competency",
						width: 240,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh pengajar`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const competency = score.competency;
							return competency;
						},
					},
				];

				// add data source below for total
				// dataSource.push({
				// 	key: "total",
				// 	lesson: {
				// 		subject: {
				// 			name: "Total",
				// 		},
				// 	},
				// 	...response.data.data.marks[0].assessments.reduce((acc, assessment) => {
				// 		acc[`mark_${assessment._id}`] = response.data.data.marks.reduce(
				// 			(sum, mark) =>
				// 				sum + (mark.assessments.find((a) => a._id === assessment._id)?.score || 0),
				// 			0
				// 		);
				// 		return acc;
				// 	}, {}),
				// 	score: {
				// 		total: response.data.data.marks.reduce((sum, mark) => sum + mark.score.total, 0),
				// 		average: response.data.data.marks.reduce((sum, mark) => sum + mark.score.average, 0),
				// 		final: response.data.data.marks.reduce((sum, mark) => sum + mark.score.final, 0),
				// 		classification: "",
				// 		competency: "",
				// 	},
				// });

				setColumns(newColumns);
				setDataSource(dataSource);
				setExtracurricularMarks(response.data.data.extracurricularMarks);
				setReportCard(response.data.data);
				form.setFieldsValue({
					note: response.data.data.note,
					action: response.data.data.action,
				});
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: error?.response?.data?.message || "Something went wrong",
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleClose = () => {
		setReportCard(null);
		onCancel();
	};

	const handleNoteSubmit = (note) => {
		setIsSaving(true);
		requestEditReportCard(reportCardId, { note: note })
			.then((response) => {
				message.success(response.data.message);
				fetchReportCardDetail();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	const handleActionSubmit = (action) => {
		setIsSaving(true);
		requestEditReportCard(reportCardId, { action: action })
			.then((response) => {
				message.success(response.data.message);
				fetchReportCardDetail();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	const handleSign = () => {
		setIsSigning(true);
		requestSignReportCard({
			ids: [reportCardId],
		})
			.then((response) => {
				message.success(response.data.message);
				fetchReportCardDetail();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsSigning(false);
			});
	};

	const handlePrint = (type) => {
		setIsPrinting(true);
		requestPrintReportCard(reportCardId, type)
			.then((response) => {
				const timestamp = dayjs().format("YYYYMMDDHHmmss");

				if (type === "pdf") {
					const file = new Blob([response.data], { type: "application/pdf" });
					const fileURL = URL.createObjectURL(file);
					const a = document.createElement("a");
					a.href = fileURL;
					a.download = `Rapor-${reportCard.student.number}-${reportCard.student.name}-${timestamp}.pdf`;
					a.click();
				} else {
					const file = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
					});
					const fileURL = URL.createObjectURL(file);
					const a = document.createElement("a");
					a.href = fileURL;
					a.download = `Rapor-${reportCard.student.number}-${reportCard.student.name}-${timestamp}.docx`;
					a.click();
				}
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: error?.response?.data?.message || "Something went wrong",
				});
			})
			.finally(() => {
				setIsPrinting(false);
			});
	};

	return (
		<Modal
			maskClosable={false}
			width={"calc(100% - 64px)"}
			style={{
				top: 32,
			}}
			title={"Detail Buku Rapor"}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Tutup
				</Button>,
				<Dropdown
					key={2}
					menu={{
						items: [
							{
								key: "1",
								label: "PDF",
								icon: <FilePdfOutlined />,
								onClick: () => {
									handlePrint("pdf");
								},
							},
							{
								key: "2",
								label: "DOCX",
								icon: <FileWordOutlined />,
								onClick: () => {
									handlePrint("docx");
								},
							},
						],
					}}
					placement="top">
					<Button loading={isPrinting} type="primary">
						Cetak
					</Button>
				</Dropdown>,
				<Popconfirm
					key={3}
					placement="topLeft"
					title="Apakah Anda yakin ingin menandatangani rapor ini? Setelah ditandatangani, Anda tidak dapat mengubahnya lagi."
					onConfirm={handleSign}
					okText="Ya"
					cancelText="Tidak">
					<Button
						loading={isSigning}
						type="primary"
						disabled={
							reportCard?.signature?.advisor?._id && reportCard?.signature?.headmaster?._id
						}>
						{reportCard?.signature?.advisor?._id && reportCard?.signature?.headmaster?._id
							? "Rapor sudah ditandatangani"
							: "Tanda tangani Rapor"}
					</Button>
				</Popconfirm>,
			]}>
			<Spin spinning={isLoading}>
				{reportCard && dataSource && dataSource.length > 0 && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col span={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Data Siswa" size="small">
									<Descriptions size="small" layout="horizontal" column={3} bordered>
										<Descriptions.Item label="NISN">
											{reportCard?.student?.number}
										</Descriptions.Item>
										<Descriptions.Item label="Nama">{reportCard?.student?.name}</Descriptions.Item>
										<Descriptions.Item label="Jenis Kelamin">
											{reportCard?.student?.gender === "MALE" ? "Laki-laki" : "Perempuan"}
										</Descriptions.Item>
										<Descriptions.Item label="Kelas">
											{reportCard?.classroom?.name}
										</Descriptions.Item>
										<Descriptions.Item label="Thn Ajaran">
											{reportCard?.year?.name}
										</Descriptions.Item>
										<Descriptions.Item label="Semester">
											{reportCard?.semester?.name}
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card title="Intrakurikuler" size="small">
									<Table
										size="small"
										bordered
										scroll={{ x: "max-content" }}
										columns={columns}
										pagination={false}
										dataSource={dataSource}
										summary={(pageData) => {
											let final = 0;

											pageData.forEach((record) => {
												// final += record.score.final;
												// check is valid number
												if (!isNaN(record.score.final)) {
													final += record.score.final;
												}
											});

											return (
												<>
													<Table.Summary.Row>
														<Table.Summary.Cell
															align="center"
															index={0}
															colSpan={assessments.length + 2}>
															<Typography.Text strong>Jumlah</Typography.Text>
														</Table.Summary.Cell>
														<Table.Summary.Cell colSpan={3} align="center">
															<Typography.Text strong>
																{final % 1 === 0 ? final : final.toFixed(2)}
															</Typography.Text>
														</Table.Summary.Cell>
														<Table.Summary.Cell colSpan={2} align="center"></Table.Summary.Cell>
													</Table.Summary.Row>
													<Table.Summary.Row>
														<Table.Summary.Cell
															align="center"
															index={0}
															colSpan={assessments.length + 2}>
															<Typography.Text strong>Rata-rata</Typography.Text>
														</Table.Summary.Cell>
														<Table.Summary.Cell colSpan={3} align="center">
															<Typography.Text strong>
																{(final / marks.length) % 1 === 0
																	? final / marks.length
																	: (final / marks.length).toFixed(2)}
															</Typography.Text>
														</Table.Summary.Cell>
														<Table.Summary.Cell colSpan={2} align="center"></Table.Summary.Cell>
													</Table.Summary.Row>
												</>
											);
										}}
									/>
								</Card>
								<Card title="Ekstrakurikuler" size="small">
									<Table
										size="small"
										bordered
										scroll={{ x: "max-content" }}
										columns={[
											{
												title: "No",
												dataIndex: "no",
												key: "no",
												render: (text, record, index) => index + 1,
												width: 50,
												align: "center",
											},
											{
												title: "Nama",
												dataIndex: "name",
												key: "name",
												render: (text, record) => <Typography.Text>{record.name}</Typography.Text>,
											},
											{
												title: "Predikat",
												dataIndex: "grade",
												key: "grade",
												width: 40,
												align: "center",
												render: (text, record) => (
													<Typography.Text>{record.grade ?? "-"}</Typography.Text>
												),
											},
											{
												title: "Catatan",
												dataIndex: "note",
												key: "note",
												render: (text, record) => {
													return <Typography.Text>{record.note ?? "-"}</Typography.Text>;
												},
											},
										]}
										pagination={false}
										dataSource={[
											...extracurricularMarks.map((mark) => ({
												key: mark._id,
												...mark,
											})),
										]}
									/>
								</Card>
								{/* Attendances Table */}
								<Card title="Absensi" size="small">
									<Table
										size="small"
										bordered
										scroll={{ x: "max-content" }}
										columns={[
											{
												title: "No",
												dataIndex: "no",
												key: "no",
												render: (text, record, index) => index + 1,
												width: 50,
												align: "center",
											},
											{
												title: "Status",
												dataIndex: "status",
												key: "status",
												render: (text, record) => {
													switch (record.status) {
														case "absent":
															return "Tanpa Keterangan";
														case "present":
															return "Hadir";
														case "illness":
															return "Izin Sakit";
														case "leave":
															return "Izin Pergi";
														case "late":
															return "Terlambat";
														default:
															return record.status;
													}
												},
											},
											{
												title: "Jumlah",
												dataIndex: "total",
												key: "total",
												render: (text, record) => `${record.total} hari`,
											},
										]}
										pagination={false}
										dataSource={attendanceSummary
											?.filter(
												(attendance) =>
													attendance.status !== "present" && attendance.status !== "late"
											)
											.sort((a, b) => {
												const order = ["present", "late", "illness", "leave", "absent"];
												return order.indexOf(a.status) - order.indexOf(b.status);
											})}
									/>
								</Card>

								<Form
									form={form}
									layout="vertical"
									requiredMark="optional"
									style={{
										display: "flex",
										flexDirection: "column",
										gap: 16,
									}}>
									<Card title="Catatan" size="small">
										<Form.Item
											style={{ marginTop: 0, marginBottom: 0 }}
											name="note"
											rules={[
												{
													required: true,
													message: "Catatan tidak boleh kosong",
												},
											]}>
											<Input.TextArea
												disabled={
													reportCard?.signature?.advisor._id ||
													reportCard?.signature?.headmaster._id
												}
												placeholder="Catatan untuk wali murid"
												autoSize={{ minRows: 3, maxRows: 6 }}
											/>
										</Form.Item>
										{note && note !== reportCard.note && (
											<div
												style={{
													display: "flex",
													justifyContent: "flex-end",
													alignItems: "center",
												}}>
												<Button
													loading={isSaving}
													type="primary"
													style={{
														marginTop: 8,
													}}
													onClick={() => {
														handleNoteSubmit(note);
													}}>
													Simpan
													<CheckOutlined />
												</Button>
											</div>
										)}
									</Card>
									{reportCard.semester.name === "Genap" && (
										<Card title="Aksi yang diambil" size="small">
											<Form.Item
												style={{ marginBottom: 0 }}
												name="action"
												rules={[
													{
														required: true,
														message: "Aksi yang diambil tidak boleh kosong",
													},
												]}>
												<Radio.Group
													buttonStyle="solid"
													disabled={
														reportCard?.signature?.advisor._id ||
														reportCard?.signature?.headmaster._id
													}
													onChange={(e) => {
														handleActionSubmit(e.target.value);
													}}>
													<Radio.Button value="PROMOTE">
														{action === "PROMOTE" && isSaving ? (
															<LoadingOutlined spin />
														) : (
															<ArrowUpOutlined />
														)}
														Naik Kelas
													</Radio.Button>
													<Radio.Button value="RETAIN">
														{action === "RETAIN" && isSaving ? (
															<LoadingOutlined spin />
														) : (
															<ArrowRightOutlined />
														)}
														Tetap di Kelas
													</Radio.Button>
													<Radio.Button value="DEMOTE">
														{action === "DEMOTE" && isSaving ? (
															<LoadingOutlined spin />
														) : (
															<ArrowDownOutlined />
														)}
														Turun Kelas
													</Radio.Button>
													<Radio.Button value="GRADUATE">
														{action === "GRADUATE" && isSaving ? (
															<LoadingOutlined spin />
														) : (
															<CheckOutlined />
														)}
														Lulus (Tidak ada kelas berikutnya)
													</Radio.Button>
												</Radio.Group>
											</Form.Item>
										</Card>
									)}
								</Form>
								<Card title="Tanda Tangan" size="small">
									<Table
										size="small"
										bordered
										scroll={{ x: "max-content" }}
										pagination={false}
										columns={[
											{
												title: "Wali Murid",
												dataIndex: "parent",
												key: "parent",
												width: "33%",
												align: "center",
												render: (text, record) => (
													<Space direction="vertical">
														<div
															style={{
																height: 96,
															}}
														/>
														<Typography.Text strong underline>
															{reportCard.student?.parent?.guardian?.name ?? "N/a"}
														</Typography.Text>
													</Space>
												),
											},
											{
												title: "Wali Kelas",
												dataIndex: "advisor",
												key: "advisor",
												width: "33%",
												align: "center",
												render: (text, record) => (
													<Space direction="vertical">
														{reportCard.signature?.advisor && (
															<>
																<img
																	src={`data:image/png;base64,${reportCard.signature?.advisor?.image}`}
																	alt="Wali Kelas"
																	style={{
																		height: 96,
																	}}
																/>
																<Typography.Text strong underline>
																	{reportCard.signature?.advisor?.name}
																</Typography.Text>
															</>
														)}
													</Space>
												),
											},
											{
												title: "Kepala Sekolah",
												dataIndex: "headmaster",
												key: "headmaster",
												width: "33%",
												align: "center",
												render: (text, record) => (
													<Space direction="vertical">
														{reportCard.signature?.headmaster && (
															<>
																<img
																	src={`data:image/png;base64,${reportCard.signature?.headmaster?.image}`}
																	alt="Kepala Sekolah"
																	style={{
																		height: 96,
																	}}
																/>
																<Typography.Text strong underline>
																	{reportCard.signature?.headmaster?.name}
																</Typography.Text>
															</>
														)}
													</Space>
												),
											},
										]}
										dataSource={[{ key: 1 }]}
									/>
								</Card>
							</Space>
						</Col>
					</Row>
				)}
			</Spin>
		</Modal>
	);
};

export default ClassroomReportCardDetailModal;
