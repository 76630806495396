import { Button, Descriptions, Form, Input, message, Modal, notification, Popconfirm } from "antd";
import { useState } from "react";
import { requestUpdatePocketTransactionStatus } from "../../../../services/admin.service";

export default function PocketTransactionConfirmationFormModal({
	isVisible,
	transaction,
	onSuccess,
	onClose,
}) {
	const [isLoading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			setLoading(true);
			requestUpdatePocketTransactionStatus({
				ids: [transaction.id],
				status: "COMPLETED",
			})
				.then((response) => {
					message.success(response.data.message);

					onSuccess();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification.error({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification.error({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			title="Apakah Anda sudah menyerahkan uang kepada siswa? bila sudah sistem akan menandai transaksi ini sebagai selesai."
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Belum
				</Button>,
				<Popconfirm
					key={2}
					onConfirm={handleSubmit}
					title="Apakah Anda yakin ingin mengkonfirmasi transaksi ini sudah selesai?"
					okText="Iya"
					cancelText="Batal">
					<Button loading={isLoading} type="primary">
						Sudah
					</Button>
				</Popconfirm>,
			]}>
			{transaction && (
				<>
					<Descriptions size="small" column={1} bordered>
						<Descriptions.Item label="Kategori">{transaction.category}</Descriptions.Item>
						<Descriptions.Item label="Jumlah">
							{Intl.NumberFormat("id-ID", {
								style: "currency",
								currency: "IDR",
								minimumFractionDigits: 0,
							}).format(transaction.amount)}
						</Descriptions.Item>
					</Descriptions>
					<Form form={form} layout="vertical">
						<Form.Item name="id" label="ID Transaksi" initialValue={transaction._id} hidden>
							<Input />
						</Form.Item>
					</Form>
				</>
			)}
		</Modal>
	);
}
