/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  requestGenerateReportCards,
  requestGetCurriculumList,
} from "../../../../services/teacher.service";

const ClassroomReportCardFormModal = ({
  isVisible,
  classroom,
  onClose,
  onSuccess,
}) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  const [curriculums, setCurriculums] = useState([]);

  useEffect(() => {
    if (isVisible) {
      fetchCurriculums();
    } else {
      setCurriculums([]);
    }
  }, [isVisible]);

  const fetchCurriculums = () => {
    setLoading(true);
    requestGetCurriculumList({
      page: 1,
      limit: 100,
    })
      .then((response) => {
        setCurriculums(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification["error"]({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification["error"]({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.classroomId = classroom._id;
        values.weight = values.weight / 100;
        setLoading(true);

        requestGenerateReportCards(values)
          .then((response) => {
            message.success("Buku rapor berhasil dibuat");
            form.resetFields();
            onSuccess();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              notification["error"]({
                message: "Kesalahan!",
                description: error.response.data.message,
              });
            } else {
              notification["error"]({
                message: "Kesalahan!",
                description: error.message,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={"Buat Buku Rapor"}
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data jenis penilaian?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button loading={isLoading} type="primary">
              {"Buat"}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Typography.Text
              type="secondary"
              style={{
                display: "block",
              }}
            >
              Pembuatan buku rapor hanya berlaku untuk{" "}
              <strong>tahun ajaran aktif</strong>. Proses ini akan membuat buku
              rapor untuk semua siswa yang terdaftar pada kelas berdasarkan
              kurikulum yang dipilih. Bila sekolah memiliki lebih dari satu
              kurikulum, maka buku rapor akan dibuat secara terpisah untuk
              setiap kurikulum. Bila buku rapor sudah pernah dibuat sebelumnya,
              proses ini <strong>tidak akan menggantikan</strong> buku rapor
              yang sudah ada. Mohon dipastikan, pelajaran telah diatur dengan
              benar sebelum membuat buku rapor.
            </Typography.Text>
            <Divider />
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="curriculumId"
              label="Kurikulum"
              required
              rules={[{ required: true, message: "Pilih kurikulum" }]}
              tooltip="Pilih kurikulum"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Pilih kuriulum"
                options={curriculums.map((curriculum) => ({
                  label: curriculum.name,
                  value: curriculum._id,
                }))}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="understand"
              valuePropName="checked"
              required
              rules={[
                {
                  required: true,
                  message: "Anda harus memahami peringatan ini",
                },
              ]}
            >
              <Checkbox style={{ marginTop: 12, marginBottom: 0 }}>
                Saya memahami bahwa proses ini tidak dapat dibatalkan
              </Checkbox>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ClassroomReportCardFormModal;
