/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Select,
	Spin,
} from "antd";
import React, { useEffect } from "react";
import { addFee, editFee } from "../../../../services/admin.service";

const FeeFormModal = ({ fee, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (fee !== null) {
			form.setFieldsValue({
				type: fee.type,
				name: fee.name,
				description: fee.description,
				amount: fee.amount,
			});
		}
	}, [fee]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (fee) {
					values.id = fee._id;
					editFee(values)
						.then((response) => {
							message.success(response.data.message);

							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addFee(values)
						.then((response) => {
							message.success(response.data.message);

							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={fee === null || fee === undefined ? "Tambah Biaya" : "Ubah Biaya"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data biaya?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{fee ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						{/* Type */}
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="type"
							label="Tipe"
							required
							rules={[{ required: true, message: "Pilih tipe biaya!" }]}
							tooltip="Masukkan tipe biaya">
							<Select style={{ width: "100%" }}>
								<Select.Option value="ONCE">Sekali</Select.Option>
								<Select.Option value="MONTHLY">Bulanan</Select.Option>
								<Select.Option value="SEMESTRIAL">Persemester</Select.Option>
								<Select.Option value="PERIODICAL">Periode</Select.Option>
								<Select.Option value="OTHER">Lainnya</Select.Option>
							</Select>
						</Form.Item>
						{/* Name */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[{ required: true, message: "Masukkan nama biaya!" }]}
							tooltip="Masukkan nama biaya">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						{/* Description */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							label="Deskripsi"
							required
							rules={[{ required: true, message: "Masukkan deskripsi biaya!" }]}
							tooltip="Masukkan deskripsi biaya">
							<Input.TextArea rows={3} style={{ width: "100%" }} />
						</Form.Item>
						{/* Amount */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="amount"
							label="Jumlah"
							required
							rules={[{ required: true, message: "Masukkan amount biaya!" }]}
							tooltip="Masukkan jumlah biaya">
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default FeeFormModal;
