/* eslint-disable react-hooks/exhaustive-deps */
import {
  App,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import {
  addCurriculum,
  editCurriculum,
} from "../../../../services/admin.service";
import {
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const CurriculumFormModal = ({
  curriculum,
  isVisible,
  isLoading,
  onLoading,
  onClose,
  onSuccess,
  subjects,
}) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();

  useEffect(() => {
    if (curriculum !== null) {
      form.setFieldsValue({
        name: curriculum.name,
        description: curriculum.description,
        subjectIds: curriculum.subjectIds,
        scale: curriculum.scale,
        classifications: curriculum.classifications || [],
      });
    }
  }, [curriculum]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLoading(true);
        if (curriculum) {
          editCurriculum(curriculum._id, values)
            .then((response) => {
              message.success(response.data.message);

              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        } else {
          addCurriculum(values)
            .then((response) => {
              message.success(response.data.message);
              form.resetFields();
              onSuccess();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.response.data.message,
                });
              } else {
                notification["error"]({
                  message: "Kesalahan!",
                  description: error.message,
                });
              }
            })
            .finally(() => {
              onLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        width={512}
        title={
          curriculum === null || curriculum === undefined
            ? "Tambah Kurikulum"
            : "Ubah Kurikulum"
        }
        open={isVisible}
        cancelText="Batal"
        onCancel={handleClose}
        footer={[
          <Button key={1} onClick={handleClose}>
            Batal
          </Button>,
          <Popconfirm
            key={2}
            onConfirm={handleSubmit}
            title="Yakin akan menyimpan data kurikulum?"
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary">{curriculum ? "Simpan" : "Tambah"}</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical" requiredMark="optional">
            <Form.Item
              style={{ marginTop: 0, marginBottom: 0 }}
              name="name"
              label="Nama"
              required
              rules={[
                {
                  required: true,
                  message: "Masukkan nama kurikulum",
                },
              ]}
              tooltip="Masukkan nama kurikulum"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            {/* description */}
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="description"
              label="Deskripsi"
              tooltip="Masukkan deskripsi kurikulum"
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
            {/* subjects */}
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="subjectIds"
              label="Mata Pelajaran"
              tooltip="Pilih mata pelajaran yang akan diikutkan dalam kurikulum"
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Pilih mata pelajaran"
                options={subjects.map((subject) => ({
                  label: subject.name,
                  value: subject._id,
                }))}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 12, marginBottom: 0 }}
              name="scale"
              label="Sistem Penilaian"
              required
              rules={[
                {
                  required: true,
                  message:
                    "Masukkan sistem penilaian yang akan digunakan dalam kurikulum",
                },
              ]}
              tooltip="Masukkan sistem penilaian yang akan digunakan dalam kurikulum"
            >
              <InputNumber
                style={{ width: "100%" }}
                min={100}
                max={100}
                placeholder="100"
              />
            </Form.Item>
            <Typography.Paragraph
              style={{
                marginTop: 12,
                marginBottom: 0,
                paddingBottom: 8,
              }}
            >
              Klasifikasi Nilai
              <Tooltip
                placement="top"
                title={"Klasifikasi nilai yang digunakan dalam kurikulum"}
              >
                <QuestionCircleOutlined
                  style={{
                    marginLeft: 4,
                    color: "rgba(0, 0, 0, 0.45)",
                  }}
                />
              </Tooltip>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  form.setFieldsValue({
                    classifications: [
                      {
                        key: "EXCELLENT",
                        label: "Sempurna",
                        threshold: 95,
                      },
                      {
                        key: "VERY_GOOD",
                        label: "Sangat Baik",
                        threshold: 85,
                      },
                      {
                        key: "GOOD",
                        label: "Baik",
                        threshold: 75,
                      },
                      {
                        key: "FAIR",
                        label: "Cukup",
                        threshold: 65,
                      },
                      {
                        key: "POOR",
                        label: "Kurang",
                        threshold: 55,
                      },
                      {
                        key: "VERY_POOR",
                        label: "Sangat Kurang",
                        threshold: 45,
                      },
                      {
                        key: "FAIL",
                        label: "Gagal",
                        threshold: 0,
                      },
                    ],
                  });
                }}
              >
                Gunakan template
              </Button>
            </Typography.Paragraph>
            <Form.List name="classifications">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row
                      key={key}
                      gutter={12}
                      style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: 12,
                      }}
                    >
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          rules={[
                            {
                              required: true,
                              message:
                                "Kata kunci wajib diisi tidak boleh ada spasi",
                            },
                          ]}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Input
                            placeholder="VERY_GOOD"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          {...restField}
                          name={[name, "label"]}
                          rules={[
                            {
                              required: true,
                              message: "Label wajib diisi",
                            },
                          ]}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Input
                            placeholder="Very Good"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "threshold"]}
                          rules={[
                            {
                              required: true,
                              message: "Batas nilai wajib",
                            },
                          ]}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <InputNumber
                            controls={false}
                            min={0}
                            placeholder="96"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="none" style={{ alignSelf: "center" }}>
                        <CloseOutlined
                          style={{
                            color: "red",
                          }}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Tambah
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default CurriculumFormModal;
