/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Empty,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Row,
	Select,
	Space,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect } from "react";
import {
	requestGetStudentList,
	requestAddInvoice,
	requestGetYearList,
	requestBillStudent,
} from "../../../../services/admin.service";
import AppContext from "../../../../store/app.context.tsx";
import InvoiceItemInput from "./InvoiceItemInput";
import dayjs from "dayjs";

const InvoiceFormModal = ({ invoice, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const context = useContext(AppContext);
	const [years, setYears] = React.useState([]);
	const [students, setStudents] = React.useState([]);
	const [fees, setFees] = React.useState([]);
	const [student, setStudent] = React.useState(null);
	const [year, setYear] = React.useState(null);
	const [semester, setSemester] = React.useState(null);

	const [form] = Form.useForm();
	const yearId = Form.useWatch("yearId", form);
	const semesterId = Form.useWatch("semesterId", form);
	const studentId = Form.useWatch("studentId", form);
	const date = Form.useWatch("date", form);
	const additionalFees = Form.useWatch("fees", form);
	const [items, setItems] = React.useState([]);

	const [subtotal, setSubtotal] = React.useState(0);
	const [additionalFee, setAdditionalFee] = React.useState(0);
	const [total, setTotal] = React.useState(0);
	const [keyword, setKeyword] = React.useState("");

	useEffect(() => {
		fetchYearList();
	}, []);

	useEffect(() => {
		if (isVisible && years.length > 0) {
			form.setFieldsValue({
				yearId: context.year._id,
			});
		}
	}, [years, isVisible]);

	useEffect(() => {
		setStudents([]);
		form.setFieldsValue({
			studentId: null,
			semesterId: null,
		});

		if (yearId) {
			fetchStudentList(`year:${yearId},status:ACTIVE`);
			setYear(years.find((year) => year._id === yearId));
		}
	}, [yearId]);

	useEffect(() => {
		if (yearId) {
			fetchStudentList(`year:${yearId},status:ACTIVE`);
		}
	}, [keyword]);

	useEffect(() => {
		form.setFieldValue("date", null);

		if (semesterId) {
			const semester = year.semesters.find((semester) => semester._id === semesterId);
			setSemester(semester);
			form.setFieldValue("date", dayjs(semester.startedAt));
		}
	}, [semesterId]);

	useEffect(() => {
		if (studentId) {
			const selectedStudent = students.find((student) => student._id === studentId);
			setStudent(selectedStudent);
		} else {
			setStudent(null);
		}
	}, [studentId]);

	useEffect(() => {
		if (year && semester && student && date) {
			fetchStudentBill();
		}
	}, [year, semester, student, date]);

	useEffect(() => {
		setSubtotal(
			(items ?? []).reduce((acc, cur) => {
				return acc + cur.amount;
			}, 0)
		);
	}, [items]);

	useEffect(() => {
		setAdditionalFee(
			(additionalFees ?? [])
				.filter((fee) => fee && fee.amount && fee.name)
				.reduce((acc, cur) => {
					return acc + cur.amount;
				}, 0)
		);
	}, [additionalFees]);

	useEffect(() => {
		setTotal(subtotal + additionalFee);
	}, [subtotal, additionalFee]);

	const fetchStudentList = (filter) => {
		onLoading(true);

		requestGetStudentList({
			keyword: keyword,
			page: 1,
			limit: 10,
			filter: filter,
		})
			.then((response) => {
				// merge unique data
				const uniqueData = [...students, ...response.data.data];
				const uniqueStudents = [...new Map(uniqueData.map((item) => [item["_id"], item])).values()];
				const sortedStudents = uniqueStudents.sort((a, b) => {
					if (a.number < b.number) {
						return -1;
					}
					if (a.number > b.number) {
						return 1;
					}
					return 0;
				});
				setStudents(sortedStudents);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchYearList = () => {
		onLoading(true);

		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchStudentBill = async () => {
		setFees([]);
		onLoading(true);

		requestBillStudent({
			yearId: year._id,
			semesterId: semester._id,
			studentId: student._id,
			date: dayjs(date).format("YYYY-MM-DD"),
		})
			.then((res) => {
				setFees(res.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleSubmit = () => {
		if (items.length === 0) {
			notification.error({
				message: "Kesalahan!",
				description: "Tagihan tidak boleh kosong",
			});
			return;
		}
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				values.date = dayjs(values.date).format("YYYY-MM-DD");
				values.items = items;
				values.fees = additionalFees
					? additionalFees.filter((fee) => fee && fee.amount && fee.name)
					: [];

				requestAddInvoice(values)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						setItems([]);
						setYear(null);
						setSemester(null);
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		setItems([]);
		setYear(null);
		setSemester(null);
		onClose();
	};

	const handleItemsChange = (value) => {
		setItems(value);
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={invoice === null || invoice === undefined ? "Tambah Tagihan" : "Ubah Tagihan"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data tagihan?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{invoice ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Row gutter={[12, 12]}>
							<Col span={semesterId && semester ? 8 : 12}>
								<Form.Item
									style={{ marginTop: 0, marginBottom: 0 }}
									name="yearId"
									label="Thn Akademik"
									required
									rules={[
										{
											required: true,
											message: "Tahun akademik harus dipilih",
										},
									]}
									tooltip="Pilih tahun akademik">
									<Select
										showSearch
										placeholder="Pilih tahun akademik"
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}>
										{years.map((year) => (
											<Select.Option key={year._id} value={year._id}>
												{`${year.name}${year._id === context.year._id ? " (Aktif)" : ""}`}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							{year && (
								<Col span={semesterId && semester ? 8 : 12}>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 0 }}
										name="semesterId"
										label="Semester"
										required
										rules={[
											{
												required: true,
												message: "Semester harus dipilih",
											},
										]}
										tooltip="Pilih semester yang akan diproses">
										<Select
											showSearch
											placeholder="Pilih semester"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{year.semesters.map((semester) => (
												<Select.Option key={semester._id} value={semester._id}>
													{`${semester.name} (${dayjs(semester.startedAt).format(
														"DD MMM YYYY"
													)} - ${dayjs(semester.endedAt).format("DD MMM YYYY")})`}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							)}
							{semesterId && semester && (
								<Col span={8}>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 0 }}
										name="date"
										label="Tgl Tagihan"
										required
										rules={[
											{
												required: true,
												message: "Tanggal tagihan harus dipilih",
											},
										]}
										tooltip="Anda dapat mengirim tagihan di atas tanggal 3 hingga tanggal 28">
										<DatePicker
											style={{ width: "100%" }}
											format={"DD MMM YYYY"}
											disabledDate={(current) => {
												const tooEarly = current.isBefore(
													dayjs(semester.startedAt).toDate(),
													"day"
												);
												const tooLate = current.isAfter(dayjs(semester.endedAt).toDate(), "day");
												const firstDays = current.date() < 2;
												const threeLastDays = current.date() > 28;
												return tooEarly || tooLate || firstDays || threeLastDays;
											}}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
							}}>
							Tanggal tagihan dapat diaplikasikan pada <strong>tanggal 2 hingga 28</strong> setiap
							bulannya. Pilih tanggal tagihan yang sesuai dengan kebijakan sekolah. Tanggal tagihan
							berperan penting dalam proses pengecekan, pastikan memilih tanggal tagihan dengan
							benar. Penarikan biaya dengan tipe <strong>BULANAN</strong> membutuhkan tanggal pada
							bulan yang akan ditarik. Misalnya bila Anda memilih tanggal 1 Januari, maka sistem{" "}
							<strong>hanya akan</strong> menarik tagihan bulan Januari,{" "}
							<strong>tidak termasuk</strong> bulan Desember atau Februari.
						</Typography.Text>
						<Divider />
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="studentId"
							label="Siswa"
							required
							rules={[
								{
									required: true,
									message: "Siswa harus dipilih",
								},
							]}
							tooltip="Pilih siswa yang akan ditagihkan">
							<Select
								showSearch
								placeholder="Pilih siswa"
								optionFilterProp="children"
								onSearch={(value) => {
									setKeyword(value);
								}}
								virtual={true}
								loading={isLoading}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{students.map((student) => (
									<Select.Option key={student._id} value={student._id}>
										{`${student.name} (${student?.classroom?.name})`}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{year && semester && student && date && fees.length > 0 && (
							<>
								<Typography.Paragraph
									style={{
										marginTop: 24,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Tagihan
									<Tooltip placement="top" title={"Tagihan tersedia untuk siswa ini"}>
										<QuestionCircleOutlined
											style={{
												marginLeft: 4,
												color: "rgba(0, 0, 0, 0.45)",
											}}
										/>
									</Tooltip>
								</Typography.Paragraph>
								<InvoiceItemInput
									year={year}
									semester={semester}
									date={date.format("YYYY-MM-DD")}
									fees={fees}
									onChange={handleItemsChange}
								/>
								<Typography.Paragraph
									style={{
										marginTop: 24,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Biaya Tambahan
									<Tooltip
										placement="top"
										title={"Biaya tambahan yang akan diaplikasikan ke seluruh tagihan"}>
										<QuestionCircleOutlined
											style={{
												marginLeft: 4,
												color: "rgba(0, 0, 0, 0.45)",
											}}
										/>
									</Tooltip>
								</Typography.Paragraph>
								<Form.List name="fees">
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, ...restField }) => (
												<Row
													key={key}
													gutter={12}
													style={{
														display: "flex",
														width: "100%",
														marginBottom: 12,
													}}>
													<Col flex={1}>
														<Form.Item
															{...restField}
															name={[name, "name"]}
															rules={[
																{
																	required: true,
																	message: "Nama wajib diisi",
																},
															]}
															style={{
																marginBottom: 0,
															}}>
															<Input
																placeholder="Nama"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col flex={1}>
														<Form.Item
															{...restField}
															name={[name, "amount"]}
															rules={[
																{
																	required: true,
																	message: "Jumlah wajib diisi",
																},
															]}
															style={{
																marginBottom: 0,
															}}>
															<InputNumber
																addonBefore="Rp"
																controls={false}
																min={0}
																placeholder="Jumlah"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col flex="none" style={{ alignSelf: "center" }}>
														<MinusCircleOutlined
															style={{
																color: "red",
															}}
															onClick={() => remove(name)}
														/>
													</Col>
												</Row>
											))}
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Tambah
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
								<Space
									direction="vertical"
									style={{
										width: "100%",
										marginTop: "16px",
										padding: "16px",
										backgroundColor: "#F7F7F7",
										borderRadius: "8px",
										border: "1px dashed #E0E0E0",
									}}>
									<Row key="date" justify="space-between">
										<span>Tanggal</span>
										<span style={{ fontWeight: "bold" }}>{dayjs(date).format("DD MMM YYYY")}</span>
									</Row>
									<Row key="name" justify="space-between">
										<span>Nama</span>
										<span style={{ fontWeight: "bold" }}>{student.name}</span>
									</Row>
									<Row key="classroom" justify="space-between">
										<span>Kelas</span>
										<span
											style={{
												fontWeight: "bold",
											}}>
											{student?.classroom?.name}
										</span>
									</Row>
									<Divider />
									<Row key="subtotal" justify="space-between">
										<Typography.Title
											level={5}
											style={{
												margin: 0,
											}}>
											Subtotal
										</Typography.Title>
										<Typography.Title
											level={5}
											style={{
												margin: 0,
												fontWeight: "bold",
											}}>
											{Intl.NumberFormat("id-ID", {
												style: "currency",
												currency: "IDR",
											}).format(subtotal)}
										</Typography.Title>
									</Row>
									<Row key="fees" justify="space-between">
										<span>Biaya Tambahan</span>
										<span
											style={{
												fontWeight: "bold",
											}}>
											{Intl.NumberFormat("id-ID", {
												style: "currency",
												currency: "IDR",
											}).format(additionalFee)}
										</span>
									</Row>
									<Row key="total" justify="space-between">
										<Typography.Title
											level={5}
											style={{
												margin: 0,
											}}>
											Total
										</Typography.Title>
										<Typography.Title
											level={5}
											style={{
												margin: 0,
												fontWeight: "bold",
											}}>
											{Intl.NumberFormat("id-ID", {
												style: "currency",
												currency: "IDR",
											}).format(total)}
										</Typography.Title>
									</Row>
								</Space>
							</>
						)}
						{year && semester && student && date && fees.length === 0 && (
							// <Typography.Paragraph
							// 	style={{
							// 		marginTop: 12,
							// 		marginBottom: 0,
							// 		paddingBottom: 8,
							// 	}}>
							// 	Tidak ada tagihan untuk {student.name} di semester {semester.name} tahun ajaran{" "}
							// 	{year.name} bulan {dayjs(date).format("MMMM YYYY")}
							// </Typography.Paragraph>
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={
									<span>
										Tidak ada tagihan untuk <strong>{student.name}</strong> pada bulan{" "}
										<strong>{dayjs(date).format("MMMM YYYY")}</strong> semester{" "}
										<strong>{semester.name}</strong> tahun ajaran <strong>{year.name}</strong>
									</span>
								}
							/>
						)}
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default InvoiceFormModal;
