/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	CreditCardFilled,
	ExclamationCircleOutlined,
	MoreOutlined,
	PlusCircleFilled,
} from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Dropdown,
	message,
	Modal,
	Space,
	Switch,
	Table,
	Tag,
	Typography,
} from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
	requestBlockCard,
	requestDeleteCard,
	requestGetCardList,
	requestUnblockCard,
} from "../../../services/admin.service";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import {
	PiIdentificationCardDuotone,
	PiMagnifyingGlassDuotone,
	PiTrashDuotone,
} from "react-icons/pi";
import CardFormModal from "./components/CardFormModal";
import CardDetailModal from "./components/CardDetailModal";

const columns = [
	{
		title: "Nomor Seri",
		dataIndex: "number",
		key: "number",
		sorter: true,
		render: (text, record) => (
			<Space direction="horizontal">
				<PiIdentificationCardDuotone
					style={{
						fontSize: "2em",
						color: `${record.blocked ? "#f5222d" : "#52c41a"}`,
						verticalAlign: "middle",
					}}
				/>
				<Space direction="vertical">
					{/* <Typography.Text>{record.student.name}</Typography.Text> */}
					<Typography.Text
						style={{ margin: 0 }}
						copyable={{ text: record.number, tooltips: "Salin nomor" }}
						strong>
						{/* {record.number.replace(/(.{4})/g, "$1-").slice(0, -1)} */}
						{record.number}
					</Typography.Text>
				</Space>
			</Space>
		),
	},
	{
		title: "Siswa",
		dataIndex: "student",
		key: "student",
		render: (text, record) => (
			<Space>
				<Typography.Text>{record.student.name}</Typography.Text>
				<Tag color="blue">
					<Typography.Text
						strong
						copyable={{ text: record.student.number, tooltips: "Salin nomor" }}>
						{record.student.number}
					</Typography.Text>
				</Tag>
			</Space>
		),
	},
	{
		title: "Tabungan",
		dataIndex: "pocket",
		key: "pocket",
		render: (text, record) => (
			<Space>
				{record.pocket && (
					<>
						<Typography.Text>{record.pocket?.name}</Typography.Text>
						<Tag color="blue">
							<Typography.Text
								strong
								copyable={{ text: record.pocket?.number, tooltips: "Salin nomor" }}>
								{record.pocket.number.replace(/(.{4})/g, "$1-").slice(0, -1)}
							</Typography.Text>
						</Tag>
					</>
				)}
			</Space>
		),
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat Detail",
		icon: <PiMagnifyingGlassDuotone />,
	},
	// {
	// 	key: "withdraw",
	// 	label: "Tarik Saldo",
	// 	icon: <HiOutlineCreditCard />,
	// },
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <PiTrashDuotone />,
	// 	danger: true,
	// },
];

const CardPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Kartu Terintegrasi";
	}, []);

	const [cards, setCards] = useState([]);
	const [mappedCards, setMappedCards] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isBlocking, setBlocking] = useState(false);
	const [isFormOpen, setFormOpen] = useState(false);
	const [detailOpen, setDetailOpen] = useState(false);
	const [card, setCard] = useState(null);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchCardList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchCardList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetCardList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setCards(response.data.data);
				updateMappedCard(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedCard = (data) => {
		setMappedCards(
			data.map((d) => ({
				key: d._id,
				number: d.number,
				student: d.student,
				pocket: d.pocket,
				blocked: d.blocked,
				actions: (
					<Space>
						<Switch
							className="switch-blocked"
							checkedChildren="Buka Blokir"
							unCheckedChildren="Blokir"
							loading={isBlocking}
							checked={d.blocked === true}
							onChange={(checked) => {
								setBlocking(true);
								if (checked) {
									requestBlockCard(d._id)
										.then((response) => {
											message.success(response.data.message);

											fetchCardList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								} else {
									requestUnblockCard(d._id)
										.then((response) => {
											message.success(response.data.message);

											fetchCardList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								}
							}}
						/>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										setCard(d);
										setDetailOpen(true);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<CardFormModal
				open={isFormOpen}
				onClose={() => setFormOpen(false)}
				onSuccess={() => {
					setFormOpen(false);
					fetchCardList();
				}}
			/>
			<CardDetailModal open={detailOpen} onClose={() => setDetailOpen(false)} cardId={card?._id} />
			<Card
				bordered={false}
				title="Daftar Kartu Terintegrasi"
				extra={
					<Space>
						<SearchInput
							placeholder="Nomor atau nama siswa"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						{/* <Button
							icon={<PlusCircleFilled />}
							type="primary"
							onClick={() => {
								setFormOpen(true);
							}}>
							<span className="ant-btn-text">Tambah</span>
						</Button> */}
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedCards}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default CardPage;
