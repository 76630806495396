import React, { useEffect, useState } from "react";
import { Input, InputNumber, Table } from "antd";
import dayjs from "dayjs";
import "./InvoiceItemInput.css";

const InvoiceItemInput = ({ year, semester, date, fees, onChange }) => {
	const [rows, setRows] = useState([]);
	const [billItems, setBillItems] = useState([]);
	const [selectedBillItems, setSelectedBillItems] = useState([]);

	useEffect(() => {
		if (year && semester && date && fees) {
			setBillItems(
				fees.map((item) => {
					return {
						...item,
						note: "",
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year, date, fees]);

	useEffect(() => {
		onChange(selectedBillItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBillItems]);

	useEffect(() => {
		if (rows.length === 0) {
			mockBills();
		}

		const tempSelectedBillItems = selectedBillItems.map((item) => {
			const fee = billItems.find((i) => i._id.toString() === item.feeId.toString());
			return {
				name: fee.name,
				feeId: fee._id,
				amount: fee.amount,
				note: fee.note,
			};
		});
		setSelectedBillItems(tempSelectedBillItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billItems]);

	const mockBills = async () => {
		const data = billItems.map((item) => {
			let name = item.name;
			if (item.type === "MONTHLY") {
				name = `${item.name} (${dayjs(date).format("MMMM YYYY")})`;
			} else if (item.type === "SEMESTRIAL") {
				name = `${item.name} (Semester ${semester.name} ${year.name})`;
			} else if (item.type === "PERIODICAL") {
				name = `${item.name} (${year.name})`;
			}

			return {
				key: item._id,
				name: name,
				amount: (
					<InputNumber
						controls={false}
						min={0}
						addonBefore="Rp"
						size="small"
						placeholder="Jumlah"
						defaultValue={item.amount}
						onChange={(e) => {
							const amount = e;
							const index = billItems.findIndex((i) => i._id.toString() === item._id.toString());
							if (index !== -1) {
								const tempBillItems = [...billItems];
								tempBillItems[index].amount = amount;
								setBillItems(tempBillItems);
							}
						}}
						style={{
							width: "100%",
						}}
					/>
				),
				description: item.description,
				note: (
					<Input
						size="small"
						placeholder="Catatan"
						defaultValue={item.note}
						onChange={(e) => {
							const note = e.target.value;
							const index = billItems.findIndex((i) => i._id.toString() === item._id.toString());
							if (index !== -1) {
								const tempBillItems = [...billItems];
								tempBillItems[index].note = note;
								setBillItems(tempBillItems);
							}
						}}
						style={{
							width: "100%",
						}}
					/>
				),
			};
		});
		setRows(data);
	};

	const columns = [
		{
			title: "Nama",
			dataIndex: "name",
			width: "30%",
		},
		{
			title: "Keterangan",
			dataIndex: "description",
			width: "30%",
		},
		{
			title: "Jumlah",
			dataIndex: "amount",
			width: "20%",
		},
		{
			title: "Catatan",
			dataIndex: "note",
			width: "20%",
		},
	];

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			const tempSelectedBillItems = selectedRows.map((item) => {
				const fee = billItems.find((i) => i._id.toString() === item.key.toString());
				return {
					name: fee.name,
					feeId: fee._id,
					amount: fee.amount,
					note: fee.note,
				};
			});
			setSelectedBillItems(tempSelectedBillItems);
		},
		getCheckboxProps: (record) => ({
			disabled: record.name === "Disabled User",
			// Column configuration not to be checked
			name: record.name,
		}),
	};

	return (
		<Table
			size="small"
			rowSelection={{
				type: "checkbox",
				...rowSelection,
			}}
			columns={columns}
			dataSource={rows}
			pagination={false}
			bordered
		/>
	);
};
export default InvoiceItemInput;
