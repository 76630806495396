import {
  Card,
  message,
  notification,
  Popover,
  Space,
  Table,
  Typography,
} from "antd";
import SearchInput from "../../components/SearchInput";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  requestGetNotificationList,
  requestReadAllNotification,
  requestReadNotification,
} from "../../services/user.service";
import { StarOutlined } from "@ant-design/icons";
import { formatTime } from "../../utils/TimeFormatter";
import { HiEnvelopeOpen } from "react-icons/hi2";

function NotificationPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    document.title = "Notifikasi";
  }, []);

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentNotificationId, setCurrentNotificationId] = useState(null);

  // SEARCH, FILTER, PAGINATION
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({
    startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
    endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [sorter, setSorter] = useState({
    _id: "desc",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (value) => {
    setKeyword(value.trim());
  };

  const handlePaginate = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    // UPDATE FILTER
    if (filters) {
      const additionalFilter = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          additionalFilter[key] = filters[key].join("|");
        } else {
          additionalFilter[key] = "";
        }
      });

      setFilter({
        ...filter,
        ...additionalFilter,
      });
    } else {
      const tempFilter = {};
      // only allow startedAt and endedAt
      Object.keys(filter).forEach((key) => {
        if (key === "startedAt" || key === "endedAt") {
          tempFilter[key] = filter[key];
        }
      });

      setFilter(tempFilter);
    }

    // UPDATE SORTER
    if (sorter && sorter.field) {
      setSorter({
        [sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
      });
    } else {
      setSorter({
        _id: "desc",
      });
    }
  };
  // SEARCH, FILTER, PAGINATION

  useEffect(() => {
    setSearchParams({ keyword: keyword, page: page, limit: limit });

    fetchNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, filter, page, limit]);

  useEffect(() => {
    if (searchParams.has("keyword")) {
      setKeyword(searchParams.get("keyword"));
    }
    if (searchParams.has("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.has("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotificationList = () => {
    setLoading(true);

    let tempFilter = "";
    if (filter) {
      tempFilter = Object.keys(filter)
        .map((key) => `${key}:${filter[key]}`)
        .filter(
          (f) =>
            f.split(":")[1] !== "" &&
            f.split(":")[1] !== undefined &&
            f.split(":")[1] !== null,
        )
        .join(",");
    }

    let tempSorter = "";
    if (sorter) {
      tempSorter = Object.keys(sorter)
        .map((key) => `${key}:${sorter[key]}`)
        .filter(
          (s) =>
            s.split(":")[1] !== "" &&
            s.split(":")[1] !== undefined &&
            s.split(":")[1] !== null,
        )
        .join(",");
    }

    requestGetNotificationList({
      page: page,
      limit: limit,
      order: tempSorter ?? "_id:desc",
      keyword: keyword ?? "",
      filter: tempFilter ?? "",
    })
      .then((response) => {
        setTotal(response.data.pagination.total);
        setNotifications(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  function handleReadAll() {
    setLoading(true);

    requestReadAllNotification()
      .then((response) => {
        message.success("Semua pemberitahuan telah dibaca");

        fetchNotificationList();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleRead(id) {
    setLoading(true);

    requestReadNotification(id)
      .then((response) => {
        message.success("Pemberitahuan telah dibaca");

        setNotifications((prev) =>
          prev.map((n) => {
            if (n._id === id) {
              n.readAt = new Date();
            }
            return n;
          }),
        );
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notification.error({
            message: "Kesalahan!",
            description: error.response.data.message,
          });
        } else {
          notification.error({
            message: "Kesalahan!",
            description: error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Card
        bordered={false}
        title="Daftar Pemberitahuan"
        extra={
          <Space>
            <SearchInput
              placeholder="Cari sesuatu..."
              onSearch={handleSearch}
              defaultValue={keyword}
              isLoading={isLoading}
            />
          </Space>
        }
      >
        <div>
          <Table
            columns={[
              {
                title: "Judul",
                dataIndex: "title",
                key: "title",
                onCell: (record) => {
                  return {
                    style: {
                      backgroundColor: record.readAt ? "#F6F6F6FF" : "",
                    },
                  };
                },
                render: (text, record) => (
                  <Popover
                    content={
                      <div
                        style={{
                          maxWidth: "300px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        <Typography.Text
                          style={{
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          {record.body}
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          {formatTime(record.createdAt)}
                        </Typography.Text>
                      </div>
                    }
                    title={record.title}
                    placement="top"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                      }}
                    >
                      <StarOutlined
                        onClick={() => {
                          notification.warning({
                            message: "Peringatan!",
                            description: "Fitur ini belum tersedia",
                          });
                        }}
                      />
                      <Typography.Text
                        style={{
                          whiteSpace: "nowrap",
                          fontWeight: record.readAt ? "normal" : "bold",
                        }}
                      >
                        {text}
                      </Typography.Text>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography.Text
                          type="secondary"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.body}
                        </Typography.Text>
                        {currentNotificationId === record._id ? (
                          <Popover
                            placement="right"
                            content="Tandai sudah dibaca"
                          >
                            <HiEnvelopeOpen
                              style={{
                                fontSize: "16px",
                              }}
                              onClick={() => handleRead(record._id)}
                            />
                          </Popover>
                        ) : (
                          <Typography.Text
                            type="secondary"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "10px",
                            }}
                          >
                            {formatTime(record.createdAt)}
                          </Typography.Text>
                        )}
                      </div>
                    </div>
                  </Popover>
                ),
              },
            ]}
            showHeader={false}
            loading={isLoading}
            dataSource={notifications.map((n) => ({
              key: n._id,
              ...n,
            }))}
            pagination={{
              current: page,
              total: total,
              position: "bottomRight",
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "item/hal" },
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleRead(record._id);
                },
                onMouseEnter: (event) => {
                  setCurrentNotificationId(record._id);
                }, // mouse enter row
                onMouseLeave: (event) => {
                  setCurrentNotificationId(null);
                },
              };
            }}
            onChange={handlePaginate}
          />
        </div>
      </Card>
    </>
  );
}

export default NotificationPage;
