/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, message, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
	requestDeleteExam,
	requestGetExamList,
	requestGetSubjectList,
} from "../../../services/admin.service";
import ExamFormModal from "./components/ExamFormModal";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import ExamDetailModal from "../../../components/ExamDetailModal";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
		render: (text, record) => {
			return (
				<Space size="small">
					{record.name}
					<Tag
						style={{
							fontWeight: "bold",
						}}
						color="default">
						Template
					</Tag>
				</Space>
			);
		},
	},
	{
		title: "Deskripsi",
		dataIndex: "description",
		key: "description",
	},
	{
		title: "Mata Pelajaran",
		dataIndex: "subjects",
		key: "subjects",
		render: (text, record) => {
			return (
				<Space size="small">
					{record.subject?.name}
					<Tag
						color="blue"
						style={{
							fontWeight: "bold",
						}}>
						{record.grade}{" "}
						{record.grade < 7 ? "SD/MI" : record.grade < 9 ? "SMP/MTs" : "SMA/SMK/MA"}
					</Tag>
				</Space>
			);
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		width: 80,
		render: (text, record) => {
			return (
				<Tag
					style={{
						fontWeight: "bold",
						marginInlineEnd: 0,
						textAlign: "center",
					}}
					color={
						record.status === "DRAFT"
							? "warning"
							: record.status === "PUBLISHED"
							? "success"
							: "error"
					}>
					{record.status === "DRAFT" ? "DRAF" : record.status === "PUBLISHED" ? "TERBIT" : "ARSIP"}
				</Tag>
			);
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat",
		icon: <HiOutlineEye />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const BlueprintedExamPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification, modal } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Akademik | Bank Soal";
	}, []);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		blueprinted: true,
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				blueprinted: true,
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {
				blueprinted: true,
			};

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [exams, setExams] = useState([]);
	const [mappedExams, setMappedExams] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [subjects, setSubjects] = useState([]);

	const [exam, setExam] = useState(null);
	const [isExamFormVisible, setExamFormVisible] = useState(false);
	const [isExamDetailVisible, setExamDetailVisible] = useState(false);

	useEffect(() => {
		fetchSubjectList();
	}, []);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchExamList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchExamList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetExamList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setExams(response.data.data);
				updateMappedExam(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchSubjectList = () => {
		requestGetSubjectList({
			page: 1,
			limit: 1000,
		})
			.then((response) => {
				setSubjects(response.data.data);
			})
			.catch((error) => {});
	};

	const updateMappedExam = (data) => {
		setMappedExams(
			data.map((d) => ({
				key: d._id,
				...d,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										handleShowButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus bank soal ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setExamFormVisible(true);
	};

	const handleShowButton = (exam) => {
		setExam(exam);
		setExamDetailVisible(true);
	};

	const handleDeleteButton = (id) => {
		setLoading(true);
		requestDeleteExam(id)
			.then((response) => {
				if (response.data.code === 200) {
					message.success(response.data.message);

					fetchExamList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseExamForm = () => {
		setExam(null);
		setExamFormVisible(false);
	};

	const handleOnSuccessExamForm = () => {
		setExam(null);
		setExamFormVisible(false);
		fetchExamList();
	};

	return (
		<>
			<ExamDetailModal
				id={exam ? exam._id : null}
				isVisible={isExamDetailVisible}
				onClose={() => {
					setExamDetailVisible(false);
					setExam(null);
				}}
			/>
			<ExamFormModal
				isLoading={isLoading}
				isVisible={isExamFormVisible}
				onLoading={(v) => setLoading(v)}
				exam={exam}
				onClose={handleOnCloseExamForm}
				onSuccess={handleOnSuccessExamForm}
				subjects={subjects}
			/>
			<Card
				bordered={false}
				title="Daftar Bank Soal"
				extra={
					<Space>
						<SearchInput
							placeholder="Nama bank soal"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
							<span className="ant-btn-text">Tambah</span>
						</Button>
					</Space>
				}>
				<div>
					<Table
						columns={columns}
						loading={isLoading}
						bordered
						dataSource={mappedExams}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default BlueprintedExamPage;
