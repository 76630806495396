/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, message, Modal, notification, Popconfirm } from "antd";
import { useState } from "react";
import { requestAssignMerchantToStore } from "../../../../services/admin.service";

const StoreAssignmentFormModal = ({ store, open, onClose, onSuccess }) => {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setSubmitting(true);
				requestAssignMerchantToStore(store._id, values.email)
					.then((response) => {
						message.success(response.data.message);

						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setSubmitting(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={!store ? "Tambah Kartu" : "Ubah Kartu"}
				open={open}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menugaskan merchant ini?"
						okText="Ya"
						cancelText="Tidak">
						<Button loading={submitting} type="primary">
							{store ? "Simpan" : "Tambah"}
						</Button>
					</Popconfirm>,
				]}>
				<Form form={form} layout="vertical" requiredMark="optional">
					<Form.Item
						style={{ marginTop: 0, marginBottom: 0 }}
						name="email"
						label="Email Merchant"
						required
						rules={[{ required: true, message: "Masukkan email merchant!" }]}
						tooltip="Masukkan email merchant">
						<Input style={{ width: "100%" }} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default StoreAssignmentFormModal;
